import React, { useEffect, useState } from "react";
import { RouteType } from "../../routes/config";
import { ListItemButton, ListItemIcon, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { useSelector } from "react-redux";

type Props = {
  item: RouteType,
  isActive: boolean,
};

const SidebarItem = ({ item }: Props) => {
  const state = useSelector((state) => state);
  const toggle = state.commonData.toggleSidebar;
  const location = useLocation();
  const [isActive, setIsActive] = useState(location.pathname === item.path);

  useEffect(() => {
    setIsActive(location.pathname === item.path);
  }, [location.pathname, item.path]);

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      onClick={() => setIsActive(!isActive)}
      sx={{
        "&:hover": {
          backgroundColor: "var(--top-bar-bg-color)",
        },
        paddingY: "12px",
        paddingX: "24px",
      }}
    >
      <ListItemIcon
        sx={{
          color: isActive ? "#4318FF" : "var(--card-text-color)",
        }}
      >
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>
      {!toggle && (
        <h5 style={{ margin: "0px", padding: "0px" }}>
          {item.sidebarProps.displayText}
        </h5>
      )}
    </ListItemButton>
  ) : null;
};
export default SidebarItem;
