import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import EndPoints from "../../../redux/features/EndPoints";
import axios from "axios";
class getDataFromAPI {
  static async getAllUser() {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(OperationDashEndpoints.createUser(), {
        headers: { Authorization: `Token ${token}` },
      });

      const accountData = response.data.map((item) => ({
        label: item.username,
        value: item.id,
      }));

      return accountData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getAllAccount() {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(OperationDashEndpoints.createAccount(), {
        headers: { Authorization: `Token ${token}` },
      });

      const accountData = response.data.map((item) => ({
        label: item.name,
        value: item.account_id,
      }));

      return accountData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }

  static async getAccountById(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getAccountById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }

  static async getAllFacility() {
    try {
      const storedFacilities = JSON.parse(
        localStorage.getItem("storedFacilities")
      );

      if (storedFacilities && storedFacilities.length > 0) {
        return storedFacilities;
      }

      const token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.createFacility(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      const facilityData = response.data.map((item) => ({
        label: item.facility_name,
        value: item.id,
      }));
      localStorage.setItem("storedFacilities", JSON.stringify(facilityData));
      return facilityData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      return [];
    }
  }

  static async getFacilityById(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getFacilityById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }

  static async getUserProfileById(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getProfileById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getFacilityProfileById(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getAllFacilityProfilesById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching facility data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getUserId(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(OperationDashEndpoints.getUserById(id), {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }

  static async getAllServices() {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getAllServices(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      const accountData = response.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return accountData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getAllNVR() {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(OperationDashEndpoints.createNVR(), {
        headers: { Authorization: `Token ${token}` },
      });

      const accountData = response.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return accountData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }

  static async getCameraDetailsByid(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getCameraById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getCameraByNvrId(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getCameraByNvrId(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const cameraData = response.data.data.map((option) => ({
        label: option.camera_name,
        value: option.id,
      }));
      return cameraData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getCameraByAccountId(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.cameraFromAccount(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const cameraData = response.data.data.map((option) => ({
        label: option.camera_name,
        value: option.id,
      }));
      return cameraData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getUseCaseDetailByid(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getUseCaseMappingById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Nvr data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getNVRDetailsByid(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(OperationDashEndpoints.getNvrById(id), {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Nvr data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }

  static async getFacilityByAcc(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        EndPoints.getAllFacilityByAccountOP(id),
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      const facilityData = response.data.map((option) => ({
        label: option.facility_name,
        value: option.id,
      }));

      return facilityData;
    } catch (error) {
      console.error("Error fetching Facility data: ", error);
      throw error;
    }
  }
  static async getDeviceList() {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(OperationDashEndpoints.getDeviceList(), {
        headers: { Authorization: `Token ${token}` },
      });

      const deviceData = response.data.map((item) => ({
        label: item.device_name,
        value: item.id,
      }));

      return deviceData;
    } catch (error) {
      console.error("Error fetching Device data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getDeviceDetailsById(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getDeviceListById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching Device data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static getSelectedItem(id, list) {
    if (list && id && list.length > 0) {
      const selectedItem = list.filter((item) => item.value === id);
      if (selectedItem) {
        return selectedItem[0];
      }
    }
    return list[0];
  }
  static getSelectedServices(ids, list) {
    if (list && ids && list.length > 0) {
      const selectedServices = list.filter((item) => ids.includes(item.value));
      return selectedServices || [];
    }
    return [];
  }
}
export default getDataFromAPI;
