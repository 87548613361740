import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  FormGroup,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import {
  fetchAccounts,
  fetchAllFacility,
  fetchGroupDetails,
  fetchUserDetails,
  getCamerasListfromNvrIds,
  getNvrAndDeviceDetailsUsingFacilityIds,
  updateGroupfromAccountIds,
  getFacilityNvrCameraByGroupIds,
  fetchAccountsforGroup,
} from "../../../redux/Action/AccessControlMappingAction";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../../components/common/UseToast";

const AccessControlMain = () => {
  const dispatch = useDispatch();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [nvrOptions, setNvrOptions] = useState([]);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [selectedNvr, setSelectedNvr] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [checkedBox, setCheckedBox] = useState(false);
  const [myFacility, setMyFacility] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getAccountData();
    dispatch(changeTopbarTitle("Admin/Access Control Mapping"));
  }, [dispatch]);

  const getAccountData = async () => {
    const accountList = await fetchAccountsforGroup();
    setAccounts(accountList);
  };

  const getUserData = async (id) => {
    const userList = await fetchUserDetails(id);
    setUserOptions(userList);
  };

  const getGroupData = async (id) => {
    const groupList = await fetchGroupDetails(id);
    setGroupOptions(groupList);
  };

  const getAllFacilityData = async () => {
    setLoadingFacilities(true);
    try {
      const facilityList = await fetchAllFacility();
      const uniqueFacilityOptions = facilityList
        .filter(
          (facility, index, self) =>
            index === self.findIndex((f) => f.label === facility.label)
        )
        .map((facility, index) => ({
          ...facility,
          id: facility.id || index,
        }));
      setFacilityOptions(uniqueFacilityOptions);
    } catch (error) {
      console.error("Error fetching facilities data:", error);
    } finally {
      setLoadingFacilities(false);
    }
  };

  const fetchNvrAndDeviceDetails = useCallback(async (facilityIds) => {
    const nvrList = await getNvrAndDeviceDetailsUsingFacilityIds(
      facilityIds,
      "nvr"
    );
    setNvrOptions(nvrList);
  }, []);

  const fetchCameraData = async (nvrIds) => {
    const cameraList = await getCamerasListfromNvrIds(nvrIds);
    setCameraOptions(cameraList);
  };

  const handleAccountChange = async (event, value) => {
    if (value) {
      setSelectedAccount(value);
      await getUserData(value.value);
    } else {
      setSelectedAccount(null);
    }
  };

  const handleUserChange = async (event, value) => {
    if (value) {
      setSelectedUser(value);
      await getGroupData(value.value);
    } else {
      setSelectedUser(null);
    }
  };

  const handleGroupChange = (event, value) => {
    setSelectedGroup(value);
    getAllFacilityData();
  };

  const handleFacilityChange = (event, value) => {
    setMyFacility(value);
    const facilityIds = value.map((item) => item.value);
    setSelectedFacility(facilityIds);
    fetchNvrAndDeviceDetails(facilityIds);
  };

  const handleNvrChange = (event, value) => {
    const nvrIds = value.map((item) => item.value);
    setSelectedNvr(nvrIds);
    if (!checkedBox) {
      fetchCameraData(nvrIds);
    }
  };

  const handleCameraChange = (event, value) => {
    const cameraIds = value.map((item) => item.value);
    setSelectedCamera(cameraIds);
  };

  const handleUpdateGroup = async () => {
    if (selectedAccount && selectedGroup) {
      try {
        const data = {
          facility_ids: selectedFacility,
          nvr_ids: selectedNvr,
          camera_ids: selectedCamera,
        };
        await updateGroupfromAccountIds(
          selectedAccount.value,
          selectedGroup.value,
          data,
          checkedBox
        );
        notifiToasterSuccess("Changes saved successfully");
      } catch (error) {
        notifiToasterWarn("Something went wrong, please refresh the page");
        console.error("Error updating group:", error);
      }
    } else {
      notifiToasterWarn("Please select both account and group");
    }
  };

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setCheckedBox(isChecked);
    if (isChecked && selectedGroup) {
      try {
        const facilityNvrCameraData = await getFacilityNvrCameraByGroupIds(
          selectedGroup.value
        );
        setFacilityOptions(
          facilityNvrCameraData.data.facility.map((item) => ({
            label: item.value,
            value: item.id,
          }))
        );
        setNvrOptions(
          facilityNvrCameraData.data.nvr.map((item) => ({
            label: item.value,
            value: item.id,
          }))
        );
        setCameraOptions(
          facilityNvrCameraData.data.cameras.map((item) => ({
            label: item.value,
            value: item.id,
          }))
        );
      } catch (error) {
        console.error("Error fetching facility, NVR, and camera data:", error);
      }
    } else {
      getAllFacilityData();
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={accounts}
            getOptionLabel={(option) =>
              `${option.label} - ${option.value}` || ""
            }
            onChange={handleAccountChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Account" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={userOptions}
            getOptionLabel={(option) => option.label || ""}
            onChange={handleUserChange}
            disabled={!selectedAccount}
            renderInput={(params) => (
              <TextField {...params} label="Select User" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={groupOptions || []}
            getOptionLabel={(option) => option.label || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleGroupChange}
            disabled={!selectedUser}
            renderInput={(params) => (
              <TextField {...params} label="Select Group" />
            )}
          />
        </Grid>
      </Grid>
      {selectedGroup && (
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={3} container alignItems="center">
              <Typography variant="h6" style={{ marginRight: "10px" }}>
                Group Details Mapping
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedBox}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Remove"
              />
            </Grid>

            <Grid item xs={12} md={4} marginTop={"42px"}>
              <Autocomplete
                options={facilityOptions || []}
                getOptionLabel={(option) => option.label || ""}
                value={myFacility}
                loading={loadingFacilities}
                multiple
                disableCloseOnSelect
                filterSelectedOptions
                onChange={handleFacilityChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Facility"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingFacilities ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} marginTop={"42px"}>
              <Autocomplete
                options={nvrOptions || []}
                multiple
                filterSelectedOptions
                disableCloseOnSelect
                onChange={handleNvrChange}
                renderInput={(params) => (
                  <TextField {...params} label="Select NVR" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      setSelectAll(isChecked);
                      if (isChecked) {
                        setSelectedCamera(
                          cameraOptions.map((option) => option.value)
                        );
                      } else {
                        setSelectedCamera([]);
                      }
                    }}
                  />
                }
                label="Select All Cameras"
              />
              <Autocomplete
                options={cameraOptions || []}
                multiple
                disableCloseOnSelect
                filterSelectedOptions
                value={selectedCamera.map((id) =>
                  cameraOptions.find((option) => option.value === id)
                )}
                onChange={handleCameraChange}
                renderInput={(params) => (
                  <TextField {...params} label="Select Camera" />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<span className="icon" />}
                      checkedIcon={<span className="checkedIcon" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "right", mt: 5 }}>
            <Button onClick={handleUpdateGroup}>Update Group</Button>
          </Grid>
        </FormGroup>
      )}
    </div>
  );
};

export default AccessControlMain;
