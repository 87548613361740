import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import getDataFromAPI from "../../Onboarding/Utils/getDataFromAPI";
import MultiSelectAutoAutoComplete from "../../../components/formLayout/MultiSelectAutoAutoComplete";
import AutocompleteComp from "../../../components/common/Autocomplete";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";

const AddUseCase = ({ isEditable, id }) => {
  const dispatch = useDispatch();
  const [ans, setAnswer] = useState(null);
  const [formData, setFormData] = useState({
    usecase_name: "",
    selectedAccount: null,
    selectedCameraList: [],
    cameraIdList: [],
    AccountList: JSON.parse(sessionStorage.getItem("accountListSaved")) ?? [],
    errors: { usecase_name: "" },
    loading: false,
  });

  const fetchUserDetails = async () => {
    if (isEditable && id) {
      const ans = await getDataFromAPI.getUseCaseDetailByid(id);
      setAnswer(ans);
      setFormData((prevData) => {
        return {
          ...prevData,
          usecase_name: ans.usecase,
          selectedAccount: prevData.AccountList.find(
            (item) => item.value == ans.account
          ),
        };
      });
    }
  };
  useEffect(() => {
    async function getCamListByAccount() {
      const cam = await getDataFromAPI.getCameraByAccountId(
        formData.selectedAccount.value
      );
      var map = cam.filter((item) => ans.camera.includes(String(item.value)));
      console.log(map, cam, ans);
      setFormData((prevData) => ({
        ...prevData,
        selectedCameraList: map,
        cameraIdList: cam,
      }));
    }
    if (ans) getCamListByAccount();
  }, [formData.selectedAccount]);

  useEffect(() => {
    fetchUserDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      errors: { ...prevData.errors, [name]: "" },
    }));
  };
  function getOptionDisabled() {
    return false;
  }
  const handleAccountChange = async (value) => {
    setFormData((prevData) => ({ ...prevData, selectedAccount: value }));
    const cam = await getDataFromAPI.getCameraByAccountId(value.value);
    setFormData((prevData) => ({ ...prevData, cameraIdList: cam }));
  };

  const handleSelectedCamera = (newValue) => {
    setFormData((prevData) => ({ ...prevData, selectedCameraList: newValue }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    if (formData.usecase_name.length < 1) {
      valid = false;
      setFormData((prevData) => ({
        ...prevData,
        errors: { usecase_name: "Username must be at least 8 characters long" },
      }));
    }

    if (valid) {
      setFormData((prevData) => ({ ...prevData, loading: true }));
      console.log(formData.selectedAccount);
      const userData = {
        usecase: formData.usecase_name,
        account: formData.selectedAccount.value,
        camera: formData.selectedCameraList.map((item) => item.value),
      };
      const action = isEditable
        ? OnboardingAction.editUseCaseMapping(userData, id)
        : OnboardingAction.addUseCaseMapping(userData);

      dispatch(action).finally(() => {
        setFormData((prevData) => ({ ...prevData, loading: false }));
      });
    }
  };

  const {
    usecase_name,
    selectedAccount,
    selectedCameraList,
    cameraIdList,
    AccountList,
    errors,
    loading,
  } = formData;

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit} className="row">
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="name"
            label="Usecase name"
            variant="outlined"
            fullWidth
            type="text"
            name="usecase_name"
            value={formData.usecase_name}
            onChange={handleChange}
            error={Boolean(errors.usecase_name)}
            helperText={errors.usecase_name}
            required
          />
        </div>
        <div
          className={
            isEditable
              ? "form-group col-12 col-sm-6 col-lg-6"
              : "form-group col-12 col-sm-6 col-lg-6 mb-3"
          }
        >
          {isEditable ? (
            <AutocompleteComp
              option={formData.AccountList}
              onChange={(event, value) => handleAccountChange(value)}
              value={formData.selectedAccount}
              label="Select Account"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={false}
              showValue={true}
              option={formData.AccountList}
              onChange={(value) => handleAccountChange(value)}
              label={"Select Account"}
            />
          )}
        </div>
        <div className={"form-group col-12 mb-3"}>
          <MultiSelectAutoAutoComplete
            option={formData.cameraIdList}
            value={formData.selectedCameraList}
            onChange={(newValue) => handleSelectedCamera(newValue)}
            label={"Select Camera"}
            getOptionDisabled={getOptionDisabled}
          />
        </div>
        <div className="row">
          <div className="col-4 mt-3">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : isEditable ? (
                "Update Mapping"
              ) : (
                "Add Mapping"
              )}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

AddUseCase.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};

export default AddUseCase;
