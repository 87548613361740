import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";

const AlertBoxEmail = (propTypes) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
        m: 2,
        backgroundColor: "rgba(229, 246, 253, 1)",
        borderRadius: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <InfoOutlinedIcon
          sx={{ color: theme.palette.info.main, fontSize: 20 }}
        />
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "600",
            fontSize: "18px",
          }}
        >
          Confirm Your Email Address
        </Typography>
      </Box>

      <Typography
        variant="h7"
        sx={{ mt: 2, textAlign: "center", fontSize: "15px" }}
      >
        We sent a confirmation email to
        <Link
          href="#"
          sx={{
            ml: 1,
            color: theme.palette.secondary.main,
            textDecoration: "none",
          }}
        >
          :{propTypes.email}
        </Link>
      </Typography>
      <Typography
        variant="body1"
        sx={{ mt: 2, textAlign: "center", fontSize: "15px" }}
      >
        [Check your email and click on the confirmation link to continue]
      </Typography>
    </Box>
  );
};

export default AlertBoxEmail;
