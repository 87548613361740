import axios from "axios";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import OperationDashEndpoint from "../features/OperationDashEndpoints";
// get Store

class configPageAction {
  static updateSelectedMessage(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_ENTERED_MESSAGE", payload: data });
    };
  }
  static updateSelectedTimeData(time, date) {
    return async (dispatch) => {
      dispatch({ type: "SET_SELECTION_TIME", payload: time });
      dispatch({ type: "SET_SELECTED_DATE", payload: date });
    };
  }
  static updateSelectedAccount(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_SELECTED_ACCOUNT", payload: data });
    };
  }
  static updateTakenAccount(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_TAKEN_ACCOUNT", payload: data });
    };
  }
  static getSelectedClients(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(OperationDashEndpoint.getSelectedClients(data), { headers })
        .then((response) => {
          dispatch({
            type: "SET_TAKEN_ACCOUNT",
            payload: response.data.other_operators,
          });
          dispatch({
            type: "SET_SELECTED_ACCOUNT",
            payload: response.data.display_clients,
          });
        })
        .catch(() => {
          dispatch({
            type: "SET_TAKEN_ACCOUNT",
            payload: [],
          });
          dispatch({
            type: "SET_SELECTED_ACCOUNT",
            payload: [],
          });
        });
    };
  }
  static getAllStoredMessage(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      try {
        const response = await axios.get(
          OperationDashEndpoint.getAllStoredMessage(data),
          { headers }
        );

        var stored = [];
        var msgDict = {};
        response.data.message.forEach((item, index) => {
          if (typeof item[0] == "string") {
            stored.push(item[0]);
            msgDict[item[0]] = item[1];
          } else if (typeof item[1] == "string") {
            stored.push(item[1]);
            msgDict[item[1]] = item[0];
          }
        });

        dispatch({
          type: "SET_ENTERED_MESSAGE",
          payload: stored,
        });
        dispatch({
          type: "SET_ENTERED_MESSAGE_DICT",
          payload: msgDict,
        });
      } catch (error) {
        console.error("API Request Error:", error); // Log any API request errors
        dispatch({
          type: "SET_ENTERED_MESSAGE",
          payload: [],
        });
        dispatch({
          type: "SET_ENTERED_MESSAGE_DICT",
          payload: {},
        });
      }
    };
  }

  static updateStoredMessages(id, data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .post(
          OperationDashEndpoint.updateStoredMessages(),
          {
            operator_id: id,
            message: data,
          }, // Pass 'null' as the second argument since you want to send an empty body
          { headers } // Pass the headers directly
        )
        .then((response) => {
          notifiToasterSuccess("Changes saved successfully");
        })
        .catch((err) => {
          notifiToasterWarn("Something Went Wrong, Please refresh the page");
        });
    };
  }
  static deleteStoredMessages(id) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      try {
        await axios.delete(OperationDashEndpoint.deleteStoredMessages(id), {
          headers,
        });
        // Dispatch an action here if needed to update the application state
        notifiToasterSuccess("Stored message deleted successfully");
      } catch (err) {
        console.error("Error deleting stored message:", err);
        notifiToasterWarn(
          "Something went wrong while deleting the stored message"
        );
      }
    };
  }

  static updateSelectedClients(id, data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .post(
          OperationDashEndpoint.updateSelectedClients(id, data),
          null, // Pass 'null' as the second argument since you want to send an empty body
          { headers } // Pass the headers directly
        )
        .then((response) => {
          notifiToasterSuccess("Changes saved successfully");
        })
        .catch((err) => {
          notifiToasterWarn("Something Went Wrong, Please refresh the page");
        });
    };
  }
}

export default configPageAction;
