import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useWindowDimensions from "../common/useWindowDimensions";
import { ToastContainer } from "react-toastify";
import { getNewUserDetails } from "../../redux/Action/Login";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import UseSnackbar from "../common/UseSnackBar";
const MainLayout = () => {
  const commonData = useSelector((state) => state.commonData);
  const [mytheme, setMyTheme] = useState(
    createTheme({
      palette: {
        mode: commonData.agrexTheme,
      },
    })
  );
  const { width } = useWindowDimensions();
  const toggle = commonData.toggleSidebar;
  const dispatch = useDispatch();
  let widtht = toggle
    ? sizeConfigs.sidebarMin.width
    : sizeConfigs.sidebar.width;
  //Code to close all the previous stream which are not closed due to some reason
  useEffect(() => {
    setMyTheme(
      createTheme({
        palette: {
          mode: commonData.agrexTheme,
        },
      })
    );
  }, [commonData.agrexTheme]);
  return (
    <div>
      <ThemeProvider theme={mytheme}>
        <ToastContainer
          className={
            commonData.muteNotification
              ? "my-toast-container"
              : "my-toast-container"
          }
          limit={1}
          autoClose={1500}
        />
        <Box
          sx={{ display: "flex", backgroundColor: "var(--side-bar-bg-color)" }}
        >
          <Topbar />
          {width >= 800 ? (
            <Box
              component="nav"
              sx={{
                width: widtht,
                flexShrink: 0,
              }}
            >
              <Sidebar />
            </Box>
          ) : (
            ""
          )}

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: `calc(100% - ${widtht})`,
              minHeight: "100vh",
              backgroundColor: "var(--top-bar-bg-color)",
            }}
          >
            <Topbar />
            <Toolbar />
            <Outlet />
          </Box>
          {/* <UseSnackbar /> */}
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default MainLayout;
