import React, { useEffect } from "react";
import { Box } from "@mui/material";
import PageCard from "./PageCard";
import "./adminPanel.css";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
const AdminHomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTopbarTitle("Admin Panel"));
  }, []);
  return (
    <div>
      <Box
        sx={{ minHeight: "80vh" }}
        className="config-page-container d-flex justify-content-center align-items-center"
      >
        <PageCard />
      </Box>
    </div>
  );
};

export default AdminHomePage;
