import { React, useState, useEffect } from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/Action/Login";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Account, { updateAgrexTheme } from "../../redux/Action/CommonAction";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import OnboardingAction from "../../redux/Action/OnboardingAction";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: "70px",
  height: "40px",
  padding: 6,
  "& .MuiSwitch-switchBase": {
    marginTop: 5,
    padding: 2,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(36px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#4318FF" : "#4318FF",
    width: "24px",
    height: "24px",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 28 / 2,
  },
}));
const THEME_KEY = "myapp_theme";
const LargeWindowTopBar = () => {
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, setTheme] = useState(
    localStorage.getItem(THEME_KEY) === "dark"
  );

  const toggle = state.commonData.toggleSidebar;

  const profileRoute = () => {
    navigate("/profile");
  };
  useEffect(() => {
    updateRootVariables(theme);
    localStorage.setItem(THEME_KEY, theme ? "dark" : "light");
    dispatch(updateAgrexTheme(theme ? "dark" : "light"));
  }, [theme]);
  const handleToggle = () => {
    setTheme((prevTheme) => !prevTheme);
  };
  // updare root varibale color

  const updateRootVariables = (updatedTheme) => {
    const sidebarBgColorValue = updatedTheme ? "#141415" : "#FFFFFF";
    const topbarbgcolor = updatedTheme ? "#313235" : "#e0e5f2";
    const cardTextColor = updatedTheme ? "#FFFFFF" : "#2b3674";
    const inputSelectBgColor = updatedTheme ? "#313235" : "#f4f7fe";
    const cardIconDivColor = updatedTheme ? "#313235" : "#f4f7fe";
    const chatBackground = updatedTheme ? " #222222" : "#FFFFFF";
    const serverSentText = updatedTheme ? "#ffff" : "black";
    const chatbotWelcomeTextColor = updatedTheme ? "#ffff" : "black";
    const loadingSkeleton = updatedTheme ? "#8d8b89" : "#e6e3dc";
    const css = `:root {
      --side-bar-bg-color: ${sidebarBgColorValue};
      --top-bar-bg-color: ${topbarbgcolor};
      --card-text-color:${cardTextColor};
      --input-select-bg-color:${inputSelectBgColor};
      --card-icon-div-bg:${cardIconDivColor};
      --chatbot-bg-color: ${chatBackground};
      --sever-sent-msg-text:${serverSentText};
      --chatbot-welcome-text-color:${chatbotWelcomeTextColor};
      --loading-skeleton:${loadingSkeleton};
    }`;

    const style =
      document.getElementById("myapp_theme") || document.createElement("style");
    style.id = "myapp_theme";
    style.textContent = css;
    document.head.appendChild(style);
  };
  // update root variables in end

  var profile = JSON.parse(localStorage.getItem("profile"));
  let width = toggle ? sizeConfigs.sidebarMin.width : sizeConfigs.sidebar.width;

  const logOutPage = () => {
    toast.dismiss();
    localStorage.clear();
    dispatch(OnboardingAction.clearOnboardingData());
    dispatch(logout());
    dispatch(Account.toglleVideoSidebar(false));
    navigate("/login");
  };

  const toggleNotification = (checked) => {
    Account.toglleMuteNotification(!checked)(dispatch);
    localStorage.setItem("muteNotification", !checked);
    if (!checked) {
      window?.socket?.disconnect();
    } else {
      window?.socket?.connect();
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${width})`,
          ml: width,
          boxShadow: "unset",
          backgroundColor: "var(--top-bar-bg-color)",
          color: colorConfigs.topbar.color,
        }}
      >
        <Toolbar sx={{ marginTop: "10px" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Page / {state.title.title}
            <h4>{state.title.title}</h4>
          </Typography>
          <div style={{ marginRight: "5px" }} className="d-flex">
            <button
              onClick={logOutPage}
              style={{
                border: "none",
                backgroundColor: "var(--top-bar-bg-color)",
              }}
            >
              <h5 style={{ margin: "0px", paddingTop: "4px" }}>
                {" "}
                <span id="log-out-text">Log Out </span>
                <span style={{ marginLeft: "10px" }}>
                  <Tooltip title="Logout" arrow>
                    <LogoutIcon />
                  </Tooltip>
                </span>{" "}
              </h5>
            </button>
          </div>

          <div
            style={{
              backgroundColor: "var(--side-bar-bg-color)",
              borderRadius: "40px",
            }}
          >
            <div style={{ width: "auto", height: "58px", padding: "5px" }}>
              <FormControlLabel
                control={<MaterialUISwitch sx={{ ml: 2 }} />}
                checked={theme}
                onChange={handleToggle}
              />

              <IconButton
                sx={{ p: 1, marginRight: 1, marginTop: "1px" }}
                onClick={profileRoute}
                style={{
                  backgroundColor: "white",
                }}
              >
                <img
                  className="profile-topbar-profile-image"
                  src={profile.account.config["logo"]}
                />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default LargeWindowTopBar;
