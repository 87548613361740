import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import ClientOnboardingActions from "../../redux/Action/ClientOnboarding";
import SelectFieldComp from "../../components/formLayout/SelectFieldComp";
const channelOption = [4, 8, 16, 32, 64, 128];
const brandOption = [
  { label: "Hikvision/Prama", value: "hikvision" },
  { label: "CP PLUS/Dahua", value: "cpplus" },
  { label: "Uniview", value: "uniview" },
  { label: "Indinatus", value: "indinatus" },
  { label: "Others", value: "other" },
];
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { notifiToasterWarn } from "../../components/common/UseToast";

import AutocompleteComp from "../../components/common/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

function OnboardNVR({ isEditable, id }) {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.clientOnboard.currentStep);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [channelCapacity, setChannelCapacity] = useState(4);
  const [brand, setBrand] = useState(brandOption[0]);
  const [ipadd, setIpadd] = useState("");
  const [cameraConnected, setCameraConnected] = useState(0);
  const [httpsPort, setHttpsPort] = useState(0);
  const [httpPort, setHttpPort] = useState(0);
  const [rtspPort, setRtspPort] = useState(0);
  const [storedNvrId, setStoredNvrId] = useState(null);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    name: "",
  });
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [disableAddAnother, setDisableAddAnother] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedPort, setSelectedPort] = useState({
    label: "HTTP",
    value: 0,
  });
  const channels = Array.from({ length: channelCapacity }, (_, i) => i + 1);
  function handleChannelChange(val) {
    setChannelCapacity(val);
  }
  function getChannelUnUsed(channelCapacity) {
    const unusedArray = [];
    for (let channel = 1; channel <= channelCapacity; channel++) {
      if (!selectedChannels.includes(channel)) {
        unusedArray.push(`${channel}01`);
      }
    }

    return unusedArray;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Reset the specific error when the input changes
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
    if (name === "name") setName(value);
    if (name === "cameraConnected") setCameraConnected(value);
    // if (name === "channelUnused") setChannelUnused(value);
    if (name === "brand") setBrand(value);
    if (name === "httpPort") setHttpPort(value);
    if (name === "httpsPort") setHttpsPort(value);
    if (name === "rtspPort") setRtspPort(value);
    if (name === "ipadd") setIpadd(value);
  };

  const handleChannelChangeSelected = (event) => {
    const channel = parseInt(event.target.value, 10);
    if (event.target.checked) {
      // Add the channel if it's checked
      setSelectedChannels([...selectedChannels, channel]);
    } else {
      // Remove the channel if it's unchecked
      setSelectedChannels(selectedChannels.filter((ch) => ch !== channel));
    }
  };
  useEffect(() => {
    let storedNvr = JSON.parse(localStorage.getItem("onboardNvr"));
    if (storedNvr && storedNvr.type == "nvr") {
      setName(storedNvr.name);
      setUsername(storedNvr.username);
      setPassword(storedNvr.password);
      setBrand(storedNvr.brand);
      setIpadd(storedNvr.ip_address);
      setChannelCapacity(storedNvr.channel_capacity);
      setCameraConnected(storedNvr.cameras_connected);
      setHttpPort(storedNvr.http_port_number);
      setHttpsPort(storedNvr.httpsPort);
      setRtspPort(storedNvr.rtsp_port);
      setSelectedChannels(storedNvr.channels_used);
      setStoredNvrId(storedNvr.id);
    }
  }, []);
  useEffect(() => {
    let selected_channel = [];
    for (let i = 1; i <= channelCapacity + 1; i++) {
      selected_channel.push(i);
    }
    setSelectedChannels(selected_channel);
  }, [channelCapacity]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const onboardFacility = localStorage.getItem("onboardFacility");
    const onboardFacility_parse = onboardFacility
      ? JSON.parse(onboardFacility)
      : null;

    // Check if the onboardAccount exists and has an account_id
    let facility_id = onboardFacility_parse
      ? onboardFacility_parse.facility_id
      : null;

    // Proceed based on the presence of account_id
    if (!facility_id) {
      notifiToasterWarn(
        "We are Unable to Find Your facility Details. Please Create a facility  First"
      );
      dispatch({ type: "updateCurrentStep", payload: 2 });
      return;
    }
    let valid = true;
    const newErrors = {
      username: "",
      password: "",
      name: "",
      channelUnused: "",
      ipadd: "",
      brand: "",
      rtspPort: "",
    };

    // Username validation
    if (username.length < 3) {
      valid = false;
      newErrors.username = "Username must be at least 3 characters long";
    }

    if (name.length < 1) {
      valid = false;
      newErrors.name = "Name must be at least 3 characters long";
    }
    // Password validation
    if (password.length < 3) {
      valid = false;
      newErrors.password = "Password must be at least 3 characters long";
    }
    const ipRegex =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (!ipRegex.test(ipadd)) {
      valid = false;
      // Include a sample IP address in the error message for user reference
      newErrors.ipadd = "Invalid IP address. Example: 145.168.1.1";
    }

    if (!channelCapacity) {
      valid = false;
      notifiToasterWarn("Please select channel capacity");
    }
    if (rtspPort == 0 || !rtspPort) {
      valid = false;
      newErrors.rtspPort = "Enter rtsp port";
    }

    const port = selectedPort.label === "HTTP" ? httpPort : httpsPort;
    if (port == "" || port == 0 || !port) {
      valid = false;
      newErrors.httpPort = "Enter  valid http port";
    }
    if (selectedChannels.length == 0) {
      valid = false;
      notifiToasterWarn("Please Select Channels");
    }
    if (valid) {
      // Initialize the NVR data object
      const nvrData = {
        name: name,
        brand: brand.value, // Assuming brand is an object with a value property
        ip_address: ipadd,
        channel_capacity: channelCapacity,
        cameras_connected: channelCapacity,
        channels_unused: getChannelUnUsed(channelCapacity), // Assuming this is a function call to calculate unused channels
        username: username,
        password: password,
        rtsp_port: rtspPort,
        installed_at: facility_id, // Assuming facility_id is defined somewhere in your scope
      };

      // Add the port number based on the selected port
      if (selectedPort.label === "HTTPS") {
        nvrData.https_port_number = httpsPort;
      } else {
        nvrData.http_port_number = httpPort;
      }

      // Set loading state to true before the async operation
      setLoading(true);

      try {
        let nvr_id = -1;
        // Await the asynchronous operation
        if (isEditable) {
          nvr_id = storedNvrId;
          await ClientOnboardingActions.updateNVRDetails(storedNvrId, nvrData);
          await ClientOnboardingActions.deleteCameras(storedNvrId);
          localStorage.removeItem("onboardCameras");
        } else {
          nvr_id = await ClientOnboardingActions.addNVR(nvrData);
          localStorage.setItem("completedStep", 3);
        }
        let data = {
          ...nvrData,
          channels_used: selectedChannels,
          httpPort: httpPort,
          httpsPort: httpsPort,
          brand: brand,
          type: "nvr",
          id: nvr_id,
        };

        // Save the NVR data to local storage
        localStorage.setItem("onboardNvr", JSON.stringify(data));

        // Dispatch an action to update the current step
        dispatch({ type: "updateCurrentStep", payload: activeStep + 1 });
      } catch (error) {
        // Handle any errors that occur during the await call
        console.error("Error updating account info:", error);
      }

      // Set loading state to false after the async operation completes
      setLoading(false);
    } else {
      // Set form errors if validation fails
      setErrors(newErrors);
    }
  };

  function handleSelectPort(e, val) {
    setSelectedPort(val);
  }
  function handleBrandOptionChange(e, val) {
    setBrand(val);
  }

  return (
    <div className="mt-4">
      {!isEditable && <h2 className="mt-2 mb-4">Create NVR/DVR</h2>}
      <form onSubmit={handleSubmit} className="row">
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="name"
            label="NVR/DVR Name"
            variant="outlined"
            fullWidth
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            // required
          />
        </div>

        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="username"
            label="NVR/DVR Username"
            variant="outlined"
            fullWidth
            type="text"
            name="username"
            value={username}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
            // required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="password"
            label="NVR/DVR Password"
            variant="outlined"
            fullWidth
            name="password"
            type="text"
            value={password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
            // required
          />
        </div>

        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <AutocompleteComp
            option={brandOption}
            onChange={handleBrandOptionChange}
            value={brand}
            label="Brand"
            showValue={false}
          />
        </div>

        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="ipadd"
            label="IP Address"
            variant="outlined"
            fullWidth
            type="text"
            name="ipadd"
            value={ipadd}
            onChange={handleChange}
            error={Boolean(errors.ipadd)}
            helperText={errors.ipadd}
            // required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <SelectFieldComp
            options={channelOption}
            value={isNaN(channelCapacity) ? "" : channelCapacity}
            onChange={handleChannelChange}
            label="Channel Capacity"
          />
        </div>

        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <AutocompleteComp
            option={[
              {
                label: "HTTPS",
                value: 0,
              },
              {
                label: "HTTP",
                value: 1,
              },
            ]}
            onChange={handleSelectPort}
            value={selectedPort}
            label="Select Port Type"
            showValue={false}
          />
        </div>
        {selectedPort.label == "HTTPS" ? (
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="httpsPort"
              label="HTTPS Port Number"
              variant="outlined"
              fullWidth
              name="httpsPort"
              type="number"
              value={httpsPort}
              onChange={handleChange}
            />
          </div>
        ) : (
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="httpPort"
              label="HTTP Port Number"
              variant="outlined"
              fullWidth
              name="httpPort"
              type="number"
              value={httpPort}
              error={Boolean(errors.httpPort)}
              helperText={errors.httpPort}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="rtspPort"
            label="RTSP Port Number"
            variant="outlined"
            fullWidth
            name="rtspPort"
            type="number"
            value={rtspPort}
            onChange={handleChange}
            error={Boolean(errors.rtspPort)}
            helperText={errors.rtspPort}
            // required
          />
        </div>

        <div style={{ maxWidth: "100%", overflow: "auto" }}>
          <Typography variant="h6" className="typography-start">
            Select Interested Channels
          </Typography>
          <Grid container spacing={2}>
            {channels.map((index, channel) => (
              <Grid item xs={6} sm={3} md={2} lg={1} key={channel}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={index + 1}
                      checked={selectedChannels.includes(index + 1)}
                      onChange={handleChannelChangeSelected}
                    />
                  }
                  label={`${channel + 1}`}
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <div className="submit-button-onboarding mt-4">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!disableAddAnother || loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : isEditable ? (
              "Update NVR"
            ) : (
              "Add NVR"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}
OnboardNVR.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default OnboardNVR;
