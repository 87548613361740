import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { notifiToasterSuccess } from "../../components/common/UseToast";
import { Details } from "@mui/icons-material";
const AivisLogin = () => {
  const [deviceData, setdeviceData] = useState({
    hostName: "proxy8.remoteiot.com",
    portNumber: "",
    deviceName: "",
    devicePassword: "",
  });

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const newErrors = {};

    if (deviceData.hostName.length < 5) {
      newErrors.hostName = "Host Name should be at least 5 characters long";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }
    sessionStorage.setItem("remoteIotCredential", JSON.stringify(deviceData));
    notifiToasterSuccess("Details saved Successfully");
    // Handle the form submission here
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setdeviceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="device-detail-form">
      <ol>
        <li>
          <a
            href="https://remoteiot.com/portal/#!Device"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>{" "}
          to visit the remote IoT website.
        </li>
        <li>Log in to your account.</li>
        <li>Go to the device section.</li>
        <li>Choose the specific device where you want to add RHMS.</li>
        <li>In the top-left corner, click on the three-line menu icon.</li>
        <li>
          Now, click on Connect Port and you will find the following
          information:
        </li>
      </ol>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="host_name"
              label="Host Name"
              variant="outlined"
              fullWidth
              type="text"
              name="hostName"
              value={deviceData.hostName}
              onChange={handleInputChange}
              error={Boolean(errors.hostName)}
              helperText={errors.hostName}
              required
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="port_Number"
              label="Port Number"
              variant="outlined"
              fullWidth
              type="number"
              name="portNumber"
              value={deviceData.portNumber}
              onChange={handleInputChange}
              error={Boolean(errors.portNumber)}
              helperText={errors.portNumber}
              required
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="username"
              label="Device Username "
              variant="outlined"
              fullWidth
              type="text"
              name="deviceName"
              value={deviceData.deviceName}
              onChange={handleInputChange}
              error={Boolean(errors.deviceName)}
              helperText={errors.deviceName}
              required
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="password"
              label="Device Password"
              variant="outlined"
              fullWidth
              type="text"
              name="devicePassword"
              value={deviceData.devicePassword}
              onChange={handleInputChange}
              error={Boolean(errors.devicePassword)}
              helperText={errors.devicePassword}
              required
            />
          </div>
        </div>
        <div className="row mt-sm-3">
          <div className="col-12 col-sm-6 col-lg-3 mb-3">
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{ minWidth: "100px" }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                "Save Details"
              )}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AivisLogin;
