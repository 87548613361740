import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Oval } from "react-loader-spinner";
import PropTypes from "prop-types";
import MultiSelectFreeSolo from "../../components/formLayout/MultiSelectFreeSolo";
import FleetDashboardAPI from "./fleetDashAPIs";
import getDataFromAPI from "../Onboarding/Utils/getDataFromAPI";
const EmailSettingModal = ({ handleChange, id, data }) => {
  const [open, setOpen] = useState(true);
  const [accountEmail, setAccountEmail] = useState([]);
  const [facilityEmail, setFacilityEmail] = useState([]);
  const [deviceEmails, setDeviceEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatingEmailsAccount, SetUpdateLoadingAccount] = useState(false);
  const [updatingEmailsDevice, SetUpdateLoadingDevice] = useState(false);
  const [updatingEmailsFacility, SetUpdateLoadingfacility] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  async function fetchEmailId(id) {
    setLoading(true);
    const emailData = await FleetDashboardAPI.getEmailViaDeviceIDForDevice(id);
    setAccountEmail(emailData.emails_account);
    setFacilityEmail(emailData.emails_facility);
    setDeviceEmails(emailData.emails_device);
    setLoading(false);
  }
  useEffect(() => {
    if (!id) return;
    fetchEmailId(id);
  }, []);

  const handleClose = () => {
    handleChange(false);
  };

  const handleAccountEmailChange = (newValue) => {
    setAccountEmail(newValue);
  };

  const handleFacilityEmailChange = (newValue) => {
    setFacilityEmail(newValue);
  };
  const handleDeviceEmailChange = (newValue) => {
    setDeviceEmails(newValue);
  };
  async function UpdateEmailAddress(usedIn) {
    if (usedIn == "accounts") {
      SetUpdateLoadingAccount(true);
      await FleetDashboardAPI.editAccountEmail(data.accountId, {
        email_list: accountEmail,
      });
      SetUpdateLoadingAccount(false);
    } else if (usedIn == "facility") {
      SetUpdateLoadingfacility(true);
      await FleetDashboardAPI.editFacilityEmail(data.facilityId, {
        email_list: facilityEmail,
      });
      SetUpdateLoadingfacility(false);
    } else {
      SetUpdateLoadingDevice(true);
      await FleetDashboardAPI.editDeviceEmail(id, {
        email_list: deviceEmails,
      });
      SetUpdateLoadingDevice(false);
    }
  }
  return (
    <div>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>Email Settings</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <Oval
              height={60}
              width={60}
              color="black"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="black"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            <DialogContent dividers>
              <div>
                <p>Account Email Details</p>
                <span>Emails associated with account names are below:</span>

                <div className="message-select-field mt-2 py-2 row">
                  <div className="col-md-8 col-12">
                    <MultiSelectFreeSolo
                      value={accountEmail}
                      onChangeFunc={handleAccountEmailChange}
                      label={"Account Email Setting"}
                      placeholder={"add new Email"}
                    />
                  </div>
                  <div className="d-flex col-md-4 col-12 justify-md-content-center align-items-center">
                    <Button
                      variant="contained"
                      onClick={() => UpdateEmailAddress("accounts")}
                    >
                      {updatingEmailsAccount ? "Updating ..." : "Update Emails"}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <p>Facility Email Details</p>
                <span>Emails associated with facility names are below:</span>
                <div className="message-select-field mt-2 py-2 row">
                  <div className="col-md-8 col-12">
                    <MultiSelectFreeSolo
                      value={facilityEmail}
                      onChangeFunc={handleFacilityEmailChange}
                      label={"Facility Email Setting"}
                      placeholder={"add new Email"}
                    />
                  </div>
                  <div className="d-flex col-md-4 col-12 justify-md-content-center align-items-center">
                    <Button
                      variant="contained"
                      onClick={() => UpdateEmailAddress("facility")}
                    >
                      {updatingEmailsFacility
                        ? "Updating ..."
                        : "Update Emails"}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <p>Device Email Details</p>
                <span>Emails associated with Devices only are below:</span>
                <div className="message-select-field mt-2 py-2 row">
                  <div className="col-md-8 col-12">
                    <MultiSelectFreeSolo
                      value={deviceEmails}
                      onChangeFunc={handleDeviceEmailChange}
                      label={"Device Email"}
                      placeholder={"add new Email"}
                    />
                  </div>
                  <div className="d-flex col-md-4 col-12 justify-md-content-center align-items-center">
                    <Button
                      variant="contained"
                      onClick={() => UpdateEmailAddress("device")}
                    >
                      {updatingEmailsDevice ? "Updating ..." : "Update Emails"}
                    </Button>
                  </div>
                </div>
              </div>
              <span>
                Note: Type an email and press Enter to add it to the list
              </span>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};
EmailSettingModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
};
export default EmailSettingModal;
