import React, { useEffect, useState } from "react";
import OverviewTable from "./OverviewTable";
import OperationModeAction from "../../../redux/Action/OperationModeAction";
import { useSelector, useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import { Bars } from "react-loader-spinner";
const AccountTable = () => {
  const operationState = useSelector((state) => state.Approval);
  const dispatch = useDispatch();
  const profile_id = localStorage.getItem("profile_id");
  const [accountList, setAccountList] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(OperationModeAction.getAllAccount(profile_id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    dispatch(changeTopbarTitle("Admin/Account"));
  }, []);

  useEffect(() => {
    setAccountList(operationState.accountList);
  }, [operationState]);
  function searchAccount() {
    if (searchItem.length > 0) {
      setLoading(true);
      dispatch(OperationModeAction.getSearchAccount(searchItem))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }
  useEffect(() => {
    if (searchItem.length >= 3) {
      searchAccount();
    } else if (searchItem.length == 0) {
      setLoading(true);
      dispatch(OperationModeAction.getAllAccount(profile_id))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [searchItem]);
  return (
    <div className="config-page-container">
      <div className="d-flex mb-3 col-4">
        <input
          className="form-control mr-sm-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
          value={searchItem}
          onChange={(e) => setSearchItem(e.target.value)}
        />
        <button
          className="btn btn-outline-primary mx-2 my-sm-0 "
          onClick={searchAccount}
        >
          Search
        </button>
      </div>
      {!loading ? (
        <OverviewTable data={accountList} />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </div>
  );
};

export default AccountTable;
