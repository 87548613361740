// import testData from "../../pages/Approval/testData";
const initialState = {
  accountList: [],
  accountDict: {},
  facilityList: [],
  cameraList: [],
  facilityByAccountList: [],
  alertEventCard: [],
  currentIndex: 0,
};

export default function OperationModeReducer(state = initialState, action) {
  switch (action.type) {
    case "STORE_LIST_DATA_MODAL":
      return {
        ...state,
        facilityList: action.payload,
      };
    case "CLIENT_LIST_DATA_MODAL":
      return {
        ...state,
        clientList: action.payload,
      };
    case "DIVICES_LIST_DATA_MODAL":
      return { ...state, deviceList: action.payload };
    case "CAMERAS_LIST_DATA_MODAL":
      return { ...state, cameraList: action.payload };
    case "ACCOUNT_LIST_DATA_MODAL":
      return { ...state, accountList: action.payload };
    case "ACCOUNT_DICT_DATA_MODAL":
      return { ...state, accountDict: action.payload };
    case "FACILITY_BY_ACCOUNT_LIST_DATA_MODAL":
      return { ...state, facilityByAccountList: action.payload };
    case "UPDATE_ALERT_EVENT_CARD":
      return { ...state, alertEventCard: action.payload };
    case "UPDATE_CURRENT_PAGE":
      return { ...state, currentIndex: action.payload };
    case "UPDATE_ALERT_EVENT_CARD_AT_INDEX": {
      const { index, newData } = action.payload;

      // Make a copy of the alertEventCard array and update the data at the specified index
      const updatedAlertEventCard = [...state.alertEventCard];
      updatedAlertEventCard[index] = newData;

      return {
        ...state,
        alertEventCard: updatedAlertEventCard,
      };
    }
    default:
      return state;
  }
}
