import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    // bg: "#233044",
    bg: "#FFFFFF",

    // color: "#eeeeee",
    color: "#2B3674",

    // hoverBg: "#1e293a",
    hoverBg: "#FAF9F6",

    activeBg: "#1e253a",
  },
  topbar: {
    // bg: "#fff",
    bg: "#E0E5F2",
    color: "#707EAE",
  },
  tableHeadColor: "#EFF1FE",
  iconColor: "#4318FF",
  mainBg: "#E0E5F2",
  buttonColorProfilePage: "#2B3674",
};

export default colorConfigs;
