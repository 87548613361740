import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import CheckboxAutocomplete from "../../components/formLayout/CheckboxAutoComplete";
import CameraWiseDateTimeSelection from "./Utils/CameraWiseDateTimeSelection";
import {
  notifiToasterInfo,
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import Button from "@mui/material/Button";
import ClientOnboardingActions from "../../redux/Action/ClientOnboarding";
import { useDispatch } from "react-redux";
const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const IntrusionService = () => {
  const [cameraList, setCameraList] = useState([]);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [formData, setFormData] = useState({});
  const [intrusionLoading, setIntrusionLoading] = useState(false);
  const isAlreadyEnabled = JSON.parse(localStorage.getItem("isInOn")) === true;

  const dispatch = useDispatch();
  useEffect(() => {
    const standAlone = JSON.parse(localStorage.getItem("standaloneCamera"));
    let cameras = [];
    if (standAlone) {
      cameras = standAlone.cameras;
    } else {
      cameras = JSON.parse(localStorage.getItem("onboardCameras")) ?? [];
    }
    const newCameraList = cameras.map(
      ({ id, camera_name, channel_number }) => ({
        id,
        value: camera_name,
        channel_number,
      })
    );
    setCameraList(newCameraList);
  }, []);

  const handleCameraDataChange = (cameraId, newData) => {
    setFormData((prevData) => ({ ...prevData, [cameraId]: newData }));
  };
  function formatData() {
    try {
      const facilityDetails = JSON.parse(
        localStorage.getItem("onboardFacility")
      );
      const nvrDetails = JSON.parse(localStorage.getItem("onboardNvr"));

      if (!facilityDetails || !nvrDetails) {
        console.error("Facility or NVR details are missing");
        return; // Exit the function if critical data is missing
      }

      const { facility_name: site_name, facility_id: site_id } =
        facilityDetails;

      const {
        brand: { value: vendor },
        username,
        password,
        ip_address: ip_add,
        rtsp_port,
      } = nvrDetails;

      if (
        !site_name ||
        !site_id ||
        !vendor ||
        !username ||
        !password ||
        !ip_add ||
        !rtsp_port
      ) {
        console.error(
          "One or more essential NVR or Facility details are missing or incomplete"
        );
        return; // Exit the function if essential details are missing
      }

      if (!formData || !selectedCameras) {
        console.error("Camera data or selected cameras are missing");
        return; // Exit the function if camera data is missing
      }

      var finalData = [];
      console.log(formData);
      Object.keys(formData).forEach((cameraId) => {
        const camera = formData[cameraId];
        const cam_fetched = selectedCameras.find(
          (item) => String(item.id) === cameraId
        );

        if (!cam_fetched) {
          console.error(
            `Camera with ID ${cameraId} not found among selected cameras`
          );
          return; // Continue to next iteration in forEach
        }

        let operationDays = [];
        let timings = [];

        let atleastOneDay = false;
        camera.times.forEach((time) => {
          if (time.active) {
            atleastOneDay = true;
            operationDays.push(time.day);
            timings.push(`${time.startTime},${time.endTime}`);
          }
        });
        if (!atleastOneDay) {
          notifiToasterInfo(
            `Please Select atleast one Operation Day for camera ${cam_fetched.value} `
          );
          return;
        }
        let profile =
          JSON.parse(localStorage.getItem("profile"))?.name || "Unknown";
        let formatted_site_name = `${site_name}_${cam_fetched.value}`.replace(
          / /g,
          "_"
        );

        const data = {
          camera_name: formatted_site_name,
          facility_name: site_name.replace(/ /g, "_") + "_intrusion",
          ip: ip_add,
          username: username,
          password: password,
          vendor: vendor.toLowerCase(),
          channel: cam_fetched.channel_number,
          protocol: "rtsp",
          port: rtsp_port,
          camera_id: cameraId,
          facility_id: site_id,
          timeout_sec: 10,
          timings: timings,
          operational_days: operationDays,
          people_count: camera.isCrowdDetectionEnabled ? camera.crowdCount : 1,
          added_by: profile,
        };

        finalData.push(data);
      });

      return finalData; // Optionally return data for further processing
    } catch (error) {
      console.error("An error occurred during the formatting of data:", error);
    }
  }
  async function handleEnableIntrusion() {
    let data = formatData();
    setIntrusionLoading(true);
    if (data.length > 0) {
      await ClientOnboardingActions.enableIntrusionAutomation(
        data,
        "intrusion"
      );
      localStorage.setItem("completedStep", 6);
      dispatch({ type: "updateCurrentStep", payload: 6 });
    } else {
      notifiToasterWarn("No cameras are selected for intrusion.");
    }
    setIntrusionLoading(false);
  }
  return (
    <>
      <div className="mb-4">
        <Typography variant="h6" gutterBottom>
          Enable Intrusion
        </Typography>
      </div>
      <CheckboxAutocomplete
        value={selectedCameras}
        onChange={(event, newValue) => setSelectedCameras(newValue)}
        label="Select Cameras On which Intrusion Works"
        options={cameraList}
        size="medium"
      />
      {selectedCameras.map((camera, index) => (
        <CameraWiseDateTimeSelection
          key={camera.id}
          camera={camera}
          index={index}
          data={formData[camera.id] || {}}
          changeData={(newData) => handleCameraDataChange(camera.id, newData)}
        />
      ))}
      <div className="d-flex justify-content-end">
        <Button
          variant="contained"
          onClick={handleEnableIntrusion}
          disabled={intrusionLoading || isAlreadyEnabled}
        >
          Enable Intrusion
        </Button>
      </div>
    </>
  );
};

export default IntrusionService;
