const initialState = {
  profileData: {
    email_address: [],
    phone_numbers: [""],
    location: "",
    whatsapp_enabled: true,
    offline_alerts: false,
  },
  userSettingData: [
    {
      id: 0,
      name: "",
      user__email: "",
      profile_type: "",
      user__is_active: false,
      group_names: "",
    },
  ],
};

export default function ProfileReducer(state = initialState, action) {
  switch (action.type) {
    case "PROFILE_DATA": {
      const obj = {
        email_address: action.payload.email_address,
        phone_numbers: action.payload.phone_numbers,
        location: action.payload.account.location,
        whatsapp_enabled: action.payload.whatsapp_enabled,
        offline_alerts: action.payload.offline_alerts,
      };
      return { ...state, profileData: obj };
    }
    case "USER_SETTING_DATA":
      return { ...state, userSettingData: action.payload };
    default:
      return state;
  }
}
