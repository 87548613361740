import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import getDataFromAPI from "../../Utils/getDataFromAPI";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AutocompleteCompUncontrollable from "../../../../components/common/AutoCompleteUncontrollable";
import { useDispatch } from "react-redux";
import OnboardingAction from "../../../../redux/Action/OnboardingAction";
import { notifiToasterWarn } from "../../../../components/common/UseToast";
import PropTypes from "prop-types";
export default function BulkCreateCamera({ isOpen, handleModalClose }) {
  const [open, setOpen] = React.useState(false);
  const [nvrList, setNVRList] = React.useState([]);
  const [connectedTo, setConnectTo] = React.useState(null);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  function generateCameras() {
    if (connectedTo && connectedTo.value) {
      dispatch(OnboardingAction.BulkCreateCameras(connectedTo.value)).then(
        () => {
          handleClose();
        }
      );
    } else {
      notifiToasterWarn("Please Select NVR ");
    }
  }
  const handleClose = () => {
    setOpen(false);
    handleModalClose(false);
  };
  function handleConnectedToChange(value) {
    setConnectTo(value);
  }
  React.useEffect(() => {
    const fetchData = async () => {
      const nvrList = await getDataFromAPI.getAllNVR();
      setNVRList(nvrList);
    };

    fetchData();
  }, []);
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Auto Create Camera</DialogTitle>
        <DialogContent style={{ height: "30vh" }}>
          <DialogContentText style={{ marginBottom: "5vh" }}>
            Select the NVR For which you want to create Auto Cameras.
          </DialogContentText>
          <AutocompleteCompUncontrollable
            option={nvrList}
            onChange={handleConnectedToChange}
            label="Connected To"
            disableClearable={true}
            showValue={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={generateCameras}>Generate Cameras</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
BulkCreateCamera.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};
