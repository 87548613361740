import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import { styled, alpha } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InputBase from "@mui/material/InputBase";
// import EditAccountModal from "./EditAccountModal";
// import EditUserDetails from "./EditUserModal";
// import EditUserProfileModal from "./EditUserProfileModal";
import EditFacilityModal from "./EditFacilityModal";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OperationModeAction from "../../../redux/Action/OperationModeAction";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function FacilityTable({ data, getUserList }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [modalId, setModalId] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [toDelete, setToDelte] = useState("");
  const [idToDelete, SetToDeleteId] = useState(null);
  const [addnewAccount, setAddNewAccount] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  function handleAddAccount() {
    setAddNewAccount(true);
    setShowModal(true);
    setModalId(null);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  function handleConfirmDelete() {
    if (idToDelete) {
      const profile_id = localStorage.getItem("profile_id");
      dispatch(OnboardingAction.deleteAccount(idToDelete)).then(() => {
        handleClose();
        dispatch(OperationModeAction.getAllAccount(profile_id));
      });
    }
  }
  const handleDeleteCamera = (id, name) => {
    setToDelte(name);
    SetToDeleteId(id);
    handleClickOpen();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleEditCamera = (id) => {
    setAddNewAccount(false);
    setShowModal(true);
    setModalId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModalId(-1);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <>
      <div className="d-flex justify-content-end px-1 mb-2">
        <div>
          <Button variant="contained" onClick={handleAddAccount}>
            Add New
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow sx={{ width: "100%" }}>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell align="left">Facility Name</StyledTableCell>
                <StyledTableCell align="left">Account</StyledTableCell>
                <StyledTableCell align="left">Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            {data && data.length > 0 && (
              <>
                <TableBody>
                  {(rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row, index) => (
                    <TableRow key={row.id + "-" + index}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.facility_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.account_name}
                      </TableCell>

                      <TableCell align="left">
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEditCamera(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      {/* <TableCell align="left">
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            handleDeleteCamera(row.id, row.username)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[25, { label: "All", value: -1 }]}
                      colSpan={4}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </>
            )}
          </Table>
          {modalId != -1 && (
            <EditFacilityModal
              addNew={addnewAccount}
              open={showModal}
              id={modalId}
              handleClose={handleCloseModal}
              getUserList={getUserList}
            />
          )}
        </>
      </TableContainer>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Account"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete {toDelete}? Deleting this will
              also delete all related details
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
FacilityTable.propTypes = {
  data: PropTypes.array.isRequired,
  getUserList: PropTypes.func.isRequired,
};
