import axios from "axios";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import OperationDashEndpoints from "../features/OperationDashEndpoints";
// get Store

class OnboardingAction {
  static updateCurrentStep(data) {
    return async (dispatch) => {
      localStorage.setItem("activeStep", data);
      dispatch({ type: "UPDATE_CURRENT_STEP", payload: data });
    };
  }
  static createUser = (data) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .post(OperationDashEndpoints.createUser(), data, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          const { id, email, name } = response.data; // Assuming these fields are available in the response data
          notifiToasterSuccess("User created Successfully");
          localStorage.setItem(
            "onboardUser",
            JSON.stringify({
              userId: id,
              userEmail: email,
              name: name,
              token: token,
            })
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error.includes("already exists")
          ) {
            notifiToasterWarn("User with this name already exists");
          } else {
            notifiToasterWarn("Unable to add user");
          }
          throw error;
        });
    };
  };

  static createUserProfileMapping = (finalData) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .post(OperationDashEndpoints.saveFacilityProfileMapping(), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          if (response.status == 201) {
            notifiToasterSuccess("Mapping saved successfully");
          } else {
            notifiToasterWarn(response.data.facility);
          }
          // Handle a successful response
        })
        .catch((error) => {
          console.error("POST request error:", error);
          notifiToasterWarn("Unable To create user profile mapping");
        });
    };
  };
  static updateUserProfileMapping = (id, finalData) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .put(OperationDashEndpoints.getAllFacilityProfilesById(id), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          notifiToasterSuccess("Mapping updated successfully");
        })
        .catch((error) => {
          console.error("POST request error:", error);
          notifiToasterWarn("Unable To update user profile mapping");
        });
    };
  };
  static createNVR = (finalData) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .post(OperationDashEndpoints.createNVR(), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          if (response.status == 201) {
            notifiToasterSuccess("NVR Added");
            dispatch({
              type: "UPDATE_NEW_ADDED_NVR",
              payload: response.data,
            });
            var prevData =
              JSON.parse(localStorage.getItem("PreviouslyAddedNVR")) || [];
            prevData.push(response.data);
            localStorage.setItem(
              "PreviouslyAddedNVR",
              JSON.stringify(prevData)
            );
          } else {
            notifiToasterWarn(response.data.facility);
          }
          // Handle a successful response
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("POST request error:", error);
          notifiToasterWarn("Unable to add NVR");
        });
    };
  };
  static deleteNVR = (id) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      axios
        .delete(OperationDashEndpoints.getNvrById(id), {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          notifiToasterSuccess("NVR Deleted");
          dispatch({
            type: "DELETE_SELECTED_NVR",
            payload: id,
          });
          var prevData =
            JSON.parse(localStorage.getItem("PreviouslyAddedNVR")) || [];
          const updatedPrevData = prevData.filter((item) => item.id !== id);
          localStorage.setItem(
            "PreviouslyAddedNVR",
            JSON.stringify(updatedPrevData)
          );
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("Delete request error:", error);
          notifiToasterWarn("Unable to delete NVR");
        });
    };
  };
  static deleteAccount = (id) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .delete(OperationDashEndpoints.getAccountById(id), {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          notifiToasterSuccess("Account Deleted");
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("Delete request error:", error);
          notifiToasterWarn("Unable to delete Account");
        });
    };
  };
  static deleteUser = (id) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .delete(OperationDashEndpoints.getAccountById(id), {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          notifiToasterSuccess("Account Deleted");
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("Delete request error:", error);
          notifiToasterWarn("Unable to delete Account");
        });
    };
  };
  static updateNVR = (finalData, id) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        const response = await axios.patch(
          OperationDashEndpoints.getNvrById(id),
          finalData,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (response.status === 200) {
          notifiToasterSuccess("NVR Details Updated");
          dispatch({
            type: "UPDATE_MODIFIED_NVR",
            payload: response.data,
          });
          var prevData =
            JSON.parse(localStorage.getItem("PreviouslyAddedNVR")) || [];

          const updatedNVR = prevData.map((nvr) => {
            if (nvr.id === id) {
              return response.data;
            }
            return nvr;
          });
          localStorage.setItem(
            "PreviouslyAddedNVR",
            JSON.stringify(updatedNVR)
          );
        } else {
          notifiToasterWarn("Unable to update NVR");
        }
      } catch (error) {
        // Handle any errors that occurred during the POST request
        console.error("PATCH request error:", error);
        notifiToasterWarn("Unable to update NVRs");
      }
    };
  };
  static updateUserDetails = (id, finalData) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        const response = await axios.patch(
          OperationDashEndpoints.getUserById(id),
          finalData,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (response.status === 200) {
          notifiToasterSuccess("User Details Updated");
        } else {
          notifiToasterWarn("Unable to update user");
        }
      } catch (error) {
        // Handle any errors that occurred during the POST request
        console.error("PATCH request error:", error);
        notifiToasterWarn("Unable to update User");
      }
    };
  };
  static updateFacility = (id, finalData) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        const response = await axios.patch(
          OperationDashEndpoints.getFacilityById(id),
          finalData,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (response.status === 200) {
          notifiToasterSuccess("Facility Details Updated");
        } else {
          notifiToasterWarn("Unable to update Facility Details");
        }
      } catch (error) {
        // Handle any errors that occurred during the POST request
        console.error("PATCH request error:", error);
        notifiToasterWarn("Unable to update Facility Details");
      }
    };
  };
  static updateUserProfileByid = (id, finalData) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        const response = await axios.put(
          OperationDashEndpoints.getProfileById(id),
          finalData,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (response.status === 200) {
          notifiToasterSuccess("Userprofile Details Updated");
        } else {
          notifiToasterWarn("Unable to update Userprofile");
        }
      } catch (error) {
        // Handle any errors that occurred during the POST request
        console.error("PATCH request error:", error);
        notifiToasterWarn("Unable to update Userprofile");
      }
    };
  };
  static createAccount = (finalData) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .post(OperationDashEndpoints.createAccount(), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          if (response.status == 201) {
            notifiToasterSuccess("Account Added");
            localStorage.setItem(
              "newlyCreatedAccount",
              response.data.account_id
            );
          } else {
            notifiToasterWarn(response.data.facility);
          }
          // Handle a successful response
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("POST request error:", error);
          notifiToasterWarn("Unable to add Account");
        });
    };
  };
  static editAccount = (id, finalData) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .put(OperationDashEndpoints.getAccountById(id), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          notifiToasterSuccess("Account Updated");
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("POST request error:", error);
          notifiToasterWarn("Unable to update Account");
        });
    };
  };
  static createUserProfile = (finalData) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .post(OperationDashEndpoints.getAllUserProfiles(), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          if (response.status == 201) {
            notifiToasterSuccess("User Profile Added");
          } else {
            notifiToasterWarn(response.data);
          }
          // Handle a successful response
        })
        .catch((error) => {
          if (error.response.data && error.response.data.user[0]) {
            notifiToasterWarn(error.response.data.user[0]);
          } else {
            // Handle any errors that occurred during the POST request
            console.error("POST request error:", error.response.data.user);
            notifiToasterWarn("Unable To create user profile");
          }
        });
    };
  };
  static createFacility = (finalData) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .post(OperationDashEndpoints.createFacility(), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          if (response.status == 201) {
            notifiToasterSuccess("Facility Added successfully");
            dispatch({
              type: "UPDATE_NEW_ADDED_FACILITIES",
              payload: response.data,
            });
            var prevData =
              JSON.parse(localStorage.getItem("PreviouslyAddedFacilities")) ||
              [];
            prevData.push(response.data);
            localStorage.setItem(
              "PreviouslyAddedFacilities",
              JSON.stringify(prevData)
            );
          } else {
            notifiToasterWarn(response.data);
          }
          // Handle a successful response
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("POST request error:", error);
          notifiToasterWarn("Unable to add User Profile");
        });
    };
  };
  static deleteFacility = (id) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      axios
        .delete(OperationDashEndpoints.getFacilityById(id), {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          notifiToasterSuccess("Facility Deleted successfully");
          dispatch({
            type: "DELETE_SELECTED_FACILITY",
            payload: id,
          });
          var prevData =
            JSON.parse(localStorage.getItem("PreviouslyAddedFacilities")) || [];
          const updatedCamera = prevData.filter(
            (facility) => facility.id !== id
          );
          localStorage.setItem(
            "PreviouslyAddedFacilities",
            JSON.stringify(updatedCamera)
          );
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("POST request error:", error);
          notifiToasterWarn("Unable to add User Profile");
        });
    };
  };
  static createCamera = (finalData) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        const response = await axios.post(
          OperationDashEndpoints.createCamerasAutoRtsp(),
          finalData,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (response.status === 201) {
          notifiToasterSuccess("Camera Added");
          dispatch({
            type: "UPDATE_NEW_ADDED_CAMERAS",
            payload: response.data,
          });
          var prevData =
            JSON.parse(localStorage.getItem("PreviouslyAddedCamera")) || [];
          prevData.push(response.data);
          localStorage.setItem(
            "PreviouslyAddedCamera",
            JSON.stringify(prevData)
          );
        } else {
          notifiToasterWarn(response.data);
        }
      } catch (error) {
        // Handle any errors that occurred during the POST request
        console.error("POST request error:", error);
        notifiToasterWarn("Unable to add Camera");
      }
    };
  };
  static BulkCreateCameras = (id) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .post(
          OperationDashEndpoints.bulkCreateCamera(id),
          {},
          {
            headers: { Authorization: `Token ${token}` },
          }
        )
        .then((response) => {
          notifiToasterSuccess("Cameras Generated");
          localStorage.setItem(
            "PreviouslyAddedCamera",
            JSON.stringify(response.data.created_cameras)
          );
          dispatch({
            type: "BULK_ADDED_CAMERAS",
            payload: response.data.created_cameras,
          });
        })
        .catch((error) => {
          console.error("POST request error:", error.response.data.message);
          notifiToasterWarn(error.response.data.message);
        });
    };
  };
  static updateCamera = (finalData, id) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        const response = await axios.patch(
          OperationDashEndpoints.getCameraById(id),
          finalData,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (response.status === 200) {
          notifiToasterSuccess("Camera Details Updated");
          dispatch({
            type: "UPDATE_MODIFIED_ADDED_CAMERAS",
            payload: response.data,
          });
          var prevData =
            JSON.parse(localStorage.getItem("PreviouslyAddedCamera")) || [];

          const updatedCameras = prevData.map((facility) => {
            if (facility.id === id) {
              return response.data;
            }
            return facility;
          });
          localStorage.setItem(
            "PreviouslyAddedCamera",
            JSON.stringify(updatedCameras)
          );
        } else {
          notifiToasterWarn(response.data);
        }
      } catch (error) {
        // Handle any errors that occurred during the POST request
        console.error("PATCH request error:", error);
        notifiToasterWarn("Unable to update Camera");
      }
    };
  };

  static addDevice = (finalData) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        const response = await axios.post(
          OperationDashEndpoints.getDeviceList(),
          finalData,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (response.status === 201) {
          notifiToasterSuccess("Device Details added");
          dispatch({
            type: "DEVICE_ADDED",
            payload: response.data,
          });
          var prevData =
            JSON.parse(localStorage.getItem("PreviouslyAddedDevice")) || [];
          prevData.push(response.data);
          localStorage.setItem(
            "PreviouslyAddedDevice",
            JSON.stringify(prevData)
          );
        } else {
          notifiToasterWarn(response.data);
        }
      } catch (error) {
        // Handle any errors that occurred during the POST request
        console.error("PATCH request error:", error);
        notifiToasterWarn("Unable to add device");
      }
    };
  };
  static updateDevice = (id, finalData) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        const response = await axios.put(
          OperationDashEndpoints.getDeviceListById(id),
          finalData,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        notifiToasterSuccess("Device Details Updated");
      } catch (error) {
        // Handle any errors that occurred during the POST request
        console.error("PATCH request error:", error);
        notifiToasterWarn("Unable to Update device details");
      }
    };
  };

  static deleteCamera = (id) => {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        await axios
          .delete(OperationDashEndpoints.getCameraById(id), {
            headers: { Authorization: `Token ${token}` },
          })
          .then((response) => {
            notifiToasterSuccess("Camera Deleted Successfully");
            dispatch({
              type: "DELETE_SELECTED_CAMERA",
              payload: id,
            });
            var prevData =
              JSON.parse(localStorage.getItem("PreviouslyAddedCamera")) || [];
            const updatedCamera = prevData.filter((camera) => camera.id !== id);
            localStorage.setItem(
              "PreviouslyAddedCamera",
              JSON.stringify(updatedCamera)
            );
          });
      } catch (error) {
        console.error("PATCH request error:", error);
        notifiToasterWarn("Unable to Delete Camera");
      }
    };
  };
  static clearOnboardingData = () => {
    return async (dispatch) => {
      dispatch({ type: "CLEAR_REDUCER_FOR_ONBOARDING" });
    };
  };
  static addUseCaseMapping = (finalData) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .post(OperationDashEndpoints.addUseCaseMapping(), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          if (response.status == 201) {
            notifiToasterSuccess("Mapping Added");
          } else {
            notifiToasterWarn(response.data.facility);
          }
          // Handle a successful response
        })
        .catch((error) => {
          // Handle any errors that occurred during the POST request
          console.error("POST request error:", error);
          notifiToasterWarn("Unable to add NVR");
        });
    };
  };
  static editUseCaseMapping = (finalData, id) => {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      await axios
        .put(OperationDashEndpoints.getUseCaseMappingById(id), finalData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          if (response.status == 200) {
            notifiToasterSuccess("Mapping Update");
          } else {
            notifiToasterWarn("Unable to update Mapping");
          }
        })
        .catch((error) => {
          console.error("POST request error:", error);
          notifiToasterWarn("Unable to add NVR");
        });
    };
  };
}

export default OnboardingAction;
