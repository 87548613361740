import OperationDashEndpoints from "../../redux/features/OperationDashEndpoints";
import axios from "axios";
import {
  notifiToasterWarn,
  notifiToasterSuccess,
} from "../../components/common/UseToast";

class FleetDashboardAPI {
  static async getAllDevicesFromIOt() {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getDeviceFromRemoteIOT(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      return response.data.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getFleetDevices() {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.retrieveFleetDevices(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getFleetDevicesViaAccount(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.retrieveFleetDevicesViaAccount(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getFleetDevicesViaFacility(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.retrieveFleetDevicesViaFacility(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getFleetDevicesViaChannel(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.retrieveFleetDevicesViaChannel(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getFleetViaSeach(string) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.retrieveFleetDevicesViaSearch(string),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getAllLabels() {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(OperationDashEndpoints.labelForFleet(), {
        headers: { Authorization: `Token ${token}` },
      });

      const accountData = response.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return accountData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getDeviceDetails(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getDeviceListById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async addDeviceToFleet(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getFacilityById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async updateDevcieDetails(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getFacilityById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getEmailViaDeviceIDForDevice(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getEmailIdViaDeviceId(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async getChannelEmails(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getChannelDetailsById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async UpdateChannelEmails(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getFacilityById(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
  static async editAccountEmail(id, finalData) {
    var token = localStorage.getItem("token");
    await axios
      .patch(OperationDashEndpoints.getAccountById(id), finalData, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        notifiToasterSuccess("Account Email Updated");
      })
      .catch((error) => {
        // Handle any errors that occurred during the POST request
        console.error("POST request error:", error);
        notifiToasterWarn("Unable to update Account emails");
      });
  }
  static async editFacilityEmail(id, finalData) {
    var token = localStorage.getItem("token");
    await axios
      .patch(OperationDashEndpoints.getFacilityById(id), finalData, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        notifiToasterSuccess("Facility Email Updated");
      })
      .catch((error) => {
        // Handle any errors that occurred during the POST request
        console.error("POST request error:", error);
        notifiToasterWarn("Unable to update Facility emails");
      });
  }
  static async editDeviceEmail(id, finalData) {
    var token = localStorage.getItem("token");
    await axios
      .patch(OperationDashEndpoints.getDeviceListById(id), finalData, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        notifiToasterSuccess("Device Email Updated");
      })
      .catch((error) => {
        // Handle any errors that occurred during the POST request
        console.error("POST request error:", error);
        notifiToasterWarn("Unable to update Device emails");
      });
  }
  static async editChannelEmail(id, finalData) {
    var token = localStorage.getItem("token");
    await axios
      .patch(OperationDashEndpoints.getChannelDetailsById(id), finalData, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        notifiToasterSuccess("Channel Email Updated");
      })
      .catch((error) => {
        // Handle any errors that occurred during the POST request
        console.error("POST request error:", error);
        notifiToasterWarn("Unable to update Channel  emails");
      });
  }
  static async getAllAccountViaChannel(id) {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.getAccountByChannelId(id),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      const accountData = response.data.data.map((item) => ({
        label: item.name,
        value: item.account_id,
      }));

      return accountData;
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  }
}
export default FleetDashboardAPI;
