import React from "react";
import FacilityProfileMapping from "../Steps/FacilityProfileMapping";
import CreateUser from "../Steps/CreateUser";
import AddNvrDvr from "../Steps/AddNvrDvr";
import AddAccount from "../Steps/AddAccount";
import Userprofile from "../Steps/Userprofile";
import AddFacilities from "../Steps/AddFacility";
import AddCamera from "../Steps/AddCamera";
import AddDevice from "../Steps/AddDevice";
import { useSelector } from "react-redux";
const FromComponent = () => {
  const state = useSelector((state) => state.onboarding);

  return (
    <div>
      {state.activeStep === 0 ? (
        <AddAccount id={-1} isEditable={false} />
      ) : state.activeStep === 1 ? (
        <CreateUser id={-1} isEditable={false} />
      ) : state.activeStep === 2 ? (
        <Userprofile id={-1} isEditable={false} />
      ) : state.activeStep === 3 ? (
        <AddFacilities id={-1} isEditable={false} />
      ) : state.activeStep === 4 ? (
        <FacilityProfileMapping id={-1} isEditable={false} />
      ) : state.activeStep === 5 ? (
        <AddNvrDvr id={-1} isEditable={false} usedIn={"onboarding"} />
      ) : state.activeStep === 6 ? (
        <AddCamera id={-1} isEditable={false} usedIn={"onboarding"} />
      ) : (
        <AddDevice isEditable={false} id={-1} />
      )}
    </div>
  );
};

export default FromComponent;
