import { BACKEND_URL } from "../../routes/config";
class OnboardingEndpoints {
  constructor() {
    this.createUserAndVarifyMail = () => {
      return `${BACKEND_URL}/onboarding/user_new/`;
    };
    this.createAccountAndOrganisation = () => {
      return `${BACKEND_URL}/onboarding/account_new/`;
    };
    this.createFacility = () => {
      return `${BACKEND_URL}/onboarding/facility_new/`;
    };
    this.createNvr = () => {
      return `${BACKEND_URL}/operations/nvr/`;
    };
    this.createCameraAuto = (nvr_id) => {
      return `${BACKEND_URL}/operations/bulk_camera/${nvr_id}`;
    };
    this.updateCameraInfo = (cam_id) => {
      return `${BACKEND_URL}/operations/camera/${cam_id}/`;
    };
    this.updateNvrInfo = (nvr_id) => {
      return `${BACKEND_URL}/operations/nvr/${nvr_id}/`;
    };
    this.createStandaloneCameraAndNvr = () => {
      return `${BACKEND_URL}/onboarding/nvr_new/`;
    };
    this.updateAccountInfo = (id) => {
      return `${BACKEND_URL}/operations/accounts/${id}/`;
    };
    this.deleteCamerasOfNvr = (id) => {
      return `${BACKEND_URL}/onboarding/delete_cameras_from_nvr/${id}`;
    };
    this.createDefaultProfile = () => {
      return `${BACKEND_URL}/controlled_access/create_default_profiles`;
    };
    this.userAccessControlById = (id) => {
      if (id) return `${BACKEND_URL}/operations/userprofiles/${id}/`;
      return `${BACKEND_URL}/controlled_access/create_user_and_userprofile`;
    };
    this.enableIntrusionAutomation = () => {
      return `${BACKEND_URL}/usecase_automation/setup_cam`;
    };
    this.createDefaultGroup = () => {
      return `${BACKEND_URL}/controlled_access/group/`;
    };
  }
}
export default new OnboardingEndpoints();
