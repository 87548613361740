// CheckboxAutocomplete.jsx
import React from "react";
import PropTypes from "prop-types";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledListbox = styled("ul")(({ theme }) => ({
  [`&.${autocompleteClasses.listbox}`]: {
    maxHeight: "100px",
    overflow: "auto",
  },
}));

const CheckboxAutocomplete = ({
  value,
  onChange,
  label,
  options,
  heading,
  size,
  helperText,
}) => {
  const isOptionEqualToValue = (option, value) => option.id === value.id;

  return (
    <div className="mb-3">
      {heading && (
        <Typography variant="h6" gutterBottom className="typography-start">
          {heading}
        </Typography>
      )}
      <Autocomplete
        multiple
        value={value}
        fullWidth
        limitTags={5}
        size={size}
        onChange={onChange}
        id="checkboxes-tags-demo"
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.value}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.value}
          </li>
        )}
        style={{ marginTop: 8 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={!heading ? label : ""}
            helperText={helperText} // Pass helperText to TextField
            error={!!helperText}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        ListboxProps={{
          component: StyledListbox,
        }}
        isOptionEqualToValue={isOptionEqualToValue}
      />
    </div>
  );
};

CheckboxAutocomplete.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  heading: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  helperText: PropTypes.string,
};

export default CheckboxAutocomplete;
