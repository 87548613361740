const sizeConfigs = {
  sidebar: {
    width: "280px",
  },
  sidebarMin: {
    width: "90px",
  },
};

export default sizeConfigs;
