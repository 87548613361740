import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/main.css";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./approval.css";
import NoCardToShow from "./NoCardToShow";
import ApprovalCard from "./ApprovalCard";
import OperationModeAction from "./../../redux/Action/OperationModeAction";

const ApprovalMainPage = () => {
  const dispatch = useDispatch();
  const profileId = localStorage.getItem("profile_id");
  const [loadingCard, setLoadingcard] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const state = useSelector((state) => state.Approval);
  const configPageState = useSelector((state) => state.configData);

  useEffect(() => {
    dispatch(changeTopbarTitle("Main Page"));
    dispatch(OperationModeAction.getAllAccount(profileId));
    setLoadingcard(true);
    dispatch(
      OperationModeAction.getAlertEventCard(
        profileId,
        configPageState.time,
        configPageState.date
      )
    )
      .then(() => setLoadingcard(false))
      .catch(() => setLoadingcard(false));
  }, []);

  const handlePrevItemClick = () => {
    dispatch(OperationModeAction.updatecurrentPage(state.currentIndex - 1));
  };

  const handleNextItemClick = () => {
    dispatch(OperationModeAction.updatecurrentPage(state.currentIndex + 1));
  };

  const handleDialogClose = (confirmed) => {
    if (confirmed) {
      setLoadingcard(true);
      dispatch(
        OperationModeAction.getAlertEventCard(
          profileId,
          configPageState.time,
          configPageState.date
        )
      )
        .then(() => setLoadingcard(false))
        .catch(() => setLoadingcard(false));
      dispatch(OperationModeAction.updatecurrentPage(0));
      localStorage.removeItem("approvedItems");
      localStorage.removeItem("rejectedItems");
    }
    setOpenDialog(false);
  };

  return (
    <div className="main_parent_container">
      <center>
        {state.alertEventCard.length > 0 && loadingCard == false ? (
          <ApprovalCard item={state.alertEventCard[state.currentIndex]} />
        ) : (
          <NoCardToShow show={loadingCard} />
        )}
        {state.alertEventCard.length > 0 && (
          <>
            <button
              className="left-button"
              onClick={handlePrevItemClick}
              disabled={state.currentIndex === 0}
            >
              <ArrowBackIosIcon fontSize="15px" />
            </button>
            <button
              className="right-button"
              onClick={
                state.currentIndex === state.alertEventCard.length - 1
                  ? () => setOpenDialog(true)
                  : handleNextItemClick
              }
            >
              {state.currentIndex === state.alertEventCard.length - 1 ? (
                <AutorenewIcon fontSize="15px" />
              ) : (
                <ArrowForwardIosIcon fontSize="20px" />
              )}
            </button>
          </>
        )}
      </center>
      <div>
        <Dialog
          open={openDialog}
          onClose={() => handleDialogClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            sx={{
              backgroundColor: "var(--side-bar-bg-color)",
              color: "var(--card-text-color)",
            }}
          >
            {"Sure to Load new Data ?"}
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "var(--side-bar-bg-color)" }}>
            <DialogContentText sx={{ color: "var(--card-text-color)" }}>
              Once you load new data, you would not be able to make changes to
              previous data. Are you still interested in loading the new data?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: "var(--side-bar-bg-color)",
              color: "var(--card-text-color)",
            }}
          >
            <Button onClick={() => handleDialogClose(false)}>No</Button>
            <Button onClick={() => handleDialogClose(true)} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ApprovalMainPage;
