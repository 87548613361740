import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
function PolygonDrawing() {
  const [perimeter, setPerimeter] = useState([]);
  const [complete, setComplete] = useState(false);
  const [newImage, setNewImage] = useState("");
  const canvasRef = useRef(null);
  const coordinatesTextareaRef = useRef(null);
  const reduxImage = useSelector((state) => state.device);
  useEffect(() => {
    if (reduxImage.newImage != "") {
      setNewImage(reduxImage.newImage);
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = reduxImage.newImage;

    img.onload = function () {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      if (perimeter.length > 0) {
        draw(false);
      }
    };
  }, [reduxImage]);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.src = canvas.getAttribute("data-imgsrc");

    img.onload = function () {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      if (perimeter.length > 0) {
        draw(false);
      }
    };
  }, [perimeter]);

  function line_intersects(p0, p1, p2, p3) {
    var s1_x, s1_y, s2_x, s2_y;
    s1_x = p1["x"] - p0["x"];
    s1_y = p1["y"] - p0["y"];
    s2_x = p3["x"] - p2["x"];
    s2_y = p3["y"] - p2["y"];

    var s, t;
    s =
      (-s1_y * (p0["x"] - p2["x"]) + s1_x * (p0["y"] - p2["y"])) /
      (-s2_x * s1_y + s1_x * s2_y);
    t =
      (s2_x * (p0["y"] - p2["y"]) - s2_y * (p0["x"] - p2["x"])) /
      (-s2_x * s1_y + s1_x * s2_y);

    if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
      // Collision detected
      return true;
    }
    return false; // No collision
  }

  const point = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.fillStyle = "white";
    ctx.strokeStyle = "white";
    ctx.fillRect(x - 2, y - 2, 4, 4);
    ctx.moveTo(x, y);
  };

  const undo = () => {
    setPerimeter([]);
    setComplete(false);
    start(true);
  };

  const clearCanvas = () => {
    setPerimeter([]);
    setComplete(false);
    coordinatesTextareaRef.current.value = "";
    start();
  };

  const draw = (end) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.lineWidth = 1;
    ctx.strokeStyle = "white";
    ctx.lineCap = "square";
    ctx.beginPath();

    for (let i = 0; i < perimeter.length; i++) {
      if (i === 0) {
        ctx.moveTo(perimeter[i].x, perimeter[i].y);
        end || point(perimeter[i].x, perimeter[i].y);
      } else {
        ctx.lineTo(perimeter[i].x, perimeter[i].y);
        end || point(perimeter[i].x, perimeter[i].y);
      }
    }
    if (end) {
      ctx.lineTo(perimeter[0].x, perimeter[0].y);
      ctx.closePath();
      ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
      ctx.fill();
      ctx.strokeStyle = "blue";
      setComplete(true);
    }
    ctx.stroke();

    if (perimeter.length === 0) {
      coordinatesTextareaRef.current.value = "";
    } else {
      coordinatesTextareaRef.current.value = JSON.stringify(perimeter);
    }
  };

  const checkIntersect = (x, y) => {
    if (perimeter.length < 4) {
      return false;
    }
    const p0 = {};
    const p1 = {};
    const p2 = {};
    const p3 = {};

    p2.x = perimeter[perimeter.length - 1].x;
    p2.y = perimeter[perimeter.length - 1].y;
    p3.x = x;
    p3.y = y;

    for (let i = 0; i < perimeter.length - 1; i++) {
      p0.x = perimeter[i].x;
      p0.y = perimeter[i].y;
      p1.x = perimeter[i + 1].x;
      p1.y = perimeter[i + 1].y;
      if (p1.x === p2.x && p1.y === p2.y) {
        continue;
      }
      if (p0.x === p3.x && p0.y === p3.y) {
        continue;
      }
      if (line_intersects(p0, p1, p2, p3) === true) {
        return true;
      }
    }
    return false;
  };

  const point_it = (event) => {
    if (complete) {
      alert("Polygon already created");
      return false;
    }
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (event.ctrlKey || event.which === 3 || event.button === 2) {
      if (perimeter.length === 2) {
        alert("You need at least three points for a polygon");
        return false;
      }
      const firstPoint = perimeter[0];
      if (checkIntersect(firstPoint.x, firstPoint.y)) {
        alert("The line you are drawing intersects another line");
        return false;
      }
      draw(true);
      alert("Polygon closed");
      event.preventDefault();
      return false;
    } else {
      if (
        perimeter.length > 0 &&
        x === perimeter[perimeter.length - 1].x &&
        y === perimeter[perimeter.length - 1].y
      ) {
        // same point - double click
        return false;
      }
      if (checkIntersect(x, y)) {
        alert("The line you are drawing intersects another line");
        return false;
      }
      setPerimeter([...perimeter, { x, y }]);
      draw(false);
      return false;
    }
  };

  const start = (withDraw) => {
    const canvas = canvasRef.current;
    const img = new Image();
    img.src = canvas.getAttribute("data-imgsrc");

    img.onload = function () {
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      if (withDraw === true) {
        draw(false);
      }
    };
  };

  const copyToClipboard = () => {
    if (coordinatesTextareaRef.current) {
      const textToCopy = coordinatesTextareaRef.current.value;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          notifiToasterSuccess("Text copied to clipboard");
        })
        .catch((error) => {
          notifiToasterWarn("Error copying text to clipboard");
        });
    }
  };
  return (
    <div style={{ minHeight: "100vh" }} className="mt-3">
      <div style={{ float: "left" }}>
        <canvas
          ref={canvasRef}
          width="960"
          height="640"
          style={{ cursor: "crosshair" }}
          data-imgsrc={newImage}
          onMouseDown={point_it}
          onContextMenu={(e) => e.preventDefault()}
        >
          Your browser does not support the HTML5 canvas tag.
        </canvas>
      </div>
      <div style={{ float: "left", marginLeft: "20px" }}>
        <div>
          <Button
            variant="contained"
            onClick={undo}
            style={{ marginRight: "20px" }}
          >
            Undo
          </Button>
          <Button variant="contained" onClick={clearCanvas}>
            Clear
          </Button>

          <p>
            Press <strong>Left Click</strong> to draw a point.
          </p>
          <p>
            <strong>CTRL+Click</strong> or <strong>Right Click</strong> to close
            the polygon.
          </p>
        </div>
        <div>
          <p>
            <strong>Coordinates:</strong>
          </p>
          <textarea
            id="coordinates"
            ref={coordinatesTextareaRef}
            disabled="disabled"
            style={{ width: "300px", height: "200px" }}
          ></textarea>
        </div>
        <button onClick={copyToClipboard}>Copy Coordinates</button>
      </div>
    </div>
  );
}

export default PolygonDrawing;
