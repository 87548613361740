import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
const AutocompleteCompUncontrollable = ({
  option,
  onChange,
  label,
  disableClearable,
  showValue,
}) => {
  const isOptionEqualToValue = (option, value) => {
    return option.value === value?.value;
  };
  function handleChange(event, value) {
    onChange(value);
  }

  return (
    <div>
      <Autocomplete
        disablePortal
        disableClearable={disableClearable}
        id={`combo-box-demo-${label}`}
        options={option}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) =>
          showValue ? `${option.label} (${option.value}) ` : option.label
        }
        getOptionDisabled={(item) =>
          item.value === "compliance_issue" ||
          item.value === "security_thread" ||
          item.value === "facility_status" ||
          item.value === "loading_status" ||
          item.value === "vehicle_alert"
        }
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </div>
  );
};
AutocompleteCompUncontrollable.propTypes = {
  option: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disableClearable: PropTypes.bool.isRequired,
  showValue: PropTypes.bool.isRequired,
};
export default AutocompleteCompUncontrollable;
