import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import "./Onboarding.css";
import { useNavigate } from "react-router-dom";
import CustomStepper from "./Utils/CustomStepper";
import StepContent from "./Utils/StepContent";
import { useDispatch, useSelector } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import ClientOnboardingActions from "../../redux/Action/ClientOnboarding";
import { useEffect } from "react";
const steps = [
  "Sign Up",
  "Create Organisation",
  "Create Facility",
  "Add NVR/DVR",
  "Add Cameras",
  "Select Services",
];

export default function OnboardingMain() {
  const activeStep = useSelector((state) => state.clientOnboard.currentStep);
  const dispatch = useDispatch();
  const [isEditable] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(changeTopbarTitle("Client Onboarding"));
  }, []);
  const handleNext = async () => {
    if (activeStep === steps.length) {
      setTimeout(async () => {
        // Add async here to handle the promise within setTimeout
        try {
          const onboardAccount = JSON.parse(
            localStorage.getItem("onboardAccount") || "null"
          );
          const account_id = onboardAccount?.account_id;
          await ClientOnboardingActions.createDefaultProfiles({ account_id });
          await ClientOnboardingActions.createDefaultGroup();
          localStorage.removeItem("onboardFacility");
          localStorage.removeItem("onboardAccount");
          localStorage.removeItem("onboardUser");
          localStorage.removeItem("currentStep");
          localStorage.removeItem("onboardCameras");
          localStorage.removeItem("completedStep");
          localStorage.removeItem("onboardNvr");
          localStorage.removeItem("standaloneCamera");
          localStorage.removeItem("profileImage");
          localStorage.removeItem("isInOn");
          localStorage.removeItem("isCrowdOn");
          dispatch({ type: "updateCurrentStep", payload: 0 });
          navigate("/client/onboard");
        } catch (error) {
          console.error("Failed to create default profiles:", error);
          // Handle errors, e.g., display an error message
        }
      }, 1000);
    } else {
      dispatch({ type: "updateCurrentStep", payload: activeStep + 1 });
    }
  };

  const handleBack = () => {
    dispatch({ type: "updateCurrentStep", payload: activeStep - 1 });
  };

  return (
    <div className="client-onboarding-main-div">
      <h2 className="Onboard-text" style={{}} id="onboard_text">
        Client Onboard
      </h2>
      <Stack
        sx={{
          width: "100%",
          marginTop: "30px",
        }}
        spacing={4}
      >
        <CustomStepper activeStep={activeStep} steps={steps} />
        <center>
          <div className="onboarding-form-card">
            <StepContent activeStep={activeStep} isEditable={isEditable} />
          </div>
        </center>
        <Stack direction="row" className="action-button-stepper">
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className="action-back-button"
          >
            Back
          </Button>
          {activeStep > 0 &&
            activeStep <=
              JSON.parse(localStorage.getItem("completedStep") || "0") && (
              <Button variant="contained" onClick={handleNext}>
                {activeStep === 6 ? "Finish" : "Next"}
              </Button>
            )}

          {/* {
            <Button variant="contained" onClick={handleNext}>
              {activeStep === 6 ? "Finish" : "Next"}
            </Button>
          } */}
        </Stack>
      </Stack>
    </div>
  );
}
