import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import OperationModeAction from "../../redux/Action/OperationModeAction";
import "./config.css";
import configPageAction from "../../redux/Action/configPageAction";
import MultiSelectAutoAutoComplete from "../../components/formLayout/MultiSelectAutoAutoComplete";
const Configuration = () => {
  const dispatch = useDispatch();
  const approvalState = useSelector((state) => state.Approval);
  const configDataState = useSelector((state) => state.configData);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const profile_id = localStorage.getItem("profile_id");
  const [selectedMessages, setSelectedMessages] = useState(
    configDataState.enteredMessages
  );
  function formatdate(currentDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  // Define state for date and time range inputs
  const [selectedDate, setSelectedDate] = useState(formatdate(new Date()));
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");

  useEffect(() => {
    dispatch(OperationModeAction.getAllAccount(profile_id));
    dispatch(changeTopbarTitle("Configuration"));
    dispatch(configPageAction.getSelectedClients(profile_id));
    dispatch(configPageAction.getAllStoredMessage(profile_id));
    dispatch(
      configPageAction.updateSelectedTimeData(
        { startTime: startTime, endTime: endTime },
        selectedDate
      )
    );
  }, []);
  useEffect(() => {
    dispatch(
      configPageAction.updateSelectedTimeData(
        { startTime: startTime, endTime: endTime },
        selectedDate
      )
    );
  }, [endTime, startTime, selectedDate]);
  useEffect(() => {
    if (
      configDataState.selectedAccount &&
      configDataState.selectedAccount.length > 0
    ) {
      const filteredAccounts = approvalState.accountList.filter((item) =>
        configDataState.selectedAccount.includes(item.value)
      );
      setSelectedAccounts(filteredAccounts);
    }
  }, [approvalState.accountList, configDataState.selectedAccount]);

  function handleAccountChange(newValue) {
    setSelectedAccounts(newValue);
    const newValueValues = newValue.map((item) => item.value);
    dispatch(configPageAction.updateSelectedAccount(newValueValues));
    if (newValueValues.length > 0) {
      dispatch(
        configPageAction.updateSelectedClients(profile_id, newValueValues)
      );
    } else {
      dispatch(configPageAction.updateSelectedClients(profile_id, -1));
    }
  }

  const handleMessageChange = (event, newValue) => {
    setSelectedMessages(newValue);
    dispatch(configPageAction.updateSelectedMessage(newValue));

    const removedItem = selectedMessages.filter(
      (item) => !newValue.includes(item)
    );

    if (removedItem.length > 0) {
      if (removedItem) {
        console.log("Removed Item:", removedItem);
        dispatch(
          configPageAction.deleteStoredMessages(
            configDataState.msgDict[removedItem[0]]
          )
        );
      }
    } else {
      dispatch(
        configPageAction.updateStoredMessages(
          profile_id,
          newValue[newValue.length - 1]
        )
      ).then(() => {
        dispatch(configPageAction.getAllStoredMessage(profile_id));
      });
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    setSelectedMessages(configDataState.enteredMessages);
  }, [configDataState.enteredMessages]);

  const getOptionDisabled = (item) => {
    if (
      configDataState.takenAccount &&
      configDataState.takenAccount.length > 0
    ) {
      return configDataState.takenAccount.includes(item.value);
    }
    return false;
  };

  return (
    <div>
      <Box className="config-page-container">
        <div className="col-12 col-lg-6">
          <div className="select-account-field">
            <MultiSelectAutoAutoComplete
              option={approvalState.accountList}
              value={selectedAccounts}
              onChange={handleAccountChange}
              label="Account"
              getOptionDisabled={getOptionDisabled}
            />
          </div>

          <div className="message-select-filed">
            <Autocomplete
              multiple
              disableClearable
              id="tags-filled"
              options={configDataState.enteredMessages}
              value={selectedMessages}
              onChange={handleMessageChange}
              freeSolo
              renderTags={(value, getTagProps) => (
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="standard"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))}
                </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Messages"
                  placeholder="Enter New Message"
                  InputLabelProps={{
                    style: {
                      fontSize: "20px",
                      color: "black",
                      fontWeight: "bold",
                    },
                  }}
                />
              )}
            />
          </div>

          {/* Add date and time range input fields */}
          <div className="date-input-field mt-3 row">
            <TextField
              type="date"
              variant="outlined"
              label="Date"
              placeholder=""
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
          <div className="row mt-3">
            <div className="time-range-input-field col-6">
              <TextField
                fullWidth
                variant="outlined"
                type="time"
                label="Start Time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </div>
            <div className="time-range-input-field col-6">
              <TextField
                variant="outlined"
                fullWidth
                type="time"
                label="End Time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Configuration;
