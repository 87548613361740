import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import { styled, alpha } from "@mui/material/styles";
import { Chip } from "@mui/material";
import EmailSettingModal from "./EmailSettingModal";
import AddDeviceForm from "./AddDeviceToFleet";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;

function LongMenu({ id, data, isExternalVendor }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openEmailModal, setEmailModal] = useState(false);
  const [openEditDevice, setEditDevice] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleOpenEmailModal = () => {
    setEmailModal(true);
  };
  function openEditDeviceModal() {
    setEditDevice(true);
  }
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openMenu ? "long-menu" : undefined}
        aria-expanded={openMenu ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {isExternalVendor == -1 && (
          <MenuItem onClick={openEditDeviceModal}>Edit Device</MenuItem>
        )}
        <MenuItem onClick={handleOpenEmailModal}>Email Setting</MenuItem>
      </Menu>
      {openEditDevice && (
        <AddDeviceForm
          isEditable={true}
          handleModal={setEditDevice}
          deviceId={id}
        />
      )}
      {openEmailModal && (
        <EmailSettingModal
          handleChange={() => setEmailModal(false)}
          id={id}
          data={data}
        />
      )}
    </div>
  );
}
LongMenu.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  isExternalVendor: PropTypes.object.isRequired,
};
export default function FleetDeviceTable({ deviceList, isExternalVendor }) {
  const data = deviceList;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F9FF",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      fontWeight: "bold",
    },
  }));
  const changeDateFormat = (dateStr, hoursToAdd, minutesToAdd) => {
    const date = new Date(dateStr);

    // Check if date is valid
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    date.setHours(date.getHours() + hoursToAdd);
    date.setMinutes(date.getMinutes() + minutesToAdd);
    return date;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead
              className="table-header"
              sx={{ width: "100%", backgroundColor: "#2196F3" }}
            >
              <TableRow sx={{ width: "100%", backgroundColor: "#2196F3" }}>
                <StyledTableCell>SN</StyledTableCell>
                <StyledTableCell align="left">Device Name</StyledTableCell>
                <StyledTableCell align="left">Facility</StyledTableCell>
                <StyledTableCell align="left">Channel</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Unit ID</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            {data && data.length > 0 && (
              <>
                <TableBody>
                  {(rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row, index) => (
                    <TableRow key={row.id + "-" + index}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.device_name}
                        <br />
                        {row.fleet_name ? `(${row.fleet_name})` : ""}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.facility_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.channel_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          label={row.status ? "Online" : "Offline"}
                          color={row.status ? "success" : "error"}
                        />
                        <br />
                        {!row.status ? "Last Online : " : ""}
                        {!row.status
                          ? changeDateFormat(
                              row.lastonlinestatus,
                              5,
                              30
                            ).toLocaleString()
                          : null}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.remoteiot_unit_id}
                      </TableCell>
                      <TableCell align="right">
                        <LongMenu
                          id={row.id}
                          data={{
                            accountId: row.account_id,
                            deviceId: row.id,
                            facilityId: row.facility_installed_at,
                          }}
                          isExternalVendor={isExternalVendor}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[25, { label: "All", value: -1 }]}
                      colSpan={7}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </>
            )}
          </Table>
        </>
      </TableContainer>
    </>
  );
}
FleetDeviceTable.propTypes = {
  deviceList: PropTypes.array.isRequired,
  isExternalVendor: PropTypes.array.isRequired,
};
