import * as React from "react";
import PropTypes from "prop-types"; // Step 1: Import PropTypes
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ColorlibStepIcon from "./ColorlibStepIcon";
import { ColorlibConnector } from "./ColorlibStepIcon";

const CustomStepper = ({ activeStep, steps }) => (
  <Stepper
    alternativeLabel
    activeStep={activeStep}
    connector={<ColorlibConnector />}
    sx={{ overflow: "auto" }}
  >
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

// Step 2: Define PropTypes
CustomStepper.propTypes = {
  activeStep: PropTypes.number.isRequired, // Define activeStep as a required number
  steps: PropTypes.arrayOf(PropTypes.string).isRequired, // Define steps as an array of strings and required
};

export default CustomStepper;
