import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import axios from "axios";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import DeviceDetailsTable from "./DeviceDetailTable";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import ApiSpec from "./../../../redux/features/EndPoints";
import { Bars } from "react-loader-spinner";
const DeviceDetails = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [facilityList, SetFacilityList] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  async function getFacilityList() {
    try {
      var token = localStorage.getItem("token");
      const response1 = await axios.get(
        OperationDashEndpoints.getAllFacilities(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const facilityData = [
        { label: "All", value: 0 },
        ...response1.data.map((item) => ({
          label: item.facility_name,
          value: item.id,
        })),
      ];

      SetFacilityList(facilityData);
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error;
    }
  }
  async function getAccountList() {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };
      const profile_id = localStorage.getItem("profile_id");
      const response = await axios.get(ApiSpec.getAllAccount(profile_id), {
        headers,
      });

      const accList = [
        { label: "All", value: 0 },
        ...response.data.map((element) => ({
          label: element.name,
          value: element.account_id,
        })),
      ];

      setAccountList(accList);
    } catch (error) {
      console.error("Error fetching account data:", error);
      setAccountList([]); // You can set an empty array for the list in case of an error.
      throw error;
    }
  }

  async function getUserList() {
    try {
      var token = localStorage.getItem("token");

      if (searchItem.length == 0 && !selectedAccount && !selectedFacility) {
        setLoading(true);
        const response = await axios.get(
          OperationDashEndpoints.getDeviceList(),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setLoading(false);
        setUserList(response.data);
      } else if (searchItem.length > 2) {
        setLoading(true);
        const response = await axios.get(
          OperationDashEndpoints.seachDeviceFromAll(searchItem),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setLoading(false);
        setUserList(response.data);
      } else if (selectedAccount && selectedAccount.value) {
        setLoading(true);
        const response = await axios.get(
          OperationDashEndpoints.getDeviceByAccount(selectedAccount.value),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setLoading(false);
        setUserList(response.data.data);
      } else if (selectedFacility && selectedFacility.value) {
        setLoading(true);
        const response = await axios.get(
          OperationDashEndpoints.getDeviceByFacility(selectedFacility.value),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setLoading(false);
        setUserList(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      setUserList([]);
      console.error("Error fetching user data:", error);
      throw error;
    }
  }
  useEffect(() => {
    getUserList();
    getFacilityList();
    getAccountList();
    dispatch(changeTopbarTitle("Admin/Devices"));
  }, []);
  useEffect(() => {
    getUserList();
  }, [searchItem, selectedFacility, selectedAccount]);
  function handleFacilityChange(val) {
    if (val.value) setSelectedFacility(val);
    else setSelectedFacility(null);
  }
  function handleAccountChange(val) {
    if (val.value) setSelectedAccount(val);
    else setSelectedAccount(null);
  }
  return (
    <div className="config-page-container">
      <div className="row">
        <div className="d-flex mb-3 col-12 col-md-6 col-lg-4">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search Device Name"
            aria-label="Search"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target.value)}
          />
        </div>
        <div className=" mb-3 col-12 col-md-6 col-lg-4">
          <AutocompleteCompUncontrollable
            option={accountList}
            onChange={handleAccountChange}
            label="Account"
            disableClearable={true}
            showValue={true}
          />
        </div>
        <div className=" mb-3 col-12 col-md-6 col-lg-4">
          <AutocompleteCompUncontrollable
            option={facilityList}
            onChange={handleFacilityChange}
            label="Facility"
            disableClearable={true}
            showValue={true}
          />
        </div>
      </div>
      {!loading ? (
        <DeviceDetailsTable data={userList} getUserList={getUserList} />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </div>
  );
};

export default DeviceDetails;
