const initialState = {
  data: [],
  forgetPassdata: { token: "" },
  passChanged: false,
  isAuthticated: false,
  isLoading: false,
  firstPage: null,
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_DATA_SUCCESS":
      return { ...state, data: action.payload, isAuthticated: true };

    case "LOGOUT_USER":
      return { ...state, data: action.payload, isAuthticated: false };

    case "FORGET_PASS_DATA_SUCCESS":
      return { ...state, forgetPassdata: action.payload };

    case "UPDATE_PROFILE_DATA":
      return { ...state, firstPage: "/" + action.payload };

    case "FORGET_PASS_SUCCESS_FLAG":
      return { ...state, passChanged: true };

    default:
      return state;
  }
}
