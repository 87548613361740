import React, { useState, useEffect } from "react";
import "./fleetDash.css";
import FleetDeviceTable from "./FleetDeviceTable";
import AddDeviceFromFleet from "./AddDeviceToFleet";
import AutocompleteCompUncontrollable from "../../components/common/AutoCompleteUncontrollable";
import getDataFromAPI from "../Onboarding/Utils/getDataFromAPI";
import { TextField, CircularProgress, Button } from "@mui/material";
import FleetDashboardAPI from "./fleetDashAPIs";
import SettingsModal from "./SettingsModal";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";

const FleetDashMainPage = () => {
  const dispatch = useDispatch();
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [deviceList, setDeviceList] = useState([]);
  const [filteredDeviceList, setFilteredDeviceList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channelList, setChannelList] = useState([
    { label: "Agrex", value: 1 },
    { label: "VCTech", value: 2 },
    { label: "Spectra", value: 3 },
  ]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const accoundId = JSON.parse(localStorage.getItem("profile")).account
    .account_id;
  const isExternalVendor = accoundId == 113 ? 3 : accoundId == 25 ? 2 : -1;

  useEffect(() => {
    dispatch(changeTopbarTitle("Fleet Dashboard"));
  }, []);

  const handleSearchName = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchDeviceData();
  };

  const handleFacilityChange = (val) => {
    setSelectedFacility(val);
  };

  const handleChannelChange = (val) => {
    setSelectedChannel(val);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSelectedAccount = async (val) => {
    if (!val) {
      fetchFacilityData();
      setSelectedFacility(null);
    } else {
      const facilityData = await getDataFromAPI.getFacilityByAcc(val.value);
      setFacilityList(facilityData);
    }
    setSelectedAccount(val);
  };

  const fetchFacilityData = async () => {
    try {
      if (isExternalVendor != -1) return;
      const facilityData = await getDataFromAPI.getAllFacility();
      setFacilityList(facilityData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccountData = async () => {
    try {
      if (isExternalVendor == -1) {
        const accData = await getDataFromAPI.getAllAccount();
        setAccountList(accData);
      } else {
        const accList = await FleetDashboardAPI.getAllAccountViaChannel(
          isExternalVendor
        );
        setAccountList(accList);
      }
    } catch (error) {
      setAccountList([]);
      console.log(error);
    }
  };

  const fetchDeviceData = async () => {
    setLoading(true);
    try {
      let facilityData = [];
      if (search.length >= 2) {
        facilityData = await FleetDashboardAPI.getFleetViaSeach(search);
      } else if (selectedAccount) {
        facilityData = await FleetDashboardAPI.getFleetDevicesViaAccount(
          selectedAccount.value
        );
      } else if (selectedFacility) {
        facilityData = await FleetDashboardAPI.getFleetDevicesViaFacility(
          selectedFacility.value
        );
      } else if (selectedChannel) {
        facilityData = await FleetDashboardAPI.getFleetDevicesViaChannel(
          selectedChannel.value
        );
      } else {
        facilityData =
          isExternalVendor == -1
            ? await FleetDashboardAPI.getFleetDevices()
            : await FleetDashboardAPI.getFleetDevicesViaChannel(
                isExternalVendor
              );
      }

      if (isExternalVendor != -1) {
        facilityData = facilityData.filter(
          (item) => item.channel === isExternalVendor
        );
      }

      setDeviceList(facilityData);
      filterDevices(facilityData);
    } catch (error) {
      setDeviceList([]);
      setFilteredDeviceList([]);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const filterDevices = (devices) => {
    let filtered = devices;
    if (statusFilter === "online") {
      filtered = devices.filter((device) => device.status === true);
    } else if (statusFilter === "offline") {
      filtered = devices.filter((device) => device.status === false);
    }
    setFilteredDeviceList(filtered);
  };

  useEffect(() => {
    fetchFacilityData();
    fetchAccountData();
  }, []);

  useEffect(() => {
    fetchDeviceData();
  }, [selectedAccount, selectedFacility, selectedChannel]);

  useEffect(() => {
    filterDevices(deviceList);
  }, [statusFilter, deviceList]);

  return (
    <div className="config-page-container">
      <div className="row">
        <div className="col-12 col-md-3 mb-3">
          <AutocompleteCompUncontrollable
            disableClearable={false}
            showValue={true}
            option={accountList}
            onChange={handleSelectedAccount}
            label="Select Account"
            disabled={loading}
          />
        </div>
        {selectedAccount && (
          <div className="col-12 col-md-3 mb-3">
            <AutocompleteCompUncontrollable
              disableClearable={false}
              showValue={true}
              option={facilityList}
              onChange={handleFacilityChange}
              label="Select Facility"
              disabled={loading}
            />
          </div>
        )}
        {isExternalVendor == -1 && (
          <div className="col-12 col-md-3 mb-3">
            <AutocompleteCompUncontrollable
              disableClearable={false}
              showValue={true}
              option={channelList}
              onChange={handleChannelChange}
              label="Select Channel"
              disabled={loading}
            />
          </div>
        )}
        <div className="form-group col-12 col-md-3">
          <form onSubmit={handleSearchSubmit}>
            <TextField
              id="search"
              label="Search Device"
              variant="outlined"
              fullWidth
              type="text"
              name="Search"
              value={search}
              onChange={handleSearchName}
              disabled={loading}
            />
            <Button type="submit" style={{ display: "none" }}>
              Search
            </Button>
          </form>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <select
            className="form-control"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            disabled={loading}
          >
            <option value="all">All Devices</option>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
          </select>
        </div>
        <div
          className={`col-12 ${
            isExternalVendor != -1 ? "col-md" : ""
          } mt-2 mb-2 d-flex justify-content-end`}
        >
          <div>
            <SettingsModal />
          </div>
          {isExternalVendor == -1 && (
            <AddDeviceFromFleet
              isEditable={false}
              handleModal={() => {
                console.log("");
              }}
              deviceId={null}
            />
          )}
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <FleetDeviceTable
          deviceList={filteredDeviceList}
          isExternalVendor={isExternalVendor}
        />
      )}
    </div>
  );
};

export default FleetDashMainPage;
