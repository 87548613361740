import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from "@mui/material";
import OnboardNVR from "./OnboardNVR";
import OnboardStandAloneCameras from "./OnboardStandAloneCameras";
const OnboardNVROrStandalone = () => {
  const [selectedOption, setSelectedOption] = useState("nvrDvr");
  const [isEditable, setIsEditable] = useState(false);
  const handleRadioChange = (event) => {
    if (!isEditable) {
      setSelectedOption(event.target.value);
    }
  };
  useEffect(() => {
    let nvrData = JSON.parse(localStorage.getItem("onboardNvr"));
    if (nvrData) {
      if (nvrData.type == "nvr") {
        setSelectedOption("nvrDvr");
      } else {
        setSelectedOption("standalone");
      }
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, []);
  return (
    <>
      <Typography variant="h6" gutterBottom>
        What does your facility have? NVR/DVR or standalone cameras (IP Cameras)
      </Typography>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="facility-type"
          name="facilityType"
          value={selectedOption}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="nvrDvr"
            control={<Radio color="primary" />}
            label="NVR/DVR"
          />
          <FormControlLabel
            value="standalone"
            control={<Radio color="primary" />}
            label="Standalone Cameras"
          />
        </RadioGroup>
      </FormControl>
      {selectedOption == "nvrDvr" ? (
        <OnboardNVR isEditable={isEditable} />
      ) : (
        <OnboardStandAloneCameras isEditable={isEditable} />
      )}
    </>
  );
};

export default OnboardNVROrStandalone;
