import React from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PageNotFound = () => {
  const PageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: var(--side-bar-bg-color);
  `;

  const Content = styled.div`
    text-align: center;
  `;

  const Title = styled.h1`
    font-size: 7rem;
    font-weight: bold;
    color: var(--card-text-color);
    margin-bottom: 0.5rem;
    animation: ${fadeIn} 1s;
  `;

  const Subtitle = styled.h2`
    font-size: 2rem;
    color: #666;
    margin-bottom: 1.5rem;
    animation: ${fadeIn} 1.5s;
  `;

  const Message = styled.p`
    font-size: 1.2rem;
    color: #888;
    animation: ${fadeIn} 2s;
  `;

  return (
    <PageContainer>
      <Content>
        <Title>404</Title>
        <Subtitle>Page Not Found</Subtitle>
        <Message>
          The requested page could not be found. Please select a page from
          sidebar
        </Message>
      </Content>
    </PageContainer>
  );
};

export default PageNotFound;
