import axios from "axios";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import OperationDashEndpoint from "../features/OperationDashEndpoints";

class deviceRelatedAction {
  static updateSelectedCamera(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_SELECTED_CAMERA_CONFIG", payload: data });
    };
  }
  static setImageForPoints() {
    return async (dispatch) => {
      dispatch({ type: "SET_IMAGE_FILE_FOR_POINTS", payload: [] });
    };
  }
  static setIsCameraEzviz(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_IS_CAMERA_EZVIZ", payload: data });
    };
  }
  static updateConfigList(data) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_CONFIG_LIST_FOR_DEVICE", payload: data });
    };
  }
  static setNewConfigList(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_CONFIG_LIST_FOR_DEVICE", payload: data });
    };
  }
  static updateSelectedNVR(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_SELECTED_NVR_CONFIG", payload: data });
    };
  }

  static StartDeviceIntsallation(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .post(OperationDashEndpoint.installDependencyToDevice(data), null, {
          headers,
        })
        .then((response) => {
          notifiToasterSuccess("Installation Started");
        })
        .catch((err) => {
          notifiToasterWarn("Something went wrong, Can't start Installation");
        });
    };
  }
  static async getImageFromDB(data) {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };

    try {
      const response = await axios.post(
        OperationDashEndpoint.getSavedImageFromDB(data),
        null,
        {
          headers,
        }
      );
      notifiToasterSuccess("Image retrieved");
      return response.data.message.image_path; // Return the image URL
    } catch (error) {
      notifiToasterWarn("Something went wrong, Can't fetch Image");
      return ""; // Return an empty string in case of an error
    }
  }

  static getImageViaNVR(data) {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };
        dispatch({ type: "SET_LOADING_FOR_POINTS", payload: true });
        const response = await axios.post(
          OperationDashEndpoint.getImageViaNvr(data),
          null,
          {
            headers,
          }
        );

        // Delay the getImageFromDB call by 10 seconds
        setTimeout(async () => {
          const image = await deviceRelatedAction.getImageFromDB(data);

          dispatch({
            type: "SET_IMAGE_FILE_FOR_POINTS",
            payload: image,
          });
          dispatch({ type: "SET_LOADING_FOR_POINTS", payload: false });
        }, 10000); // 10 seconds delay
      } catch (err) {
        // Delay the getImageFromDB call by 10 seconds
        setTimeout(async () => {
          const image = await deviceRelatedAction.getImageFromDB(data);

          dispatch({
            type: "SET_IMAGE_FILE_FOR_POINTS",
            payload: image,
          });
          dispatch({ type: "SET_LOADING_FOR_POINTS", payload: false });
        }, 10000); // 10 seconds delay

        // notifiToasterWarn("Something went wrong, Can't fetch Image");
      }
    };
  }

  static getImageViaCamera(data) {
    return async (dispatch) => {
      try {
        var token = localStorage.getItem("token");
        let headers = {
          Authorization: `Token ${token}`,
        };
        dispatch({ type: "SET_LOADING_FOR_POINTS", payload: true });
        const res = await axios.post(
          OperationDashEndpoint.getImageViaEzvizCamera(data),
          null,
          {
            headers,
          }
        );
        const image = await deviceRelatedAction.getImageFromDB(data);
        dispatch({
          type: "SET_IMAGE_FILE_FOR_POINTS",
          payload: image,
        });
        notifiToasterSuccess("Fetching  Image");
        dispatch({ type: "SET_LOADING_FOR_POINTS", payload: false });
      } catch (err) {
        const image = await deviceRelatedAction.getImageFromDB(data);
        dispatch({
          type: "SET_IMAGE_FILE_FOR_POINTS",
          payload: image,
        });
        dispatch({ type: "SET_LOADING_FOR_POINTS", payload: false });
        notifiToasterWarn("Something went wrong, Can't fetch Image");
      }
    };
  }
  static sendDataToUpdateConfig(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };
      await axios
        .post(OperationDashEndpoint.updateDataInConfig(), data, {
          headers,
        })
        .then((response) => {
          notifiToasterSuccess("Data Sent Successfully");
        })
        .catch((err) => {
          notifiToasterWarn("Something went wrong, Can't save data");
        });
    };
  }
}

export default deviceRelatedAction;
