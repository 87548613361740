import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProfileDetail,
  getProfile,
} from "../../redux/Action/ProfileAction";
// import { toggleNotificationSnackBar } from "../../redux/Action/CommonAction";
import { getNewUserDetails } from "../../redux/Action/Login";
const ProfileDetails = () => {
  const dispatch = useDispatch();
  // var profile = JSON.parse(localStorage.getItem("profile"));
  const profile = useSelector((state) => state.profile.profileData);
  const [email_address, setEmailAddress] = useState(profile.email_address[0]);
  const [phone_numbers, setPhoneNumber] = useState(profile.phone_numbers[0]);
  const [location, setLocation] = useState(profile.location);
  const [whatsapp_enabled, setWhatappEnable] = useState(
    profile.whatsapp_enabled
  );
  const [offline_alerts, setOfflineAlerts] = useState(profile.offline_alerts);
  const [showMobileError, setShowMobileError] = useState(false);

  useEffect(() => {
    setEmailAddress(profile.email_address[0]);
    setPhoneNumber(profile.phone_numbers[0]);
    setLocation(profile.location);
    setWhatappEnable(profile.whatsapp_enabled);
    setOfflineAlerts(profile.offline_alerts);
  }, [profile]);
  const handleSubmit = (event) => {
    event.preventDefault();
    var validation = true;
    const Mobilereg = /^\+91[0-9]{10}$/;
    if (phone_numbers && !Mobilereg.test(phone_numbers)) {
      validation = false;
      setShowMobileError(true);
      return false;
    } else {
      setShowMobileError(false);
    }
    if (validation) {
      const formData = {
        email_address: email_address ? [email_address] : [""],
        phone_numbers: phone_numbers ? [phone_numbers] : [""],
        location: location,
        whatsapp_enabled: whatsapp_enabled,
        offline_alerts: offline_alerts,
      };
      dispatch(updateProfileDetail({ formData }));
      getNewUserDetails();
      dispatch(getProfile());
      // dispatch(toggleNotificationSnackBar("Profile Updated"));
    } else {
      return false;
    }
  };

  const handleEmailChange = (event, index) => {
    setEmailAddress(event.target.value);
  };

  const handlePhonChange = (event, index) => {
    setPhoneNumber(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleWhatsappChange = (event) => {
    setWhatappEnable(event.target.checked);
  };
  const handleOfflineChange = (event) => {
    setOfflineAlerts(event.target.checked);
  };
  return (
    <div>
      <form className="profile-detail-form" onSubmit={handleSubmit}>
        {/* Email Address */}
        <div className="form-group">
          <div className="row">
            <div style={{ padding: "10px" }} className="col-md-6">
              <label className="form-label" htmlFor="email">
                Email address:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email_address"
                placeholder="Enter Email address"
                value={email_address}
                aria-describedby="emailHelp"
                onChange={(event) => handleEmailChange(event, 0)}
                style={{
                  backgroundColor: "var(--sidebar-bg-color)",
                  color: "var(--card-text-color)",
                }}
              />
            </div>
          </div>
        </div>

        {/* Phone Number */}
        <div className="form-group">
          <div className="row">
            <div style={{ padding: "10px" }} className="col-md-6">
              <label className="form-label" htmlFor="phone">
                Phone Number:
              </label>
              <input
                type="phon"
                className="form-control"
                id="phone"
                name="phone_number"
                placeholder="Enter Phone as +91XXXXXXXXXX"
                value={phone_numbers}
                aria-describedby="emailHelp"
                onChange={(event) => handlePhonChange(event, 0)}
                style={{
                  backgroundColor: "var(--sidebar-bg-color)",
                  color: "var(--card-text-color)",
                }}
              />
            </div>
            {showMobileError ? (
              <div className="row">
                <div
                  className="phone-validation-error col-md-6"
                  style={{ lineHeight: "20px" }}
                >
                  * Mobile number must be in the form of +91XXXXXXXXXX
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {/* Location */}
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <label className="form-label" htmlFor="location">
                Location:
              </label>
              <input
                type="text"
                className="form-control"
                id="location"
                name="location"
                placeholder="Enter location"
                value={location}
                aria-describedby="emailHelp"
                onChange={handleLocationChange}
                style={{
                  backgroundColor: "var(--sidebar-bg-color)",
                  color: "var(--card-text-color)",
                }}
              />
            </div>
          </div>
        </div>

        <br />
        {/* Enable WhatsApp Notification */}
        <div className="row">
          <div className="col-12 col-lg-6 d-flex mb-3">
            <input
              type="checkbox"
              className="form-check-input col-1"
              id="whatsapp_enabled"
              name="whatsapp_enabled"
              checked={whatsapp_enabled}
              onChange={handleWhatsappChange}
            />
            <label
              className="form-check-label mx-2 col"
              htmlFor="whatsapp_enabled"
            >
              <span>Enable WhatsApp Notification</span>
            </label>
          </div>
        </div>

        {/* Enable Device Online/Offline Notification */}
        <div className="row">
          <div className="col-12 col-lg-6 d-flex mb-3">
            <input
              type="checkbox"
              className="form-check-input col-1"
              id="offline_alerts"
              name="offline_alerts"
              checked={offline_alerts}
              onChange={handleOfflineChange}
            />
            <label
              className="form-check-label col mx-2"
              htmlFor="offline_alerts"
            >
              <span>Enable Device Online/Offline Notification</span>
            </label>
          </div>
        </div>

        <br />

        <button className="profile-submit-btn" type="submit">
          <span>Save</span>
        </button>
      </form>
    </div>
  );
};

export default ProfileDetails;
