import React, { useState, useEffect } from "react";
import Textfield from "../../../components/formLayout/TextField";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import MultiSelectAutoAutoComplete from "../../../components/formLayout/MultiSelectAutoAutoComplete";
import Button from "@mui/material/Button";
import getDataFromAPI from "../Utils/getDataFromAPI";
import { notifiToasterWarn } from "../../../components/common/UseToast";
import { useDispatch, useSelector } from "react-redux";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import FacilityTable from "./Tables/FacilityTable";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import AutocompleteComp from "../../../components/common/Autocomplete";
const AddFacilities = ({ isEditable, id }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.onboarding);
  const [facilityName, setFacilityName] = useState("");
  const [account, setAccount] = useState(null);
  const [services, setServices] = useState([]);
  const [address, setAddress] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [storeManager, setStoreManager] = useState("");
  const [holidaySchedule, setHolidaySchedule] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [DisableAddAnother, setAddAnother] = useState(true);
  const [loading, setLoading] = useState(false);
  const [facilityDetails, setFacilityDetails] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const accList = await getDataFromAPI.getAllAccount();
      setAccountList(accList);
      const serviceLst = await getDataFromAPI.getAllServices();
      setServiceList(serviceLst);
    };

    fetchData();
  }, []);
  useEffect(() => {
    setFacilityName(facilityDetails.facility_name);
    setAccount(
      getDataFromAPI.getSelectedItem(facilityDetails.account, accountList)
    );
    setServices(
      getDataFromAPI.getSelectedServices(
        facilityDetails.service_on,
        serviceList
      )
    );
    setAddress(facilityDetails.address);
    setContactPerson(facilityDetails.contact_person);
    setStoreManager(facilityDetails.store_manager_name);
    setHolidaySchedule(facilityDetails.holiday_schedule);
  }, [facilityDetails, accountList, serviceList]);
  useEffect(() => {
    async function getFacilityDetails() {
      const ans = await getDataFromAPI.getFacilityById(id);
      setFacilityDetails(ans);
    }
    if (isEditable && id) {
      getFacilityDetails();
    }
  }, [id, isEditable]);
  const handleFacilityNameChange = (event) => {
    const selectedValue = event.target.value;
    setFacilityName(selectedValue);
  };

  const handleAccountChange = (value) => {
    setAccount(value);
  };

  const handleServicesChange = (value) => {
    setServices(value);
  };

  const handleAddressChange = (event) => {
    const selectedValue = event.target.value;
    setAddress(selectedValue);
  };

  const handleContactPersonChange = (event) => {
    const selectedValue = event.target.value;
    setContactPerson(selectedValue);
  };

  const handleStoreManagerChange = (event) => {
    const selectedValue = event.target.value;
    setStoreManager(selectedValue);
  };

  const handleHolidaySchedule = (event) => {
    const selectedValue = event.target.value;
    setHolidaySchedule(selectedValue);
  };

  const getOptionDisabled = (item) => {
    return false;
  };
  const handleClick = () => {
    if (facilityName.length < 3) {
      notifiToasterWarn("Enter Facility Name Correctly");
      return;
    } else if (!account) {
      notifiToasterWarn("Please Select the account");
      return;
    } else if (services.length == 0) {
      notifiToasterWarn("Please Select at least one service ");
    } else {
      const facilityResponse = {
        facility_name: facilityName,
        address: address,
        contact_person: contactPerson,
        store_manager_name: storeManager,
        account: account.value,
        holiday_schedule: "string",
        go_live_date: new Date(Date.now()).toISOString().split("T")[0],
        service_on: services.map((option) => option.value),
      };
      setLoading(true);
      if (isEditable) {
        dispatch(OnboardingAction.updateFacility(id, facilityResponse))
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        dispatch(OnboardingAction.createFacility(facilityResponse))
          .then(() => {
            setLoading(false);
            setAddAnother(false);
            resetAllStates();
          })
          .catch(() => {
            setLoading(false);
            setAddAnother(true);
          });
      }
    }
  };
  function resetAllStates() {
    setFacilityName("");
    setAccount(account);
    setServices([]);
    setAddress("");
    setContactPerson("");
    setStoreManager("");
    setHolidaySchedule("");
    setAddAnother(true);
  }

  return (
    <div className="container">
      <div>{!isEditable && <h2 className="mt-2 mb-4">Add Facility:</h2>}</div>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label={"Facility Name"}
            value={facilityName}
            onChange={handleFacilityNameChange}
            type={"text"}
            placeholder={"Facility Name"}
            name="facility name"
            isrequired={true}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          {isEditable && account ? (
            <AutocompleteComp
              option={accountList}
              onChange={handleAccountChange}
              value={account}
              label="Account"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={accountList}
              onChange={handleAccountChange}
              label="Account"
            />
          )}
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <MultiSelectAutoAutoComplete
            option={serviceList}
            value={services}
            onChange={handleServicesChange}
            label="Services Selected"
            getOptionDisabled={getOptionDisabled}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label={"Address"}
            value={address}
            onChange={handleAddressChange}
            type={"text"}
            placeholder={"Address"}
            name="address"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label={"Contact Person"}
            value={contactPerson}
            onChange={handleContactPersonChange}
            type={"text"}
            placeholder={"Contact Person"}
            name="contact person"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label={"Store Manager Name"}
            value={storeManager}
            onChange={handleStoreManagerChange}
            type={"text"}
            placeholder={"Store Manager Name"}
            name="store manager name"
            isrequired={false}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label="Holiday Schedule"
            value={holidaySchedule}
            onChange={handleHolidaySchedule}
            type={"text"}
            placeholder={"Holiday Schedule"}
            name="holiday schedule"
            isrequired={false}
          />
        </div>
      </div>

      <div className="row mt-sm-3">
        <div className="col-12 col-sm-6 col-lg-3 mb-3">
          <Button
            variant="contained"
            onClick={handleClick}
            disabled={!DisableAddAnother || loading}
            sx={{ minWidth: "100px" }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : isEditable ? (
              "Update Facility"
            ) : (
              "Add Facility"
            )}
          </Button>
        </div>
      </div>
      {!isEditable && <FacilityTable data={state.newAddedFacility} />}
    </div>
  );
};
AddFacilities.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default AddFacilities;
