import titleReducer from "../redux/Reducer/ChangeTopbarTiitleReducer";
import { configureStore } from "@reduxjs/toolkit";
import OperationModeReducer from "./Reducer/OperationModeReducer";
import CommonReducer from "../redux/Reducer/CommonReducer";
import ConfigPageReducer from "./Reducer/ConfigPageReducer";
import dataReducer from "../redux/Reducer/LoginReducer";
import ProfileReducer from "../redux/Reducer/ProfileReducer";
import OnboardingReducer from "./Reducer/OnboardingReducer";
import DeviceRelatedReducer from "./Reducer/DeviceRelatedReducer";
import ClientOnboardingReducer from "./Reducer/ClientOnboardingReducer";
export const store = configureStore({
  reducer: {
    title: titleReducer,
    commonData: CommonReducer,
    Approval: OperationModeReducer,
    configData: ConfigPageReducer,
    profile: ProfileReducer,
    login: dataReducer,
    onboarding: OnboardingReducer,
    device: DeviceRelatedReducer,
    clientOnboard: ClientOnboardingReducer,
  },
  devTools: true,
});
