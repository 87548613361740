const initialState = {
  selectedCamera: 0,
  selectedNvr: 0,
  newImage: "",
  loadingForImage: false,
  configList: JSON.parse(localStorage.getItem("deviceConfigList")) ?? [],
  isCameraEZVIZ: false,
};

export default function DeviceRelatedReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_SELECTED_CAMERA_CONFIG":
      return {
        ...state,
        selectedCamera: action.payload,
      };
    case "SET_SELECTED_NVR_CONFIG":
      return {
        ...state,
        selectedNvr: action.payload,
      };
    case "SET_IMAGE_FILE_FOR_POINTS":
      return {
        ...state,
        newImage: action.payload,
      };
    case "SET_LOADING_FOR_POINTS":
      return {
        ...state,
        loadingForImage: action.payload,
      };
    case "UPDATE_CONFIG_LIST_FOR_DEVICE":
      return {
        ...state,
        configList: [...state.configList, action.payload],
      };
    case "SET_CONFIG_LIST_FOR_DEVICE":
      return {
        ...state,
        configList: action.payload,
      };
    case "SET_IS_CAMERA_EZVIZ":
      return {
        ...state,
        isCameraEZVIZ: action.payload,
      };
    default:
      return state;
  }
}
