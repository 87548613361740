import React, { useEffect, useState } from "react";
// import TextField from "../../../components/formLayout/TextField";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SelectFieldComp from "../../../components/formLayout/SelectFieldComp";
import CheckBoxComp from "../../../components/formLayout/CheckBox";
import { useDispatch } from "react-redux";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import { notifiToasterWarn } from "../../../components/common/UseToast";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import OperationModeAction from "../../../redux/Action/OperationModeAction";
import getDataFromAPI from "../Utils/getDataFromAPI";
const logoDict = {
  Spectra:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/clientlogo/spectranew.jpg",
  VCTech:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/clientlogo/vctech.jpeg",
};
function getLogoDetails(input) {
  if (input === logoDict.Spectra) {
    return "Spectra";
  } else if (input === logoDict.VCTech) {
    return "VCTech";
  } else {
    return "Agrex";
  }
}
const AddAccount = ({ isEditable, id }) => {
  const [formData, setFormData] = useState({
    accountName: "",
    phase: "POC",
    primaryEmail: "",
    secondaryEmail: "",
    gstin: "",
    pan: "",
    headOfficeAddress: "",
    telephoneNumber: "",
    slug: "",
    shinobiGroupKey: "",
    shinobiApiKey: "",
    logo: "Agrex",
    profileImage: "",
    isClient: false,
    isOperation: false,
    isActive: true,
  });

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleClick = () => {
    var config = {
      logo:
        formData.profileImage.length > 0
          ? formData.profileImage
          : "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/clientlogo/agrex.jpeg",
      client: formData.isClient ? "True" : "False",
      Operation: formData.isOperation ? "True" : "False",
    };
    if (formData.logo == "Spectra" || formData.logo == "VCTech")
      config["client_logo"] = logoDict[formData.logo];

    var valid = false;
    if (formData && formData.accountName && formData.accountName.length > 2) {
      valid = true;
    } else {
      notifiToasterWarn("Enter the account name");
    }
    if (valid) {
      const data = {
        name: formData.accountName,
        is_active: formData.isActive,
        primary_email_address: formData.primaryEmail,
        secondary_email_address: formData.secondaryEmail,
        phase: formData.phase,
        gstin: formData.gstin,
        pan: formData.pan,
        head_office_address: formData.headOfficeAddress,
        telephone_number: formData.telephoneNumber,
        slug: formData.slug.replace(/ /g, "-"),
        shinobi_group_key: formData.shinobiGroupKey,
        shonibi_api_key: formData.shinobiApiKey,
        config: config,
      };
      setLoading(true);
      if (isEditable) {
        dispatch(OnboardingAction.editAccount(id, data)).then(() => {
          const profile_id = localStorage.getItem("profile_id");
          dispatch(OperationModeAction.getAllAccount(profile_id));
          setLoading(false);
        });
      } else {
        dispatch(OnboardingAction.createAccount(data))
          .then(() => {
            setLoading(false);
            setFormData({
              accountName: "",
              phase: "POC",
              primaryEmail: "",
              secondaryEmail: "",
              gstin: "",
              pan: "",
              headOfficeAddress: "",
              telephoneNumber: "",
              slug: "",
              shinobiGroupKey: "",
              shinobiApiKey: "",
              logo: "Agrex",
              profileImage: "",
              isClient: false,
              isOperation: false,
              isActive: true,
            });
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    async function getAccountDetails() {
      const ans = await getDataFromAPI.getAccountById(id);
      setFormData({
        accountName: ans.name,
        phase: ans.phase,
        primaryEmail: ans.primary_email_address,
        secondaryEmail: ans.secondary_email_address,
        gstin: ans.gstin,
        pan: ans.pan,
        headOfficeAddress: "",
        telephoneNumber: ans.telephone_number,
        slug: "",
        shinobiGroupKey: ans.shinobi_group_key,
        shinobiApiKey: ans.shinobi_group_key,
        logo: getLogoDetails(ans.config.client_logo),
        profileImage: ans.config.logo,
        isClient: ans.config.client === "True",
        isOperation: ans.config.Operation
          ? ans.config.Operation == "True"
          : false,
        isActive: ans.is_active,
      });
    }
    if (isEditable && id) {
      getAccountDetails();
    }
  }, [id, isEditable]);

  function handleSidebarLogoChange(val) {
    setFormData((prevData) => ({
      ...prevData,
      logo: val,
    }));
  }
  function handlePhaseChange(val) {
    setFormData((prevData) => ({
      ...prevData,
      phase: val,
    }));
  }
  return (
    <div className="container">
      {!isEditable && (
        <div>
          <h2 className="mt-2 mb-4">Add Account:</h2>
        </div>
      )}
      <div className="row">
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="accountName"
            label="Account Name"
            variant="outlined"
            fullWidth
            type="text"
            name="accountName"
            value={formData.accountName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <SelectFieldComp
            name="phase"
            options={["POC", "Post_POC", "Commercial"]}
            value={formData.phase}
            onChange={handlePhaseChange}
            label="Phase"
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="primaryEmail"
            label="Primary Email"
            variant="outlined"
            fullWidth
            type="email"
            name="primaryEmail"
            value={formData.primaryEmail}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="secondaryEmail"
            label="Secondary Email"
            variant="outlined"
            fullWidth
            type="email"
            name="secondaryEmail"
            value={formData.secondaryEmail}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="gstin"
            label="GSTIN Number"
            variant="outlined"
            fullWidth
            type="number"
            name="gstin"
            value={formData.gstin}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="pan"
            label="PAN"
            variant="outlined"
            fullWidth
            type="number"
            name="pan"
            value={formData.pan}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="headOfficeAddress"
            label="Head Office Address"
            variant="outlined"
            fullWidth
            type="text"
            name="headOfficeAddress"
            value={formData.headOfficeAddress}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="telephoneNumber"
            label="Phone Number"
            variant="outlined"
            fullWidth
            type="text"
            name="telephoneNumber"
            value={formData.telephoneNumber}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>{" "}
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="slug"
            label="Slug"
            variant="outlined"
            fullWidth
            type="text"
            name="slug"
            value={formData.slug}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="shinobiGroupKey"
            label="Shinobi group key"
            variant="outlined"
            fullWidth
            type="text"
            name="shinobiGroupKey"
            value={formData.shinobiGroupKey}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="shinobiApiKey"
            label="Shinobi API key"
            variant="outlined"
            fullWidth
            type="text"
            name="shinobiApiKey"
            value={formData.shinobiApiKey}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="profileImage"
            label="Profile Image"
            variant="outlined"
            fullWidth
            type="text"
            name="profileImage"
            value={formData.profileImage}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <SelectFieldComp
            name="logo"
            options={["Agrex", "Spectra", "VCTech"]}
            value={formData.logo}
            onChange={handleSidebarLogoChange}
            label="Sidebar Logo"
          />
        </div>
        <div className="row mt-sm-3">
          <div className="col-12 col-sm-6 col-lg-3 mb-3">
            <CheckBoxComp
              name="isClient"
              label="Client"
              checked={formData.isClient}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-3 mb-3">
            <CheckBoxComp
              name="isOperation"
              label="Operation"
              checked={formData.isOperation}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-3 mb-3">
            <CheckBoxComp
              name="isActive"
              label="Active"
              checked={formData.isActive}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mt-sm-3">
          <div className="col-12 col-sm-6 col-lg-3 mb-3">
            <Button
              variant="contained"
              onClick={loading ? null : handleClick}
              disabled={loading}
              sx={{ minWidth: "100px" }}
            >
              {" "}
              {loading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : isEditable ? (
                "Update Details"
              ) : (
                "Add Account"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
AddAccount.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.any,
};
export default AddAccount;
