import colorConfigs from "../../configs/colorConfigs";
const mainCss = {
  inputSelect: {
    width: "100%",
    height: "36px",
    // backgroundColor:colorConfigs.tableHeadColor,
    backgroundColor: "#F7F7FC",
    borderRadius: "10px",
    outline: "none",
    boxShadow: "none",
    border: "none",
  },
  divBelowTopbar: {
    marginTop: "20px",
    borderRadius: "20px",
  },
  divBelowTopbarSecond: {
    // backgroundColor: colorConfigs.sidebar.bg,
    borderRadius: "20px",
    marginTop: "20px",
  },
  // divsettingDashpage: {
  //   backgroundColor: colorConfigs.sidebar.bg,
  //   // box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.11);
  //   // border-radius: 10px;
  //   marginTop: "20px",
  //   boxShadow:"2px 8px 4px",
  //   // margin: "6px",
  // },
  percentbargraphdiv: {
    backgroundColor: colorConfigs.sidebar.bg,
    borderRadius: "20px",
    marginTop: "20px",
    margin: "6px",
  },
  mainContainer: {
    paddingBottom: "20px",
    paddingTop: "15px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  downloadButton: {
    height: "51px",
    width: "51px",
    borderRadius: "50%",
    backgroundColor: "#4318FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  downloadIcon: {
    color: "white",
  },
  bigButtonIcon: {
    width: "70px",
    height: "70px",
    borderRadius: "40px",
    backgroundColor: "#4318FF",
  },
  bigIconInRhms: {
    fontSize: "50px",
    color: "white",
  },
  sliderDesign: {
    marginTop: "7px",
    color: "#4318FF",
    width: "90%",
    marginLeft: "20px",
    "& .MuiSlider-valueLabel": {
      backgroundColor: "#4318FF",
      borderRadius: "10px",
      color: "white", // Set the value label color to red
    },
    "& .MuiSlider-thumb": {
      width: "30px",
      height: "30px",
      borderWidth: 8, // Set the border width to 2 pixels
      borderColor: "#4318FF", // Set the border color to red
      borderStyle: "solid",
      backgroundColor: "white", // Change the background color of the thumb to the dark variant of the primary color
    },
    "& .MuiSlider-track": {
      height: "6px",
      background:
        "linear-gradient(90deg, rgba(212,211,224,1) 0%, rgba(96,96,214,0.9416141456582633) 45%, rgba(67,24,255,1) 100%)",
    },
    "& .MuiSlider-rail": {
      backgroundColor: "grey ",
    },
  },
  remarkInput: {
    width: "173px",
    height: "30px",
    color: "#F4F7FE",
  },
  alertDashCard: {
    borderRadius: "15px",
  },
  alertCardIconDiv: {
    backgroundColor: "#F4F7FE",
    borderRadius: "25px",
  },
  alertdashIcon: {
    fontSize: 60,
    color: "#4318FF",
  },
  paraDesignDiv: {
    marginLeft: "10px",
  },
  paraDesign: {
    color: "#A3AED0",
    paddingTop: "0px",
    marginTop: "0px",
  },
  cardBackGround: {
    backgroundColor: "white",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "15px",
    display: "flex",
    paddingTop: "20px",
  },
};

export default mainCss;
