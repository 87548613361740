import React from "react";
import "../../assets/css/main.css";
import { Bars } from "react-loader-spinner";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
const NoCardToShow = (propType) => {
  return (
    <div>
      <div className="main-container-nodata">
        {propType.show ? (
          <Bars
            height="80"
            width="80"
            color="var(--card-text-color)"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <div>
            <SentimentVeryDissatisfiedIcon
              sx={{ fontSize: "100px", color: "var(--card-text-color)" }}
            />
            <p style={{ fontSize: "80px" }}>No Card To Show</p>.
          </div>
        )}
      </div>
    </div>
  );
};

export default NoCardToShow;
