import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import assets from "../../assets";

const AppLoader = () => {
  return (
    <div className="app-loader">
      <div className="app-loader-content">
        <img src={assets.images.logo} id="sidebar-logo-image" />
        <CircularProgress />
      </div>
    </div>
  );
};

export default AppLoader;
