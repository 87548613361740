import { ReactNode } from "react";

export type RouteType = {
  element: ReactNode,
  state: string,
  index?: boolean,
  path?: string,
  child?: RouteType[],
  sidebarProps?: {
    displayText: string,
    icon?: ReactNode,
  },
};

export const BACKEND_URL = "https://backendv2.agrexai.com/api/v2";
// export const BACKEND_URL = "https://staging.agrexai.com/api/v2";
// export const BACKEND_URL = "http://192.168.1.197:8000/api/v2";
