import React, { useEffect, useState } from "react";
import AutocompleteCompUncontrollable from "../../components/common/AutoCompleteUncontrollable";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import getDataFromAPI from "../Onboarding/Utils/getDataFromAPI";
import { Button } from "@mui/material";
import { notifiToasterWarn } from "../../components/common/UseToast";
import CircularProgress from "@mui/material/CircularProgress";
import JpolygonModal from "./JpolygonModal";
import { useDispatch, useSelector } from "react-redux";
import deviceRelatedAction from "../../redux/Action/DeviceRelatedAction";
import ConfigTable from "./ConfigTable";
const AivisConfig = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.device);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [cameraList, setCameraList] = useState([]);
  const [nvrList, setNvrList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [useCase, setUseCase] = useState({});
  const [staffExclusion, setStaffExclusion] = useState({});
  const [demographics, setDemographics] = useState(false);
  const [selectedNVR, setSelectedNvr] = useState(null);
  const [saveFullImage, setFullImage] = useState(false);
  const [saveFaceImages, setFaceImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ezvizCamera, setEzvizCamera] = useState(false);
  const [isSomeDataSaved, setSomeData] = useState(false);
  const [formData, setFormData] = useState({
    linePoint: "",
    entry: "",
    LowerBoundHSV: "",
    UpperBoundHSV: "",
  });

  const [errors, setErrors] = useState({
    linePoint: "",
    LowerBoundHSV: "",
    UpperBoundHSV: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    if (name === "demographics") {
      setDemographics(checked);
    } else if (name === "save_full_image") {
      setFullImage(checked);
    } else if (name === "save_face_images") {
      setFaceImage(checked);
    } else if (name === "ezvizCamera") {
      setEzvizCamera(checked);
      dispatch(deviceRelatedAction.setIsCameraEzviz(checked));
    }
  };

  useEffect(() => {
    fetchNvrList();
  }, []);

  useEffect(() => {
    if (selectedNVR && selectedNVR.value) {
      fetchCameraListByNVR(selectedNVR.value);
    }
  }, [selectedNVR]);

  const fetchNvrList = async () => {
    try {
      const tempNvrData = await getDataFromAPI.getAllNVR();
      const tempDeviceData = await getDataFromAPI.getDeviceList();
      setNvrList(tempNvrData);
      setDeviceList(tempDeviceData);
    } catch (error) {
      console.error("Error fetching NVR list:", error);
      notifiToasterWarn("Error fetching NVR list");
    }
  };

  const fetchCameraListByNVR = async (id) => {
    try {
      const cameraData = await getDataFromAPI.getCameraByNvrId(id);
      setCameraList(cameraData);
    } catch (error) {
      console.error("Error fetching camera list by NVR:", error);
      notifiToasterWarn("Error fetching camera list by NVR");
    }
  };

  const handleUseCaseChange = (value) => {
    setUseCase(value);
  };

  const handleStaffExclusionChange = (value) => {
    setStaffExclusion(value);
  };
  const validateCameraAndNVR = () => {
    if (!selectedNVR) {
      notifiToasterWarn("Please Select NVR");
      return false;
    }
    if (!selectedCamera) {
      notifiToasterWarn("Please Select Camera");
      return false;
    }
    return true;
  };
  async function handleSubmit() {
    const mydata = JSON.parse(localStorage.getItem("deviceConfigList"));
    const sessioData = JSON.parse(
      sessionStorage.getItem("remoteIotCredential")
    );
    if (!mydata) {
      notifiToasterWarn("Please Enter Details");
      return;
    }
    const data = {
      input: JSON.stringify(mydata),
      device_hostname: sessioData.hostName,
      device_port: sessioData.portNumber,
      device_username: sessioData.deviceName,
      device_password: sessioData.devicePassword,
    };
    setLoading(true);
    try {
      await dispatch(deviceRelatedAction.sendDataToUpdateConfig(data))
        .then(() => setLoading(false))
        .catch(() => {
          setLoading(false);
        });

      dispatch(deviceRelatedAction.setNewConfigList([]));
      localStorage.removeItem("deviceConfigList");
    } catch (error) {
      console.error("API call failed:", error);
    }
  }

  function checkValidArray(usedIn, value) {
    try {
      const parsedArray = JSON.parse(value);
      if (parsedArray.length !== 3) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [usedIn]: "Please enter three points",
        }));
        return false;
      }
      if (Array.isArray(parsedArray)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [usedIn]: "",
        }));
        return true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [usedIn]: `The ${usedIn} is not a valid JSON array.`,
        }));
        return false;
      }
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [usedIn]: `The ${usedIn} is not a valid JSON array.`,
      }));
      return false;
    }
  }

  function handleSaveAndAddNew(e) {
    e.preventDefault();
    var temp = {};

    var deviceConfigList =
      JSON.parse(localStorage.getItem("deviceConfigList")) || [];
    if (!selectedNVR) {
      notifiToasterWarn("Please Select NVR");
      return;
    }
    if (!selectedCamera) {
      notifiToasterWarn("Please Select camera");
      return;
    }
    if (!checkValidArray("linePoint", formData.linePoint)) {
      return;
    }

    if (!staffExclusion || !staffExclusion.label) {
      notifiToasterWarn("Please Select Staff Exclusion");
      return;
    }
    temp = {
      cameraId: selectedCamera.value,
      cameraName: selectedCamera.label,
      deviceId: selectedDevice.value,
      deviceName:
        useCase.value == 0
          ? `${selectedDevice.label}_pc`
          : `${selectedDevice.label}_pb`,
      linePoint: JSON.parse(formData.linePoint).slice(0, 2),
      entryPoints: JSON.parse(formData.linePoint)[2],
      save_face_image: saveFaceImages,
      save_full_image: saveFullImage,
      demographics: demographics,
      staff_mode: staffExclusion.label === "Yes",
      useCase: useCase.value,
    };

    if (staffExclusion.label === "Yes") {
      temp.staff_lower_thresholds = JSON.parse(formData.LowerBoundHSV);
      temp.staff_upper_thresholds = JSON.parse(formData.UpperBoundHSV);
    }

    deviceConfigList.push(temp);
    localStorage.setItem("deviceConfigList", JSON.stringify(deviceConfigList));
    dispatch(deviceRelatedAction.updateConfigList(temp));
    setSelectedCamera(selectedCamera);
    setSelectedNvr(selectedNVR); // Assuming you have a selectedNVR state
    setUseCase(useCase);
    setFullImage(false);
    setFaceImage(false);
    setDemographics(false);
    setSomeData(true);
    // Resetting formData
    setFormData({
      linePoint: "",
      entry: "",
      LowerBoundHSV: "",
      UpperBoundHSV: "",
    });
  }

  function handleNvrChange(value) {
    setSelectedNvr(value);
    dispatch(deviceRelatedAction.updateSelectedNVR(value.value));
  }

  function handleCameraChange(value) {
    setSelectedCamera(value);
    dispatch(deviceRelatedAction.updateSelectedCamera(value.value));
  }
  function handleDeviceChange(value) {
    setSelectedDevice(value);
  }
  return (
    <div>
      <form onSubmit={handleSaveAndAddNew} method="POST">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 ol-4 mt-3">
            <AutocompleteCompUncontrollable
              option={nvrList}
              onChange={handleNvrChange}
              label="Select NVR"
              disableClearable={true}
              showValue={true}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
            <AutocompleteCompUncontrollable
              option={cameraList}
              onChange={handleCameraChange}
              label="Select Camera"
              disableClearable={true}
              showValue={true}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
            <AutocompleteCompUncontrollable
              option={deviceList}
              onChange={handleDeviceChange}
              label="Select Device"
              disableClearable={true}
              showValue={true}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3 d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={ezvizCamera}
                  onChange={handleChangeCheckbox}
                  name="ezvizCamera"
                  color="primary"
                />
              }
              label="Ezviz Camera"
            />
            <div className="mt-2">
              <JpolygonModal
                isEzviz={ezvizCamera}
                validate={validateCameraAndNVR}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
            <AutocompleteCompUncontrollable
              option={[
                { label: "People Count", value: 0 },
                { label: "Passer By", value: 1 },
              ]}
              onChange={handleUseCaseChange}
              label="Select UseCase"
              disableClearable={true}
              showValue={true}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
            <TextField
              id="linePoint"
              label="Line Points"
              variant="outlined"
              fullWidth
              type="text"
              name="linePoint"
              value={formData.linePoint}
              onChange={handleInputChange}
              error={Boolean(errors.linePoint)}
              helperText={errors.linePoint}
              required
            />
          </div>

          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
            <AutocompleteCompUncontrollable
              option={[{ label: "Yes" }, { label: "No" }]}
              onChange={handleStaffExclusionChange}
              label="Staff Exclusion"
              disableClearable={true}
              showValue={true}
            />
          </div>

          {staffExclusion.label === "Yes" && (
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
                <TextField
                  id="LowerBoundHSV"
                  label="Lower Bound HSV"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="LowerBoundHSV"
                  value={formData.LowerBoundHSV}
                  onChange={handleInputChange}
                  error={Boolean(errors.LowerBoundHSV)}
                  helperText={errors.LowerBoundHSV}
                  required
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
                <TextField
                  id="UpperBoundHSV"
                  label="Upper Bound HSV"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="UpperBoundHSV"
                  value={formData.UpperBoundHSV}
                  onChange={handleInputChange}
                  error={Boolean(errors.UpperBoundHSV)}
                  helperText={errors.UpperBoundHSV}
                  required
                />
              </div>
            </div>
          )}
          <div className="row mt-3">
            <div className="col-12 col-sm-6 col-md-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={demographics}
                    onChange={handleChangeCheckbox}
                    name="demographics"
                    color="primary"
                  />
                }
                label="Demographics"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveFullImage}
                    onChange={handleChangeCheckbox}
                    name="save_full_image"
                    color="primary"
                  />
                }
                label="Save Full Image"
              />
            </div>{" "}
            <div className="col-12 col-sm-6 col-md-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveFaceImages}
                    onChange={handleChangeCheckbox}
                    name="save_face_images"
                    color="primary"
                  />
                }
                label="Save Face Images"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Button variant="contained" color="primary" type="submit">
              Save and Add Another
            </Button>
          </div>
          {isSomeDataSaved && (
            <div className="col-6">
              <Button
                variant="contained"
                type="button"
                disabled={loading}
                sx={{ minWidth: "100px" }}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  "Upload Data"
                )}
              </Button>
            </div>
          )}
        </div>
      </form>
      <div className="mt-3">
        <ConfigTable data={state.configList} />
      </div>
    </div>
  );
};

export default AivisConfig;
