import React, { useEffect } from "react";
import ProgressMenu from "./Utils/ProgressMenu";
import "./formLayout.css";
import FromComponent from "./Utils/FromComponent";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import OnboardingAction from "../../redux/Action/OnboardingAction";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FormLayoutComponent = () => {
  const onboardingState = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    dispatch(changeTopbarTitle("Onboarding"));
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(OnboardingAction.updateCurrentStep(0));
  };
  const handleNext = () => {
    if (onboardingState.activeStep <= 6) {
      dispatch(
        OnboardingAction.updateCurrentStep(onboardingState.activeStep + 1)
      );
    }
  };

  const handleBack = () => {
    dispatch(
      OnboardingAction.updateCurrentStep(onboardingState.activeStep - 1)
    );
  };
  function handleFinish() {
    setOpen(true);
    localStorage.removeItem("PreviouslyAddedNVR");
    localStorage.removeItem("PreviouslyAddedFacilities");
    localStorage.removeItem("PreviouslyAddedCamera");
    localStorage.removeItem("newlyCreatedAccount");
    dispatch(OnboardingAction.clearOnboardingData());
    localStorage.removeItem("activeStep");
  }

  return (
    <div className="main-form-layout-container">
      <div className="d-flex">
        <div className="sidebar-from-layout">
          <ProgressMenu />
        </div>
        <div style={{ width: "100%" }}>
          <FromComponent />
        </div>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Completed"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You have successfully completed all the necessary steps, and now
              your account is fully prepared for use.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="button-div mb-2">
        <Button
          disabled={onboardingState.activeStep === 0}
          onClick={handleBack}
          sx={{ mt: 1, mr: 1 }}
        >
          Back
        </Button>{" "}
        <Button
          variant="contained"
          onClick={onboardingState.activeStep === 7 ? handleFinish : handleNext}
          sx={{ mt: 1, mr: 1 }}
        >
          {onboardingState.activeStep === 7 ? "Finish" : "Continue"}
        </Button>
      </div>
    </div>
  );
};

export default FormLayoutComponent;
