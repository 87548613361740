import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AutocompleteCompUncontrollable from "../../components/common/AutoCompleteUncontrollable";
import MultiSelectFreeSolo from "../../components/formLayout/MultiSelectFreeSolo";
import getDataFromAPI from "../Onboarding/Utils/getDataFromAPI";
import FleetDashboardAPI from "./fleetDashAPIs";
import SettingsIcon from "@mui/icons-material/Settings";
import "../FleetDashboard/fleetDash.css";
const channelList = [
  { label: "Agrex", value: 1 },
  { label: "VCTech", value: 2 },
  { label: "Spectra", value: 3 },
];

const initialState = {
  selectedFacility: null,
  selectedChannel: null,
  selectedAccount: null,
};

const SettingsModal = () => {
  const [formData, setFormData] = useState(initialState);
  const [show, setIsShow] = useState(false);
  const [facilities, setFacilityList] = useState([]);
  const [facilityEmail, setFacilityEmail] = useState([]);
  const [accounts, setAccountList] = useState([]);
  const [accountEmail, setAccountEmail] = useState([]);
  const [channelEmail, setChannelEmail] = useState([]);
  const [updatingEmailsAccount, SetUpdateLoadingAccount] = useState(false);
  const [updatingEmailsChannel, SetUpdateLoadingChannel] = useState(false);
  const [updatingEmailsFacility, SetUpdateLoadingfacility] = useState(false);
  const [errors, setErrors] = useState({});
  const accoundId = JSON.parse(localStorage.getItem("profile")).account
    .account_id;
  const isExternalVendor = accoundId == 113 ? 3 : accoundId == 25 ? 2 : -1;
  const fetchData = async () => {
    try {
      const [accounts] = await Promise.all([
        isExternalVendor == -1
          ? getDataFromAPI.getAllAccount()
          : FleetDashboardAPI.getAllAccountViaChannel(isExternalVendor),
      ]);
      setAccountList(accounts);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setIsShow(true);
  };

  const handleClose = () => {
    setIsShow(false);
  };

  const handleFacilityChange = async (val) => {
    setFormData({ ...formData, selectedFacility: val });
    const response = await getDataFromAPI.getFacilityById(val.value);
    setFacilityEmail(response.email_list ?? []);
  };

  const handleChannelChange = async (val) => {
    setFormData({ ...formData, selectedChannel: val });
    const response = await FleetDashboardAPI.getChannelEmails(val.value);
    setChannelEmail(response.email_list ?? []);
    const accData = await FleetDashboardAPI.getAllAccountViaChannel(val.value);
    setAccountList(accData);
  };

  const handleAccountChange = async (val) => {
    setFormData({ ...formData, selectedAccount: val });
    const response = await getDataFromAPI.getAccountById(val.value);
    setAccountEmail(response.email_list ?? []);
    const facilityData = await getDataFromAPI.getFacilityByAcc(val.value);
    setFacilityList(facilityData);
  };

  const handleChannelEmailChange = (newValue) => {
    setChannelEmail(newValue);
  };

  const handleFacilityEmailChange = (newValue) => {
    setFacilityEmail(newValue);
  };

  const handleAccountEmailChange = (newValue) => {
    setAccountEmail(newValue);
  };

  async function UpdateEmailAddress(usedIn) {
    if (usedIn == "accounts") {
      SetUpdateLoadingAccount(true);
      await FleetDashboardAPI.editAccountEmail(formData.selectedAccount.value, {
        email_list: accountEmail,
      });
      SetUpdateLoadingAccount(false);
    } else if (usedIn == "facility") {
      SetUpdateLoadingfacility(true);
      await FleetDashboardAPI.editFacilityEmail(
        formData.selectedFacility.value,
        {
          email_list: facilityEmail,
        }
      );
      SetUpdateLoadingfacility(false);
    } else {
      SetUpdateLoadingChannel(true);
      await FleetDashboardAPI.editChannelEmail(formData.selectedChannel.value, {
        email_list: channelEmail,
      });
      SetUpdateLoadingChannel(false);
    }
  }
  return (
    <div>
      <IconButton>
        <SettingsIcon onClick={handleClickOpen} />
      </IconButton>
      <Dialog onClose={handleClose} open={show} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>Email Configuration</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="setting_modal_container">
            <div className="row flex justify-content-start">
              <div className="col-2 pt-3">
                <h4>Channel:</h4>
              </div>
              <div className="col-md-10">
                <div className="row message-select-field mt-2 py-2">
                  <div className="col-md-4">
                    <AutocompleteCompUncontrollable
                      disableClearable={true}
                      showValue={true}
                      option={
                        isExternalVendor != -1
                          ? channelList.filter(
                              (item) => item.value === isExternalVendor
                            )
                          : channelList
                      }
                      onChange={handleChannelChange}
                      label="Channel"
                    />
                  </div>
                </div>

                <div className="row message-select-field mt-2 py-2 email-field">
                  <MultiSelectFreeSolo
                    value={channelEmail}
                    onChangeFunc={handleChannelEmailChange}
                    label={"Channel Email Details"}
                    placeholder={"add new Email"}
                  />
                </div>
                <div className="d-flex col-md-4 col-12 justify-md-content-center align-items-center">
                  <Button
                    variant="contained"
                    onClick={() => UpdateEmailAddress("channel")}
                  >
                    {updatingEmailsChannel ? "Updating ..." : "Update Emails"}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row flex justify-content-start">
              <div className="col-2 pt-3">
                <h4>Accounts:</h4>
              </div>
              <div className="col-md-10">
                <div className=" row message-select-field mt-2 py-2">
                  <div className="col-md-4">
                    <AutocompleteCompUncontrollable
                      disableClearable={true}
                      showValue={true}
                      option={accounts}
                      onChange={handleAccountChange}
                      label="Accounts"
                      ListboxProps={{ style: { maxHeight: "150px" } }}
                    />
                  </div>
                </div>
                <div className="row message-select-field mt-2 py-2 email-field">
                  <MultiSelectFreeSolo
                    value={accountEmail}
                    onChangeFunc={handleAccountEmailChange}
                    label={"Account Email Details"}
                    placeholder={"add new Email"}
                  />
                </div>
                <div className="d-flex col-md-4 col-12 justify-md-content-center align-items-center">
                  <Button
                    variant="contained"
                    onClick={() => UpdateEmailAddress("accounts")}
                  >
                    {updatingEmailsAccount ? "Updating ..." : "Update Emails"}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row flex justify-content-start">
              <div className="col-2 pt-3">
                <h4>Facility:</h4>
              </div>
              <div className="col-md-10">
                <div className="row message-select-field mt-2 py-2">
                  <div className="col-md-4">
                    <AutocompleteCompUncontrollable
                      disableClearable={true}
                      showValue={true}
                      option={facilities}
                      onChange={handleFacilityChange}
                      label="Facility"
                    />
                  </div>
                </div>
                <div className="row message-select-field mt-2 py-2 email-field">
                  <MultiSelectFreeSolo
                    value={facilityEmail}
                    onChangeFunc={handleFacilityEmailChange}
                    label={"Facility Email Details"}
                    placeholder={"add new Email"}
                  />
                </div>
                <div className="d-flex col-md-4 col-12 justify-md-content-center align-items-center">
                  <Button
                    variant="contained"
                    onClick={() => UpdateEmailAddress("facility")}
                  >
                    {updatingEmailsFacility ? "Updating ..." : "Update Emails"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SettingsModal;
