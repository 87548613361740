import axios from "axios";

import ApiSpec from "../features/EndPoints";
import { notifiToasterSuccess } from "../../components/common/UseToast";
import OperationDashEndpoint from "../features/OperationDashEndpoints";
// get Store

class OperationModeAction {
  // people Count courly functions
  static getStoresByAccount(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getStoresList(data), { headers })
        .then((response) => {
          let stores = [];
          response.data.forEach((element) => {
            stores.push({ text: element.facility_name, value: element.id });
          });
          dispatch({ type: "STORE_LIST_DATA_MODAL", payload: stores });
        });
    };
  }
  static getFacilitiesByAccountId(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getAllFacilityByAccountOP(data), { headers })
        .then((response) => {
          let stores = [];
          response.data.forEach((element) => {
            stores.push({ label: element.facility_name, value: element.id });
          });
          dispatch({
            type: "FACILITY_BY_ACCOUNT_LIST_DATA_MODAL",
            payload: stores,
          });
        });
    };
  }
  static getAllAccount(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getAllAccount(data), { headers })
        .then((response) => {
          let stores = [];
          response.data.forEach((element) => {
            stores.push({ label: element.name, value: element.account_id });
          });
          dispatch({ type: "ACCOUNT_LIST_DATA_MODAL", payload: stores });
        });
    };
  }
  static getSearchAccount(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(OperationDashEndpoint.seachAccountFromAll(data), { headers })
        .then((response) => {
          let stores = [];
          response.data.forEach((element) => {
            stores.push({ label: element.name, value: element.account_id });
          });
          dispatch({ type: "ACCOUNT_LIST_DATA_MODAL", payload: stores });
        });
    };
  }
  static getCamerasByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      await axios
        .get(ApiSpec.getCamerasByStore(data), { headers })
        .then((response) => {
          let device = [];
          response.data.forEach((element) => {
            device.push({ label: element.camera_name, value: element.id });
          });
          dispatch({ type: "CAMERAS_LIST_DATA_MODAL", payload: device });
        });
    };
  }

  static transferAlert(data) {
    return async (dispatch) => {
      axios.post(ApiSpec.transferAlert(), data).then((response) => {
        notifiToasterSuccess("Alert Transferred");
      });
    };
  }
  static deleteAlerts(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      try {
        await axios.delete(ApiSpec.deleteAlerts(data), { headers });
        notifiToasterSuccess("Alert Deleted");
      } catch (error) {
        console.error("Error deleting alerts:", error);
      }
    };
  }
  static transferEvent(data) {
    return async (dispatch) => {
      axios.post(ApiSpec.transferEvent(), data).then((response) => {
        notifiToasterSuccess("Alert Transferred");
      });
    };
  }
  static deleteEvents(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      try {
        await axios.delete(ApiSpec.deleteEvents(data), { headers });
        notifiToasterSuccess("Event Deleted");
      } catch (error) {
        console.error("Error deleting events:", error);
      }
    };
  }
  static formatdate(currentDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  static getAlertEventCard(id, time, date) {
    return async (dispatch) => {
      if (!date) {
        date = OperationModeAction.formatdate(new Date());
      }
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(
          OperationDashEndpoint.getAlertEventCard(
            id,
            time.startTime,
            time.endTime,
            date
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "UPDATE_ALERT_EVENT_CARD",
            payload: response.data.display,
          });
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_ALERT_EVENT_CARD",
            payload: [],
          });
        });
    };
  }

  static updatecurrentPage(data) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_CURRENT_PAGE", payload: data });
    };
  }
  static updateAlertEventCardAtIndex(data) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_ALERT_EVENT_CARD_AT_INDEX", payload: data });
    };
  }
}

export default OperationModeAction;
