import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import axios from "axios";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import FacilityTable from "./FacilityTable";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import { Bars } from "react-loader-spinner";
const FacilityDetails = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [userProfileList, setUserProfileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  async function fetchAccounts() {
    try {
      const response = await axios.get(OperationDashEndpoints.createAccount(), {
        headers: { Authorization: `Token ${token}` },
      });

      const accountData = [
        { label: "All", value: 0 },
        ...response.data.map((item) => ({
          label: item.name,
          value: item.account_id,
        })),
      ];

      setAccountList(accountData);
    } catch (error) {
      handleFetchError(error, "Error fetching account data:");
    }
  }

  async function fetchUserProfiles() {
    try {
      const response = await axios.get(
        OperationDashEndpoints.getAllUserProfiles(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      const userProfileData = [
        { label: "All", value: 0 },
        ...response.data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      ];

      setUserProfileList(userProfileData);
    } catch (error) {
      handleFetchError(error, "Error fetching user profile data:");
    }
  }

  async function fetchUserList() {
    try {
      let endpoint = null;
      setLoading(true);
      if (searchItem.length === 0 && !selectedUserProfile && !selectedAccount) {
        endpoint = OperationDashEndpoints.getAllFacilities();
      } else if (searchItem.length > 1) {
        endpoint = OperationDashEndpoints.getSearchFacilityProfile(searchItem);
      } else if (selectedAccount && selectedAccount.value) {
        endpoint = OperationDashEndpoints.getFacilityByAccount(
          selectedAccount.value
        );
      } else if (selectedUserProfile && selectedUserProfile.value) {
        endpoint = OperationDashEndpoints.getFacilityByUserProfile(
          selectedUserProfile.value
        );
      }

      if (endpoint) {
        const response = await axios.get(endpoint, {
          headers: { Authorization: `Token ${token}` },
        });

        setUserList(
          endpoint === OperationDashEndpoints.getAllFacilities() ||
            endpoint ===
              OperationDashEndpoints.getSearchFacilityProfile(searchItem)
            ? response.data
            : response.data.data
        );
        setLoading(false);
      } else {
        setLoading(false);
        setUserList([]);
      }
    } catch (error) {
      handleFetchError(error, "Error fetching user data:");
    }
  }

  function handleFetchError(error, message) {
    setUserList([]);
    console.error(message, error);
  }

  useEffect(() => {
    fetchAccounts();
    fetchUserProfiles();
    fetchUserList();
    dispatch(changeTopbarTitle("Admin/Facility"));
  }, [dispatch]);

  useEffect(() => {
    fetchUserList();
  }, [searchItem, selectedUserProfile, selectedAccount]);

  function handleAccountChange(val) {
    setSelectedUserProfile(null);
    setSelectedAccount(val.value ? val : null);
  }

  function handleUserProfileChange(val) {
    setSelectedAccount(null);
    setSelectedUserProfile(val.value ? val : null);
  }

  return (
    <div className="config-page-container">
      <div className="row">
        <div className="d-flex mb-3 col-4">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target.value)}
          />
        </div>{" "}
        <div className=" mb-3 col-4">
          <AutocompleteCompUncontrollable
            option={accountList}
            onChange={handleAccountChange}
            label="Account"
            disableClearable={true}
            showValue={true}
          />
        </div>
        <div className=" mb-3 col-4">
          <AutocompleteCompUncontrollable
            option={userProfileList}
            onChange={handleUserProfileChange}
            label="User Profile"
            disableClearable={true}
            showValue={true}
          />
        </div>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <FacilityTable data={userList} getUserList={fetchUserList} />
      )}
    </div>
  );
};

export default FacilityDetails;
