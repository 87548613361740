import React from "react";
import mainCss from "../../assets/css/mainCss.js";
import { useState } from "react";
import "../../assets/css/main.css";
import Box from "@mui/material/Box";
import { Switch } from "@mui/material";
import { useEffect } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  getUserSettingData,
  deleteUserSetting,
  updateUserSetting,
  getProfileDetailsByAccountId,
} from "../../redux/Action/ProfileAction";
import { useSelector } from "react-redux";

const UserSettings = (propTypes) => {
  // states of the component

  const [show, setShow] = useState(false);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  // update main user methods

  const handleActivate = (item) => {
    let updatedItem = { ...item };
    updatedItem.user__is_active = !item.user__is_active;
    updateUserSetting(updatedItem).then((res) => {
      if (res) {
        dispatch(getUserSettingData(propTypes.profile.account));
      }
    });
  };

  const handleDelete = (item) => {
    deleteUserSetting(item).then((res) => {
      if (res) {
        dispatch(getUserSettingData(propTypes.profile.account));
      }
    });
  };

  // user setting forms and modal methods

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    getProfileDetailsByAccountId(item).then((res) => {
      setFormState({
        id: res.data.data.id,
        name: res.data.data.name,
        alerts: res.data.data.alerts,
        dashboard: res.data.data.dashboard,
        device_control: res.data.data.device_control,
        device_status: res.data.data.device_status,
        events: res.data.data.events,
        heatmap: res.data.data.heatmap,
        live_view: res.data.data.live_view,
        remote_monitoring: res.data.data.remote_monitoring,
        offlineAlerts: res.data.data.offline_alerts,
        profile_type: res.data.data.profile_type,
      });
      setShow(true);
    });
  };

  const [formState, setFormState] = useState({
    id: null,
    alerts: null,
    dashboard: null,
    device_control: null,
    device_status: null,
    events: null,
    heatmap: null,
    live_view: null,
    remote_monitoring: null,
    offlineAlerts: null,
    profile_type: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState({ ...formState, [name]: checked });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    updateUserSetting(formState);
  };

  useEffect(() => {
    dispatch(getUserSettingData(propTypes.profile.account));
  }, []);

  return (
    <div className="profile-detail-form">
      <div
        style={{ margin: "20px", paddingBottom: "40px" }}
        className="col-11 table-responsive"
      >
        <table className="user-profile-setting-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Profile Type</th>
              <th>Group Name</th>
              <th>Activate</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.profile.userSettingData.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.user__email}</td>
                <td>{item.profile_type}</td>
                <td>{item.group_names}</td>
                <td>
                  <div className="form-check form-check-inline">
                    <Box>
                      {item.user__is_active ? (
                        <Switch
                          style={{ color: "#2D96EE" }}
                          defaultChecked
                          onChange={() => handleActivate(item)}
                        />
                      ) : (
                        <Switch
                          style={{ color: "#2D96EE" }}
                          onChange={() => handleActivate(item)}
                        />
                      )}
                    </Box>
                  </div>
                </td>
                <td>
                  <ModeEditOutlineOutlinedIcon
                    style={{ color: "#9A9AB0" }}
                    onClick={() => handleShow(item)}
                  />
                  &nbsp;&nbsp;
                  <DeleteIcon
                    style={{ color: "#9A9AB0" }}
                    onClick={() => handleDelete(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "var( --side-bar-bg-color)",
            border: "2px solid white",
          }}
        >
          <form onSubmit={handleSubmit}>
            <div className="custom-control custom-checkbox mb-3">
              <span
                className="user-update-form-setting"
                id="name-label"
                htmlFor="name-input"
              >
                Profile Type
              </span>
              &nbsp;
              <select
                id="profile-type-select"
                name="profile_type"
                value={formState.profile_type}
                onChange={handleInputChange}
              >
                <option value="head_office">Head Office</option>
                <option value="regional_admin">Regional Admin</option>
                <option value="zonal_admin">Zonal Admin</option>
                <option value="area_admin">Area Admin</option>
                <option value="store_admin">Store Admin</option>
              </select>
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="alerts-input"
                name="alerts"
                checked={formState.alerts}
                onChange={handleCheckboxChange}
              />
              &nbsp; &nbsp;
              <span className="user-update-form-setting" htmlFor="alerts-input">
                Alerts
              </span>
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="dashboard"
                name="dashboard"
                checked={formState.dashboard}
                onChange={handleCheckboxChange}
              />
              &nbsp; &nbsp;
              <span
                className="user-update-form-setting"
                htmlFor="dashboard-input"
              >
                Dashboard
              </span>
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="device_control"
                name="device_control"
                checked={formState.device_control}
                onChange={handleCheckboxChange}
              />
              &nbsp; &nbsp;
              <span
                className="user-update-form-setting"
                htmlFor="device_control"
              >
                Device Control
              </span>
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="device_status"
                name="device_status"
                checked={formState.device_status}
                onChange={handleCheckboxChange}
              />
              &nbsp; &nbsp;
              <span
                className="user-update-form-setting"
                htmlFor="device_status"
              >
                Device Status
              </span>
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="events"
                name="events"
                checked={formState.events}
                onChange={handleCheckboxChange}
              />
              &nbsp; &nbsp;
              <span className="user-update-form-setting" htmlFor="events">
                Events
              </span>
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="heatmap"
                name="heatmap"
                checked={formState.heatmap}
                onChange={handleCheckboxChange}
              />
              &nbsp; &nbsp;
              <span className="user-update-form-setting" htmlFor="heatmap">
                Heatmap
              </span>
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="live_view"
                name="live_view"
                checked={formState.live_view}
                onChange={handleCheckboxChange}
              />
              &nbsp; &nbsp;
              <span className="user-update-form-setting" htmlFor="live_view">
                Live View
              </span>
            </div>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="remote_monitoring"
                name="remote_monitoring"
                checked={formState.remote_monitoring}
                onChange={handleCheckboxChange}
              />
              &nbsp; &nbsp;
              <span
                className="user-update-form-setting"
                htmlFor="remote_monitoring"
              >
                Remote Monitoring
              </span>
            </div>

            <button type="submit" className="profile-submit-btn">
              <span>Update</span>
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserSettings;
