import React from "react";
import qrcode from "../../assets/images/QRCode.png";
const WhatsappQR = () => {
  return (
    <div>
      <center>
        <div className="qr-container">
          <img src={qrcode} />
          <h5 style={{ color: "grey", marginTop: "10px" }}>
            {" "}
            Scan this QR code for Whatsapp Login
          </h5>
        </div>
      </center>
    </div>
  );
};

export default WhatsappQR;
