import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useDispatch, useSelector } from "react-redux";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import SelectFieldComp from "../../../components/formLayout/SelectFieldComp";
const channelOption = [4, 8, 16, 32, 64, 128];
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import axios from "axios";
import { notifiToasterWarn } from "../../../components/common/UseToast";
import NvrTable from "./Tables/NvrTable";
import getDataFromAPI from "../Utils/getDataFromAPI";
import AutocompleteComp from "../../../components/common/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

function AddNvrDvr(propTypes) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [channelCapacity, setChannelCapacity] = useState(4);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [brand, setBrand] = useState("");
  const [ipadd, setIpadd] = useState("");
  const [cameraConnected, setCameraConnected] = useState(0);
  const [httpsPort, setHttpsPort] = useState(0);
  const [channelUnused, setChannelUnused] = useState("");
  const [httpPort, setHttpPort] = useState(0);
  const [rtspPort, setRtspPort] = useState(0);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [nvrData, setNvrData] = useState({});
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    name: "",
  });
  const [disableAddAnother, setDisableAddAnother] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedPort, setSelectedPort] = useState({
    label: "HTTP",
  });
  const prevNVRstate = useSelector((state) => state.onboarding);
  const [state, setState] = React.useState({
    cloudEnable: true,
  });

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  function handleChannelChange(val) {
    setChannelCapacity(val);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Reset the specific error when the input changes
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
    if (name === "name") setName(value);
    if (name === "cameraConnected") setCameraConnected(value);
    if (name === "channelUnused") setChannelUnused(value);
    if (name === "brand") setBrand(value);
    if (name === "httpPort") setHttpPort(value);
    if (name === "httpsPort") setHttpsPort(value);
    if (name === "rtspPort") setRtspPort(value);
    if (name === "ipadd") setIpadd(value);
  };
  function getChannelUsedData(channel_unused) {
    var channel = "";
    if (channel_unused && channel_unused.length > 0) {
      channel_unused.map((item) => {
        channel = channel + "," + item;
      });
    }
    return channel;
  }
  function filterfacilityById(inputArray, id) {
    if (inputArray && id && inputArray.length > 0) {
      const filteredArray = inputArray.filter((obj) => obj.value === id);

      return filteredArray[0];
    }
  }
  useEffect(() => {
    if (propTypes.id != -1) {
      if (nvrData) {
        setUsername(nvrData.username);
        setBrand(nvrData.brand);
        setName(nvrData.name);
        setHttpPort(nvrData.http_port_number ? nvrData.http_port_number : 0);
        setHttpsPort(nvrData.https_port_number ? nvrData.https_port_number : 0);
        setChannelCapacity(Number(nvrData.channel_capacity));
        setRtspPort(nvrData.rtsp_port);
        setPassword(nvrData.password);
        setIpadd(nvrData.ip_address);
        setCameraConnected(nvrData.cameras_connected);
        setChannelUnused(getChannelUsedData(nvrData.channels_unused));
        setState({ ...state, cloudEnable: nvrData.cloud_monitoring_enabled });
        if (facilitiesList && nvrData) {
          setSelectedFacility(
            filterfacilityById(facilitiesList, nvrData.installed_at)
          );
        }
      }
    }
  }, [nvrData, facilitiesList]);
  const fetchDataById = async () => {
    const tempNvrData = await getDataFromAPI.getNVRDetailsByid(propTypes.id);
    setNvrData(tempNvrData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = {
      username: "",
      password: "",
      name: "",
      channelUnused: "",
      ipadd: "",
      brand: "",
      rtspPort: "",
    };

    // Username validation
    if (username.length < 1) {
      valid = false;
      newErrors.username = "Username must be at least 3 characters long";
    }

    if (name.length < 1) {
      valid = false;
      newErrors.name = "Name must be at least 3 characters long";
    }
    // Password validation
    if (password.length < 1) {
      valid = false;
      newErrors.password = "Password must be at least 3 characters long";
    }
    if (brand.length < 3) {
      valid = false;
      newErrors.brand = "Brand Name must be at least 3 characters long";
    }

    if (!selectedFacility) {
      valid = false;
      notifiToasterWarn("Please Select Facility");
    }
    if (!channelCapacity) {
      valid = false;
      notifiToasterWarn("Please select channel capacity");
    }
    if (rtspPort == 0 || !rtspPort) {
      valid = false;
      newErrors.rtspPort = "Enter rtsp port";
    }

    if (channelUnused && channelUnused.length > 0) {
      const pattern = /^(\d+(,\d+)*)?$/;
      if (!pattern.test(channelUnused)) {
        valid = false;
        newErrors.channelUnused =
          "Only Comma-separated numbers are allowed, e.g., 201,205";
      }
    }
    const port = selectedPort.label === "HTTP" ? httpPort : httpsPort;
    if (port == "" || port == 0 || !port) {
      valid = false;
      notifiToasterWarn(`Please Select ${selectedPort.label} Port`);
    }
    if (rtspPort == 0) {
      valid = false;
      notifiToasterWarn("Please Select RTSP Port");
    }
    if (valid) {
      // Handle form submission, e.g., send data to the server
      const userData = {
        name: name,
        brand: brand,
        ip_address: ipadd,
        channel_capacity: channelCapacity,
        cameras_connected: cameraConnected,
        channels_unused:
          channelUnused.length > 0 ? channelUnused.split(",") : [],
        username: username,
        password: password,
        rtsp_port: rtspPort,
        cloud_monitoring_enabled: state.cloudEnable,
        installed_at: selectedFacility.value,
      };
      if (selectedPort.label == "HTTPS") {
        userData.https_port_number = httpsPort;
      } else {
        userData.http_port_number = httpPort;
      }
      setLoading(true);
      if (propTypes.isEditable) {
        dispatch(OnboardingAction.updateNVR(userData, propTypes.id))
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        dispatch(OnboardingAction.createNVR(userData))
          .then(() => {
            setDisableAddAnother(false);
            setLoading(false);
            resetForm();
          })
          .catch(() => {
            setLoading(false);
          });
      }
    } else {
      // Update the error state with validation errors
      setErrors(newErrors);
    }
  };
  const resetAllStates = () => {
    setUsername("");
    setPassword("");
    setName("");
    setChannelCapacity(4);
    setBrand("");
    setIpadd("");
    setCameraConnected(0);
    setHttpsPort(0);
    setChannelUnused("");
    setHttpPort(0);
    setRtspPort(0);
    setSelectedFacility(selectedFacility);
    setNvrData({});
  };

  function getAllFacilitiesList() {
    var token = localStorage.getItem("token");
    axios
      .get(OperationDashEndpoints.getAllFacilities(), {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        const facilitiesData = response.data.map((item) => ({
          label: item.facility_name,
          value: item.id,
        }));
        setFacilitiesList(facilitiesData);
      });
  }
  function handleFacilityChangeControlled(val) {
    if (val) {
      setSelectedFacility(val);
    }
  }
  function handleFacilityChange(val) {
    if (val) {
      setSelectedFacility(val);
    }
  }
  function handleSelectPort(e, val) {
    setSelectedPort(val);
  }
  useEffect(() => {
    getAllFacilitiesList();
    if (propTypes.id && propTypes.id != -1) {
      fetchDataById();
    }
  }, [propTypes.id]);
  const resetForm = () => {
    setUsername("");
    setPassword("");
    setName("");
    setChannelCapacity(4);
    setBrand("");
    setIpadd("");
    setCameraConnected(0);
    setHttpsPort(0);
    setChannelUnused("");
    setHttpPort(0);
    setRtspPort(0);
    setSelectedFacility(selectedFacility);
    setErrors({
      username: "",
      password: "",
      confirmPassword: "",
      name: "",
      email: "",
      channelUnused: "",
      ipadd: "",
      brand: "",
    });
    setSelectedPort({ label: "HTTP" });
    setDisableAddAnother(true);
  };
  return (
    <div className="mt-4">
      {!propTypes.isEditable && <h2 className="mt-2 mb-4">Create NVR/DVR</h2>}
      <form onSubmit={handleSubmit} className="row">
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="name"
            label="NVR/DVR Name"
            variant="outlined"
            fullWidth
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
          />
        </div>

        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="username"
            label="NVR/DVR Username"
            variant="outlined"
            fullWidth
            type="text"
            name="username"
            value={username}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="password"
            label="NVR/DVR Password"
            variant="outlined"
            fullWidth
            name="password"
            type="text"
            value={password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          {propTypes.isEditable ? (
            <AutocompleteComp
              option={facilitiesList}
              onChange={handleFacilityChangeControlled}
              value={selectedFacility}
              label="facility"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={
                facilitiesList && facilitiesList.length > 0
                  ? facilitiesList
                  : []
              }
              onChange={handleFacilityChange}
              label={"Facility"}
            />
          )}
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="brand"
            label="NVR Brand"
            variant="outlined"
            fullWidth
            type="text"
            name="brand"
            value={brand}
            onChange={handleChange}
            error={Boolean(errors.brand)}
            helperText={errors.brand}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="ipadd"
            label="IP Address"
            variant="outlined"
            fullWidth
            type="text"
            name="ipadd"
            value={ipadd}
            onChange={handleChange}
            error={Boolean(errors.ipadd)}
            helperText={errors.ipadd}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <SelectFieldComp
            options={channelOption}
            value={isNaN(channelCapacity) ? "" : channelCapacity}
            onChange={handleChannelChange}
            label="Channel Capacity"
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="cameraConnected"
            label="Cameras Connected"
            variant="outlined"
            fullWidth
            name="cameraConnected"
            type="number"
            value={cameraConnected}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="channelUnused"
            label="Channel Unused"
            variant="outlined"
            fullWidth
            name="channelUnused"
            type="text"
            value={channelUnused}
            onChange={handleChange}
            error={Boolean(errors.channelUnused)}
            helperText={errors.channelUnused}
          />
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <AutocompleteComp
            option={[
              {
                label: "HTTPS",
              },
              {
                label: "HTTP",
              },
            ]}
            onChange={handleSelectPort}
            value={selectedPort}
            label="Select Port Type"
            showValue={false}
          />
        </div>
        {selectedPort.label == "HTTPS" ? (
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="httpsPort"
              label="HTTPS Port Number"
              variant="outlined"
              fullWidth
              name="httpsPort"
              type="number"
              value={httpsPort}
              onChange={handleChange}
            />
          </div>
        ) : (
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="httpPort"
              label="HTTP Port Number"
              variant="outlined"
              fullWidth
              name="httpPort"
              type="number"
              value={httpPort}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="rtspPort"
            label="RTSP Port Number"
            variant="outlined"
            fullWidth
            name="rtspPort"
            type="number"
            value={rtspPort}
            onChange={handleChange}
            error={Boolean(errors.rtspPort)}
            helperText={errors.rtspPort}
            required
          />
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={state.cloudEnable}
              onChange={handleChangeCheckbox}
              name="cloudEnable"
              color="primary"
            />
          }
          label="Enable Cloud Monitoring"
        />
        <FormHelperText>
          Select this if you want to add NVR in RHMS.
        </FormHelperText>

        <div className="col-4 mt-3">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!disableAddAnother || loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : propTypes.isEditable ? (
              "Update NVR"
            ) : (
              "Add NVR"
            )}
          </Button>
        </div>
      </form>
      <div className="mt-4">
        {!propTypes.isEditable && (
          <NvrTable
            data={prevNVRstate.newAddedNVR}
            getUserList={() => console.log("")}
            showButtonAboveTable={
              propTypes.usedIn == "onboarding" ? false : true
            }
          />
        )}
      </div>
    </div>
  );
}

export default AddNvrDvr;
