import React from "react";
// import AddAccount from "../../Onboarding/Steps/AddAccount";
import CreateUser from "../../Onboarding/Steps/CreateUser";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Userprofile from "../../Onboarding/Steps/Userprofile";
// import AddNvrDvr from "../AddNvrDvr";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function EditUserProfileModal(propTypes) {
  const [open, setOpen] = React.useState(propTypes.open);
  React.useEffect(() => {
    setOpen(propTypes.open);
  }, [propTypes]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    propTypes.handleClose();
    setOpen(false);
    propTypes.getUserList();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {propTypes.addNew ? "Add User Profile" : "Edit User Profile"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {propTypes.addNew ? (
            <Userprofile isEditable={false} id={null} />
          ) : (
            <Userprofile isEditable={true} id={propTypes.id} />
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
