import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AutocompleteCompUncontrollable from "../../components/common/AutoCompleteUncontrollable";
import { useDispatch } from "react-redux";
import AutocompleteComp from "../../components/common/Autocomplete";
import getDataFromAPI from "../Onboarding/Utils/getDataFromAPI";
import FleetDashboardAPI from "./fleetDashAPIs";
import PropTypes from "prop-types";
import Textfield from "../../components/formLayout/TextField";
import FleetDashActions from "../../redux/Action/FleetDashActions";
import MultiSelectFreeSolo from "../../components/formLayout/MultiSelectFreeSolo";
import { Oval } from "react-loader-spinner";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import OnboardingAction from "../../redux/Action/OnboardingAction";

const HARDWARE_TYPE = [
  { value: "1", label: "ANALYTICS_BOX_CAMERA_2GB" },
  { value: "2", label: "ANALYTICS_BOX_CAMERA_4GB" },
  { value: "3", label: "ANALYTICS_BOX_SMALL" },
  { value: "4", label: "ANALYTICS_BOX_MEDIUM" },
  { value: "5", label: "ANALYTICS_BOX_LARGE" },
];
const channelOption = [
  {
    label: "Agrex",
    value: 1,
  },
  {
    label: "VC Tech",
    value: 2,
  },
  {
    label: "Spectra",
    value: 3,
  },
];

const featureList = [
  {
    label: "People_Count",
    value: "1",
  },
  {
    label: "Heatmap",
    value: "2",
  },
  {
    label: "Demographics",
    value: "3",
  },
  {
    label: "Passer_By",
    value: "4",
  },
  {
    label: "Logistic",
    value: "5",
  },
  {
    label: "Remote_Health",
    value: "6",
  },
  {
    label: "All",
    value: "7",
  },
];
function formatCurrentDate() {
  const now = new Date(Date.now());
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
const initialState = {
  deviceName: "",
  selectedFacility: null,
  selectedDevice: null,
  selectedChannel: null,
  typeOfHardware: null,
  typeOfFeature: null,
  notes: "",
  dateOfInstallation: "",
  username: "",
  password: "",
  macId: "",
  openingTime: "",
  closingTime: "",
  enableMonitoring: false,
};
const AddDeviceForm = ({ isEditable, handleModal, deviceId }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [deviceData, setDeviceData] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [show, setIsShow] = useState(isEditable);
  const [DeviceListData, setDeviceListData] = useState([]);
  const [deviceEmails, setDeviceEmails] = useState([]);
  const [completeDeviceData, setCompleteDeviceData] = useState([]);
  const [facilities, setFacilityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      const [devicesData, facilityData] = await Promise.all([
        FleetDashboardAPI.getAllDevicesFromIOt(),
        getDataFromAPI.getAllFacility(),
      ]);

      const deviceListData = devicesData.map((item) => ({
        label: item.Name,
        value: item.UnitId,
      }));

      setCompleteDeviceData(devicesData);
      setFacilityList(facilityData);
      setDeviceListData(deviceListData);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDeviceDetails = async (deviceId) => {
    try {
      setLoading(true);
      const response = await FleetDashboardAPI.getDeviceDetails(deviceId);
      setDeviceData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    if (deviceId && isEditable) {
      fetchDeviceDetails(deviceId);
    }
  }, [deviceId]);
  function getTypeOfItem(list, key) {
    const ans = list.filter((item) => item.label.toLowerCase() == key);
    if (ans) {
      return ans[0];
    } else return {};
  }
  useEffect(() => {
    if (!deviceData && facilities.length == 0) return;
    let fleetDevice = getDataFromAPI.getSelectedItem(
      deviceData.remoteiot_unit_id,
      DeviceListData
    );
    setFormData({
      deviceName: deviceData.device_name,
      remoteIotUnit: deviceData.remoteiot_unit_id,
      macId: deviceData.mac_id,
      ip: deviceData.ip,
      password: deviceData.password,
      username: deviceData.username,
      notes: deviceData.notes,
      selectedFacility: getDataFromAPI.getSelectedItem(
        deviceData.facility_installed_at,
        facilities
      ),
      typeOfFeature: getTypeOfItem(featureList, deviceData.type_of_feature),
      typeOfHardware: getTypeOfItem(HARDWARE_TYPE, deviceData.type_of_hardware),
      dateOfInstallation: deviceData.date_of_installation,
      selectedDevice: fleetDevice,
      selectedChannel: getDataFromAPI.getSelectedItem(
        deviceData.channel,
        channelOption
      ),
      openingTime: deviceData.opening_time || "",
      closingTime: deviceData.closing_time || "",
      enableMonitoring: deviceData.enable_monitoring || false,
    });
    setDeviceEmails(deviceData.email_list ?? []);
  }, [deviceData, facilities]);
  function filterSelectedDevice(id) {
    const filteredData = completeDeviceData.filter(
      (item) => item.UnitId === id
    );
    return filteredData[0];
  }

  const handleFacilityChange = (val) => {
    setFormData({ ...formData, selectedFacility: val });
  };
  const handleFacilityChangeControl = (e, val) => {
    setFormData({ ...formData, selectedFacility: val });
  };
  const handleDeviceChange = (val) => {
    setFormData({ ...formData, selectedDevice: val });
  };
  const handleDeviceChangeControlled = (e, val) => {
    setFormData({ ...formData, selectedDevice: val });
  };
  const handleChannelChangeControlled = (e, val) => {
    setFormData({ ...formData, selectedChannel: val });
  };
  const handleChannelChange = (val) => {
    setFormData({ ...formData, selectedChannel: val });
  };
  const handleHardwareChange = (value) => {
    setFormData({ ...formData, typeOfHardware: value });
  };
  const handleHardwareChangeControlled = (e, value) => {
    setFormData({ ...formData, typeOfHardware: value });
  };

  const handleFeatureChange = (value) => {
    setFormData({ ...formData, typeOfFeature: value });
  };
  const handleFeatureChangeControlled = (e, value) => {
    setFormData({ ...formData, typeOfFeature: value });
  };
  const handleClickOpen = () => {
    setIsShow(true);
  };

  const handleClose = () => {
    setIsShow(false);
    handleModal(false);
  };
  const handleDeviceEmailChange = (newValue) => {
    setDeviceEmails(newValue);
  };
  const handleSubmit = async () => {
    if (
      !formData.deviceName ||
      !formData.typeOfFeature ||
      !formData.typeOfFeature ||
      !formData.selectedChannel ||
      !formData.selectedFacility ||
      !formData.selectedDevice ||
      !formData.openingTime ||
      !formData.closingTime
    ) {
      setErrors({
        deviceName: !formData.deviceName ? "Device Name is required" : "",
        selectedChannel: !formData.selectedChannel
          ? "Channel Name is required"
          : "",
        selectedFacility: !formData.selectedFacility
          ? "Facility is required"
          : "",
        selectedDevice: !formData.selectedDevice ? "Device is required" : "",
        typeOfHardware: !formData.typeOfHardware
          ? "Hardware type is required"
          : "",
        typeOfFeature: !formData.typeOfFeature
          ? "Feature type is required"
          : "",
        openingTime: !formData.openingTime ? "Opening time is required" : "",
        closingTime: !formData.closingTime ? "Closing time is required" : "",
      });
    } else {
      setErrors({});
      try {
        let filter_data = filterSelectedDevice(formData.selectedDevice.value);
        const deviceData = {
          device_name: formData.deviceName,
          facility_installed_at: formData.selectedFacility.value,
          remoteiot_unit_id: formData.selectedDevice.value,
          internal_ip: filter_data.Internal_ip,
          external_ip: filter_data.External_ip,
          description: "abc",
          mac_id: formData.macId,
          username: formData.username,
          password: formData.password,
          notes: formData.notes,
          type_of_feature: formData.typeOfFeature.label.toLowerCase(),
          type_of_hardware: formData.typeOfHardware.label.toLowerCase(),
          date_of_installation: formData.dateOfInstallation,
          device_type: "premises",
          email_list: deviceEmails,
          fleet_name: formData.selectedDevice.label,
          channel: formData.selectedChannel.value,
          lastonlinestatus: formatCurrentDate(),

          enable_monitoring: formData.enableMonitoring,
        };
        if (isEditable) {
          await FleetDashActions.updateDeviceToFleetDash(deviceId, deviceData);
        } else {
          await FleetDashActions.addDeviceToFleetDash(deviceData);
        }
        dispatch(
          OnboardingAction.updateFacility(formData.selectedFacility.value, {
            opening_time: formData.openingTime,
            closing_time: formData.closingTime,
          })
        );
      } catch (error) {
        console.error("Error adding device:", error);
      }
    }
  };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <div>
      {!isEditable && (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ backgroundColor: "#4318FF" }}
        >
          Add device
        </Button>
      )}
      <Dialog onClose={handleClose} open={show} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {isEditable ? "Update Device" : "Add Device"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <Oval
              height={60}
              width={60}
              color="black"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="black"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            <DialogContent dividers>
              <div className="row">
                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  {isEditable && formData.selectedDevice ? (
                    <AutocompleteComp
                      option={DeviceListData}
                      onChange={handleDeviceChangeControlled}
                      value={formData.selectedDevice}
                      label="Device"
                      showValue={true}
                      error={Boolean(errors.selectedDevice)}
                      helperText={errors.selectedDevice}
                    />
                  ) : (
                    <AutocompleteCompUncontrollable
                      option={DeviceListData}
                      onChange={handleDeviceChange}
                      label="Device"
                      disableClearable={true}
                      showValue={true}
                      error={Boolean(errors.selectedDevice)}
                      helperText={errors.selectedDevice}
                    />
                  )}
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  <TextField
                    id="deviceName"
                    label="Device Name for Dashboard"
                    variant="outlined"
                    fullWidth
                    name="deviceName"
                    type="text"
                    value={formData.deviceName}
                    onChange={handleChange}
                    error={Boolean(errors.deviceName)}
                    helperText={errors.deviceName}
                    required
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  <Textfield
                    label="User Name"
                    value={formData.username}
                    onChange={handleChange}
                    type="text"
                    placeholder="User Name"
                    name="username"
                    isrequired={false}
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  <Textfield
                    label="Password"
                    value={formData.password}
                    onChange={handleChange}
                    type="password"
                    placeholder="Password"
                    name="password"
                    isrequired={false}
                  />
                  <span className="error-form-fleet">{errors.password}</span>
                </div>
                <div className="form-group col-12 col-md-6 col-lg-4 mb-3">
                  {isEditable && formData.selectedFacility ? (
                    <AutocompleteComp
                      option={facilities}
                      onChange={handleFacilityChangeControl}
                      value={formData.selectedFacility}
                      label="Facility"
                      showValue={true}
                    />
                  ) : (
                    <AutocompleteCompUncontrollable
                      disableClearable={true}
                      showValue={true}
                      option={facilities}
                      onChange={handleFacilityChange}
                      label="Facility"
                    />
                  )}
                  <span className="error-form-fleet">
                    {errors.selectedFacility}
                  </span>
                </div>

                <div className="form-group col-12 col-md-6 col-lg-4 mb-3">
                  {isEditable && formData.typeOfHardware ? (
                    <AutocompleteComp
                      option={channelOption}
                      onChange={handleChannelChangeControlled}
                      value={formData.selectedChannel}
                      label="Channel"
                      showValue={true}
                    />
                  ) : (
                    <AutocompleteCompUncontrollable
                      disableClearable={true}
                      showValue={true}
                      option={channelOption}
                      onChange={handleChannelChange}
                      label="Channel"
                    />
                  )}
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  {isEditable && formData.typeOfHardware ? (
                    <AutocompleteComp
                      option={HARDWARE_TYPE}
                      onChange={handleHardwareChangeControlled}
                      value={formData.typeOfHardware}
                      label="Device"
                      showValue={true}
                    />
                  ) : (
                    <AutocompleteCompUncontrollable
                      disableClearable={true}
                      showValue={true}
                      option={HARDWARE_TYPE}
                      onChange={handleHardwareChange}
                      name="typeOfHardware"
                      label="Type of Hardware:"
                    />
                  )}
                  <span className="error-form-fleet">
                    {errors.typeOfHardware}
                  </span>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  {isEditable && formData.typeOfFeature ? (
                    <AutocompleteComp
                      option={featureList}
                      onChange={handleFeatureChangeControlled}
                      value={formData.typeOfFeature}
                      label="Type of Feature:"
                      showValue={true}
                    />
                  ) : (
                    <AutocompleteCompUncontrollable
                      disableClearable={true}
                      showValue={true}
                      option={featureList}
                      onChange={handleFeatureChange}
                      name="typeOfFeature"
                      label="Type of Feature:"
                    />
                  )}
                  <span className="error-form-fleet">
                    {errors.typeOfFeature}
                  </span>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  <TextField
                    label="Notes"
                    fullWidth
                    value={formData.notes}
                    onChange={handleChange}
                    type="text"
                    placeholder="Notes"
                    name="notes"
                    required
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  <TextField
                    label="Date of Installation"
                    value={formData.dateOfInstallation}
                    fullWidth
                    onChange={handleChange}
                    type="date"
                    placeholder="Date of Installation"
                    name="dateOfInstallation"
                    required
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  <TextField
                    label="Opening Time"
                    value={formData.openingTime}
                    fullWidth
                    onChange={handleChange}
                    type="time"
                    name="openingTime"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    helperText="Enter the opening time of the facility"
                    error={!!errors.openingTime}
                  />
                  <span className="error-form-fleet">{errors.openingTime}</span>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
                  <TextField
                    label="Closing Time"
                    value={formData.closingTime}
                    fullWidth
                    onChange={handleChange}
                    type="time"
                    name="closingTime"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    helperText="Enter the closing time of the facility"
                    error={!!errors.closingTime}
                  />
                  <span className="error-form-fleet">{errors.closingTime}</span>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-3 mb-3">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.enableMonitoring}
                        onChange={handleChange}
                        name="enableMonitoring"
                        color="primary"
                      />
                    }
                    label="Enable Monitoring"
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-9 mb-3">
                  <MultiSelectFreeSolo
                    value={deviceEmails}
                    onChangeFunc={handleDeviceEmailChange}
                    label={"Emails for notification"}
                    placeholder={"add new Email"}
                  />
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              {isEditable ? (
                <Button autoFocus onClick={handleSubmit}>
                  Update Device
                </Button>
              ) : (
                <Button autoFocus onClick={handleSubmit}>
                  Add Device
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};
AddDeviceForm.propTypes = {
  handleModal: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  deviceId: PropTypes.number.isRequired,
};
export default AddDeviceForm;
