import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import { Bars } from "react-loader-spinner";
import NvrTable from "../../Onboarding/Steps/Tables/NvrTable";

const NvrDetails = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [facilityList, setFacilityList] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const fetchData = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      let endpoint = null;

      if (searchItem.length === 0 && !selectedAccount && !selectedFacility) {
        endpoint = OperationDashEndpoints.createNVR();
      } else if (searchItem.length > 2) {
        endpoint = OperationDashEndpoints.seachNVRFromAll(searchItem);
      } else if (selectedAccount && selectedAccount.value) {
        endpoint = OperationDashEndpoints.getNVRByAccount(
          selectedAccount.value
        );
      } else if (selectedFacility && selectedFacility.value) {
        endpoint = OperationDashEndpoints.getNVRByFacility(
          selectedFacility.value
        );
      }

      if (endpoint) {
        const response = await axios.get(endpoint, {
          headers: { Authorization: `Token ${token}` },
        });

        setUserList(
          endpoint === OperationDashEndpoints.createNVR() ||
            endpoint === OperationDashEndpoints.seachNVRFromAll(searchItem)
            ? response.data
            : response.data.data
        );
      } else {
        setUserList([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataForDropDown = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");

      const facilityResponse = await axios.get(
        OperationDashEndpoints.getAllFacilities(),
        { headers: { Authorization: `Token ${token}` } }
      );

      const accountData = [
        { label: "All", value: 0 },
        ...facilityResponse.data.map((item) => ({
          label: item.facility_name,
          value: item.id,
        })),
      ];

      setFacilityList(accountData);

      const accountResponse = await axios.get(
        OperationDashEndpoints.createAccount(),
        { headers: { Authorization: `Token ${token}` } }
      );

      const accountData1 = [
        { label: "All", value: 0 },
        ...accountResponse.data.map((item) => ({
          label: item.name,
          value: item.account_id,
        })),
      ];

      setAccountList(accountData1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function handleFacilitychange(val) {
    setSelectedAccount(null);
    setSelectedFacility(val.value ? val : null);
  }
  function handleAccountChange(val) {
    setSelectedFacility(null);
    setSelectedAccount(val.value ? val : null);
  }
  useEffect(() => {
    fetchData();
    fetchDataForDropDown();
    dispatch(changeTopbarTitle("Admin/NVRS"));
  }, []);
  useEffect(() => {
    fetchData();
  }, [searchItem, selectedAccount, selectedFacility]);

  return (
    <div className="config-page-container">
      <div className="row">
        <div className="d-flex mb-3 col-4">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search NVR"
            aria-label="Search"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target.value)}
          />
        </div>
        <div className=" mb-3 col-4">
          <AutocompleteCompUncontrollable
            option={accountList}
            onChange={handleAccountChange}
            label="Account"
            disableClearable={true}
            showValue={true}
          />
        </div>
        <div className=" mb-3 col-4">
          <AutocompleteCompUncontrollable
            option={facilityList}
            onChange={handleFacilitychange}
            label="Facility"
            disableClearable={true}
            showValue={true}
          />
        </div>
      </div>
      {!loading ? (
        <NvrTable data={userList} getUserList={fetchData} loading={loading} />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </div>
  );
};

export default NvrDetails;
