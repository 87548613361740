import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import mainCss from "../../assets/css/mainCss";
import "../../assets/css/Login.css";
import ProfileTopCard from "./ProfileTopCard";
import ProfileDetails from "./ProfileDetails";
import UserSettings from "./UserSettings";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { getNewUserDetails } from "../../redux/Action/Login";
import WhatsappQR from "./whatsappQR";
import { getProfile } from "../../redux/Action/ProfileAction";
const Profile = () => {
  const [option, setOption] = useState(1);

  const dispatch = useDispatch();

  var profile = JSON.parse(localStorage.getItem("profile"));
  useEffect(() => {
    setTitile();
    getNewUserDetails();
    dispatch(getProfile());
  }, []);

  const setTitile = () => {
    dispatch(changeTopbarTitle("Profile"));
  };
  return (
    <div>
      <div style={mainCss.divBelowTopbar}>
        <div>
          <ProfileTopCard profile={profile} />
        </div>
      </div>
      <div className="profile-options-container  mt-2">
        <div className="profile-options-list d-flex">
          <div
            className={
              option == 1
                ? "profile-option-div profile-option-div-active"
                : "profile-option-div"
            }
            onClick={() => {
              setOption(1);
            }}
          >
            Profile Details
          </div>
          <div
            className={
              option == 2
                ? "profile-option-div profile-option-div-active"
                : "profile-option-div"
            }
            onClick={() => {
              setOption(2);
            }}
          >
            User Setting
          </div>
          <div
            className={
              option == 3
                ? "profile-option-div profile-option-div-active"
                : "profile-option-div"
            }
            onClick={() => {
              setOption(3);
            }}
          >
            Whatsapp QR
          </div>
        </div>
      </div>
      <div>
        {option == 1 ? (
          <div className="profile-dropdown-div">
            <ProfileDetails profile={profile} />
          </div>
        ) : null}
        {option == 2 ? (
          <div className="profile-dropdown-div">
            <UserSettings profile={profile} />
          </div>
        ) : null}
        {option == 3 ? (
          <div className="profile-dropdown-div">
            <WhatsappQR />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Profile;
