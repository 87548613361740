const initialState = {
  storeList: [{ text: "All", value: "all" }],
  clientList: [{ text: "All", value: "all" }],
  deviceList: [{ text: "All", value: "all" }],
  cameraList: [{ text: "All", value: "all" }],
  ipCamerasList: [],
  tenStoreList: [],
  appTheme: false,
  toggleSidebar: false,
  toggleVideoWallSidebar: false,
  alerteventDate: new Date().toISOString(),
  dateRange: {
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    key: "selection",
  },
  muteNotification: localStorage.getItem("muteNotification")
    ? localStorage.getItem("muteNotification") === "true"
    : false,
  pageSize: localStorage.getItem("pageSize")
    ? parseInt(localStorage.getItem("pageSize"))
    : 12,
  snackBarText: "",
  agrexTheme: localStorage.getItem("myapp_theme") ?? "light",
};

export default function CommonReducer(state = initialState, action) {
  switch (action.type) {
    case "STORE_LIST_DATA":
      return {
        ...state,
        storeList: action.payload,
        tenStoreList: action.payload,
      };
    case "CLIENT_LIST_DATA":
      return {
        ...state,
        clientList: action.payload,
      };
    case "DIVICES_LIST_DATA":
      return { ...state, deviceList: action.payload };
    case "CAMERAS_LIST_DATA":
      return { ...state, cameraList: action.payload };
    case "IP_CAMERAS_LIST":
      return { ...state, ipCamerasList: action.payload };
    case "UPDATE_THEME":
      return { ...state, appTheme: action.payload };
    case "TOGGLE_SIDEBAR":
      return { ...state, toggleSidebar: action.payload };
    case "UPDATE_DATE":
      return { ...state, alerteventDate: action.payload };
    case "UPDATE_DATE_RANGE":
      return { ...state, dateRange: action.payload };
    case "TOGGLE_VIDEO_SIDEBAR":
      return { ...state, toggleVideoWallSidebar: action.payload };
    case "TOGGLE_MUTE_NOTIFICATION":
      return { ...state, muteNotification: action.payload };
    case "UPDATE_PAGE_SIZE":
      return { ...state, pageSize: action.payload };
    case "UPDATE_SNACKBAR_TEXT":
      return { ...state, snackBarText: action.payload };
    case "UPDATE_AGREX_THEME":
      return { ...state, agrexTheme: action.payload };
    default:
      return state;
  }
}
