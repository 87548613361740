import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import ClientOnboardingActions from "../../redux/Action/ClientOnboarding";
import PropTypes from "prop-types";
const brandOption = [
  { label: "Hikvision/Ezviz", value: "hikvision" },
  { label: "CP PLUS", value: "cpplus" },
];
import { notifiToasterWarn } from "../../components/common/UseToast";
import AutocompleteComp from "../../components/common/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

function OnboardStandAloneCameras({ isEditable }) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [brand, setBrand] = useState(brandOption[0]);
  const [ipadd, setIpadd] = useState("");
  const [httpsPort, setHttpsPort] = useState(0);
  const [channelUnused, setChannelUnused] = useState("");
  const [httpPort, setHttpPort] = useState(0);
  const [rtspPort, setRtspPort] = useState(0);
  const [storedNvrId, setStoredNvrId] = useState(null);
  const activeStep = useSelector((state) => state.clientOnboard.currentStep);
  const [isSaveAndAddAnother, setIsSaveAndAddAnother] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    name: "",
  });
  const [disableAddAnother, setDisableAddAnother] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedPort, setSelectedPort] = useState({
    label: "HTTP",
  });
  useEffect(() => {
    if (isEditable) {
      let storedNvr = JSON.parse(localStorage.getItem("onboardNvr"));
      if (storedNvr && storedNvr.type == "standalone") {
        setName(storedNvr.name);
        setUsername(storedNvr.username);
        setPassword(storedNvr.password);
        setBrand(storedNvr.brand);
        setIpadd(storedNvr.ip_address);
        setHttpPort(storedNvr.http_port_number);
        setHttpsPort(storedNvr.httpsPort);
        setRtspPort(storedNvr.rtsp_port);
        setStoredNvrId(storedNvr.id);
      }
    }
  }, [isEditable]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Reset the specific error when the input changes
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
    if (name === "name") setName(value);
    if (name === "channelUnused") setChannelUnused(value);
    if (name === "brand") setBrand(value);
    if (name === "httpPort") setHttpPort(value);
    if (name === "httpsPort") setHttpsPort(value);
    if (name === "rtspPort") setRtspPort(value);
    if (name === "ipadd") setIpadd(value);
  };

  const handleSubmit = async (isAddAnother) => {
    let valid = true;
    const newErrors = {
      username: "",
      password: "",
      name: "",
      channelUnused: "",
      ipadd: "",
      brand: "",
      rtspPort: "",
    };

    // Username validation
    if (username.length < 1) {
      valid = false;
      newErrors.username = "Username must be at least 3 characters long";
    }

    if (name.length < 1) {
      valid = false;
      newErrors.name = "Name must be at least 3 characters long";
    }
    // Password validation
    if (password.length < 1) {
      valid = false;
      newErrors.password = "Password must be at least 3 characters long";
    }
    if (brand.length < 3) {
      valid = false;
      newErrors.brand = "Brand Name must be at least 3 characters long";
    }

    if (rtspPort == 0 || !rtspPort) {
      valid = false;
      newErrors.rtspPort = "Enter rtsp port";
    }

    if (channelUnused && channelUnused.length > 0) {
      const pattern = /^(\d+(,\d+)*)?$/;
      if (!pattern.test(channelUnused)) {
        valid = false;
        newErrors.channelUnused =
          "Only Comma-separated numbers are allowed, e.g., 201,205";
      }
    }
    const port = selectedPort.label === "HTTP" ? httpPort : httpsPort;
    if (port == "" || port == 0 || !port) {
      valid = false;
      notifiToasterWarn(`Please Select ${selectedPort.label} Port`);
    }
    if (rtspPort == 0) {
      valid = false;
      notifiToasterWarn("Please Select RTSP Port");
    }

    if (valid) {
      const nvrData = {
        name: name,
        brand: brand.value,
        ip_address: ipadd,
        channel_capacity: 4,
        cameras_connected: 1,
        channels_unused: [201, 202, 203],
        username: username,
        password: password,
        rtsp_port: rtspPort,
        installed_at: JSON.parse(localStorage.getItem("onboardFacility"))
          .facility_id,
        facility_id: JSON.parse(localStorage.getItem("onboardFacility"))
          .facility_id,
        user_id: JSON.parse(localStorage.getItem("onboardUser")).userId,
      };
      if (selectedPort.label == "HTTPS") {
        nvrData.https_port_number = httpsPort;
      } else {
        nvrData.http_port_number = httpPort;
      }

      setLoading(true);
      let nvr_id = null;
      if (isEditable) {
        await ClientOnboardingActions.updateNVRDetails(storedNvrId, nvrData);
        nvr_id = storedNvrId;
      } else {
        const json_data = JSON.parse(localStorage.getItem("standaloneCamera"));
        // Check if json_data is not present or has less than 5 cameras to allow adding a new camera
        if (!json_data || (json_data && json_data.nvr.length < 5)) {
          nvr_id = await ClientOnboardingActions.createStandaloneCameraAndNVR(
            nvrData
          );
        } else {
          // When 5 cameras are already added, show a warning and move to the next step
          notifiToasterWarn("You can only add up to 5 IP cameras.");
          dispatch({ type: "updateCurrentStep", payload: activeStep + 1 });
        }
      }

      setLoading(false);
      let data = {
        ...nvrData,
        httpPort: httpPort,
        httpsPort: httpsPort,
        brand: brand,
        type: "standalone",
        id: nvr_id,
      };
      localStorage.setItem("completedStep", 3);
      localStorage.setItem("onboardNvr", JSON.stringify(data));
    } else {
      setErrors(newErrors);
    }

    if (isAddAnother && !isEditable) {
      setUsername("");
      setPassword("");
      setName("");
      setBrand(brandOption[0]);
      setIpadd("");
      setHttpsPort(0);
      setChannelUnused("");
      setHttpPort(0);
      setRtspPort(0);
    } else {
      dispatch({ type: "updateCurrentStep", payload: activeStep + 1 });
    }

    setLoading(false);
  };

  function handleSelectPort(e, val) {
    setSelectedPort(val);
  }
  function handleBrandOptionChange(e, val) {
    setBrand(val);
  }

  return (
    <div className="mt-4">
      {!isEditable && (
        <h2 className="mt-2 mb-4">Create Standalone Camera (IP Cameras)</h2>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="name"
              label="Camera Name"
              variant="outlined"
              fullWidth
              type="text"
              name="name"
              value={name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
              required
            />
          </div>

          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="username"
              label="Camera Username"
              variant="outlined"
              fullWidth
              type="text"
              name="username"
              value={username}
              onChange={handleChange}
              error={Boolean(errors.username)}
              helperText={errors.username}
              required
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="password"
              label="Camera Password"
              variant="outlined"
              fullWidth
              name="password"
              type="text"
              value={password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
              required
            />
          </div>

          <div className="col-12 col-sn-6 col-lg-4 mb-3">
            <AutocompleteComp
              option={brandOption}
              onChange={handleBrandOptionChange}
              value={brand}
              label="Brand"
              showValue={false}
            />
          </div>

          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="ipadd"
              label="Camera IP Address"
              variant="outlined"
              fullWidth
              type="text"
              name="ipadd"
              value={ipadd}
              onChange={handleChange}
              error={Boolean(errors.ipadd)}
              helperText={errors.ipadd}
              required
            />
          </div>
          <div className="col-12 col-sn-6 col-lg-4 mb-3">
            <AutocompleteComp
              option={[
                {
                  label: "HTTPS",
                  value: 0,
                },
                {
                  label: "HTTP",
                  value: 1,
                },
              ]}
              onChange={handleSelectPort}
              value={selectedPort}
              label="Select Port Type"
              showValue={false}
            />
          </div>
          {selectedPort.label == "HTTPS" ? (
            <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
              <TextField
                id="httpsPort"
                label="HTTPS Port Number"
                variant="outlined"
                fullWidth
                name="httpsPort"
                type="number"
                value={httpsPort}
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
              <TextField
                id="httpPort"
                label="HTTP Port Number"
                variant="outlined"
                fullWidth
                name="httpPort"
                type="number"
                value={httpPort}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="rtspPort"
              label="RTSP Port Number"
              variant="outlined"
              fullWidth
              name="rtspPort"
              type="number"
              value={rtspPort}
              onChange={handleChange}
              error={Boolean(errors.rtspPort)}
              helperText={errors.rtspPort}
              required
            />
          </div>
        </div>
        <div className="submit-button-onboarding mt-3">
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => handleSubmit(true)}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : isEditable ? (
              "Update Camera"
            ) : (
              "Save and Add Another Camera"
            )}
          </Button>
          {!isEditable && (
            <Button
              sx={{ marginLeft: "20px" }}
              type="button"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => handleSubmit(false)}
            >
              Save and Next Step
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}
OnboardStandAloneCameras.propTypes = {
  isEditable: PropTypes.bool.isRequired,
};
export default OnboardStandAloneCameras;
