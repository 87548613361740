import { RouteType } from "./config";
import TestPage from "../pages/test/TestPage";
import PreviewIcon from "@mui/icons-material/Preview";
import Configuration from "../pages/Configuration/Configuration";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ApprovalMainPage from "../pages/Approval/Approval";
import FormLayoutComponent from "../pages/Onboarding/FormLayoutComponent";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DeviceList from "../pages/DeviceConfiguration/DeviceList";
import AivisConfigMainPage from "../pages/AivisConfiguration/AivisConfigMainPage";
import DeviceSetup from "../pages/DeviceSetup/DeviceSetup";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
// import GetImageChoice from "../pages/JPolygen/GetImages/GetImageChoice";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AdminHomePage from "../pages/AdminPanel/AccountDetails/AdminHomePage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MainSalesForm from "../pages/SalesForm/MainSalesForm";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import FleetDashMainPage from "../pages/FleetDashboard/FleetDashMainPage";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import OnboardingMain from "../pages/ClientOnboarding/OnboardingMain";
import QueueIcon from "@mui/icons-material/Queue";
const appRoutes = [
  {
    path: "/config",
    element: <Configuration />,
    state: "config",
    sidebarProps: {
      displayText: "Configuration",
      icon: <RemoveRedEyeIcon />,
    },
  },
  {
    path: "/mainpage",
    element: <ApprovalMainPage />,
    state: "mainpage",
    sidebarProps: {
      displayText: "Main Page",
      icon: <PreviewIcon />,
    },
  },
  {
    path: "/onboarding",
    element: <FormLayoutComponent />,
    state: "mainpage",
    sidebarProps: {
      displayText: "Onboarding",
      icon: <ExitToAppIcon />,
    },
  },
  // {
  //   path: "/device-rhms",
  //   element: <DeviceList />,
  //   state: "mainpage",
  //   sidebarProps: {
  //     displayText: "Device RHMS",
  //     icon: <AddToQueueIcon />,
  //   },
  // },
  {
    path: "/device_setup",
    element: <DeviceSetup />,
    state: "mainpage",
    sidebarProps: {
      displayText: "Device SetUp",
      icon: <DisplaySettingsIcon />,
    },
  },
  {
    path: "/aivis_config",
    element: <AivisConfigMainPage />,
    state: "mainpage",
    sidebarProps: {
      displayText: "AIVIS Configuration",
      icon: <SettingsSuggestIcon />,
    },
  },
  {
    path: "/admin_panel",
    element: <AdminHomePage />,
    state: "mainpage",
    sidebarProps: {
      displayText: "Admin Panel",
      icon: <ManageAccountsIcon />,
    },
  },
  {
    path: "/salesform",
    element: <MainSalesForm />,
    state: "salesform",
    sidebarProps: {
      displayText: "Connectivity Tester",
      icon: <SettingsInputAntennaIcon />,
    },
  },
  {
    path: "/fleet",
    element: <FleetDashMainPage />,
    state: "fleet",
    sidebarProps: {
      displayText: "Fleet Dashboard",
      icon: <PhonelinkIcon />,
    },
  },
  {
    path: "/client/onboard",
    element: <OnboardingMain />,
    state: "client_onboarding_ops",
    sidebarProps: {
      displayText: "Client Onboard",
      icon: <QueueIcon />,
    },
  },
];

export default appRoutes;
