import React, { useState, useEffect } from "react";
import Textfield from "../../../components/formLayout/TextField";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import MultiSelectAutoAutoComplete from "../../../components/formLayout/MultiSelectAutoAutoComplete";
import CheckBoxComp from "../../../components/formLayout/CheckBox";
import AutocompleteComp from "../../../components/common/Autocomplete";
import Button from "@mui/material/Button";
import getDataFromAPI from "../Utils/getDataFromAPI";
import { useDispatch, useSelector } from "react-redux";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import { notifiToasterWarn } from "../../../components/common/UseToast";
import CameraTable from "./Tables/CameraTables";
import CircularProgress from "@mui/material/CircularProgress";
import BulkCreateCamera from "./Tables/BulkCreateCamera";
const initialState = {
  cameraName: "",
  channelNumber: "",
  roi: "",
  connectedTo: null,
  cameraUserName: "",
  cameraPassword: "",
  cameraIp: "",
  rtspPortNo: "",
  useCases: [],
  liveView: { label: "Yes" },
  isActive: { label: "Yes" },
  streamSource: {
    label: "Cloud",
  },
  liveViewServerPort: "",
  cloudEnabled: false,
  isStandAlone: false,
  isEzviz: false,
  streamType: "",
  mainRtsp: "",
  subRtsp: "",
  monitorId: "",
  embeddedUrl: "",
};
const AddCamera = (propTypes) => {
  const state = useSelector((state) => state.onboarding);
  const [formData, setFormData] = useState(initialState);

  const [serviceList, setServiceList] = useState([]);
  const [nvrList, setNVRList] = useState([]);
  const [disableAnotherCamera, setAnotherCamera] = useState(true);
  const [cameraData, setCameraData] = useState({});
  const [cameraDataChanges, setCameraDataChanges] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  function handleModal(value) {
    setOpenModal(value);
  }
  function filterObjectsById(inputArray, idArray) {
    if (inputArray && idArray && inputArray.length > 0 && idArray.length > 0) {
      const filteredArray = inputArray.filter((obj) =>
        idArray.includes(obj.value)
      );
      return filteredArray;
    }
  }
  function filterNvrById(inputArray, id) {
    if (inputArray && id && inputArray.length > 0) {
      const filteredArray = inputArray.filter((obj) => obj.value === id);

      return filteredArray[0];
    }
  }
  const fetchDataById = async () => {
    const cameraData = await getDataFromAPI.getCameraDetailsByid(propTypes.id);

    setCameraDataChanges(Math.random());
    setCameraData(cameraData);
    if (cameraData) {
      setFormData((prevData) => ({
        ...prevData,
        cameraName: cameraData.camera_name,
        channelNumber: cameraData.channel_number,
        roi: cameraData.roi,
        connectedTo: cameraData.connected_to,
        useCases: cameraData.usecases,
        liveView: { label: cameraData.live_view ? "Yes" : "No" },
        isActive: { label: cameraData.is_active ? "Yes" : "No" },
        streamSource: {
          label: cameraData.stream_source == "cloud" ? "Cloud" : "On Premisis",
        },
        liveViewServerPort: cameraData.live_view_server_port,
        cloudEnabled: cameraData.cloud_enabled,
        isStandAlone: cameraData.is_standalone,
        isEzviz: cameraData.credentials?.username ? true : false,
        streamType: cameraData.stream_type,
        mainRtsp: cameraData.main_rtsp_url,
        subRtsp: cameraData.sub_rtsp_url,
        monitorId: cameraData.monitor_id,
        embeddedUrl: cameraData.embedded_url,
        cameraUserName: cameraData.credentials?.username ?? "",
        cameraIp: cameraData.credentials?.camera_IP ?? "",
        cameraPassword: cameraData.credentials?.password ?? "",
      }));
    }
  };

  useEffect(() => {
    if (propTypes.id != -1) {
      setFormData((prevData) => ({
        ...prevData,
        useCases: filterObjectsById(serviceList, cameraData.usecases),
        connectedTo: filterNvrById(nvrList, cameraData.connected_to),
      }));
    }
  }, [cameraData, cameraDataChanges, serviceList, nvrList]);

  useEffect(() => {
    const fetchData = async () => {
      const serviceList = await getDataFromAPI.getAllServices();
      setServiceList(serviceList);
      const nvrList = await getDataFromAPI.getAllNVR();
      setNVRList(nvrList);
    };

    fetchData();
    if (propTypes.id && propTypes.id != -1) {
      fetchDataById();
    }
  }, []);

  useEffect(() => {
    if (formData.isEzviz) {
      setFormData((prevData) => ({
        ...prevData,
        channelNumber: 101,
        rtspPortNo: 554,
      }));
    }
  }, [formData.isEzviz]);

  const dispatch = useDispatch();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function handleConnectedToChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      connectedTo: value,
    }));
  }
  function handleConnectedToChangeConrolleble(e, value) {
    setFormData((prevData) => ({
      ...prevData,
      connectedTo: value,
    }));
  }
  function handleUseCasesChange(newValue) {
    setFormData((prevData) => ({
      ...prevData,
      useCases: newValue,
    }));
  }

  const handleLiveViewChange = (e, value) => {
    setFormData((prevData) => ({
      ...prevData,
      liveView: value,
    }));
  };

  const handleIsActiveChange = (e, value) => {
    setFormData((prevData) => ({
      ...prevData,
      isActive: value,
    }));
  };

  const handleStreamSourceChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      streamSource: value,
    }));
  };

  const handleClick = () => {
    if (formData.cameraName.length < 4) {
      notifiToasterWarn("Please Enter Camera Name Correctly");
      return;
    }
    if (formData.channelNumber == "") {
      notifiToasterWarn("Please select Channel Number");
      return;
    }
    if (!formData.connectedTo || !formData.connectedTo.value) {
      notifiToasterWarn("Please select NVR To which camera is connected");
      return;
    }

    const cameraData = {
      camera_name: formData.cameraName,
      channel_number: formData.channelNumber,
      roi: {},
      is_standalone: formData.isStandAlone,
      is_ezviz: formData.isEzviz,
      credentials: {
        username: formData.cameraUserName,
        password: formData.cameraPassword,
        camera_IP: formData.cameraIp,
        rtsp_port_no: formData.rtspPortNo,
      },
      cloud_enabled: formData.cloudEnabled,
      live_view: formData.liveView.label == "Yes",
      is_active: formData.isActive.label == "Yes",
      monitor_id: formData.monitorId,
      main_rtsp_url: formData.mainRtsp,
      sub_rtsp_url: formData.subRtsp,
      embedded_url: formData.embeddedUrl,
      stream_type: formData.streamType,
      stream_source: formData.streamSource.label
        .replace(/\s+/g, "_")
        .toLowerCase(),
      live_view_server_port:
        typeof formData.liveViewServerPort == "string"
          ? 0
          : formData.liveViewServerPort,
      connected_to: formData.connectedTo.value,
      usecases: formData.useCases
        ? formData.useCases.map((option) => option.value)
        : [],
    };
    if (propTypes.isEditable) {
      setLoading(true);
      dispatch(OnboardingAction.updateCamera(cameraData, propTypes.id))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      dispatch(OnboardingAction.createCamera(cameraData))
        .then(() => {
          setLoading(false);
          setAnotherCamera(false);
          setFormData({
            cameraName: "",
            channelNumber: "",
            roi: "",
            connectedTo: formData.connectedTo,
            cameraUserName: "",
            cameraPassword: "",
            cameraIp: "",
            rtspPortNo: "",
            useCases: [],
            liveView: { label: "Yes" },
            isActive: { label: "Yes" },
            streamSource: {
              label: "Cloud",
            },
            liveViewServerPort: "",
            cloudEnabled: false,
            isStandAlone: false,
            isEzviz: false,
            streamType: "",
            mainRtsp: "",
            subRtsp: "",
            monitorId: "",
            embeddedUrl: "",
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const getOptionDisabled = (item) => {
    return false;
  };
  const handleCheckChange = (name, checked) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };
  return (
    <div className="container">
      {!propTypes.isEditable && (
        <div>
          <h2 className="mt-2 mb-4">Add Cameras:</h2>
        </div>
      )}
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label="Camera name"
            value={formData.cameraName}
            onChange={handleChange}
            type="text"
            placeholder="Camera name"
            name="cameraName"
            isrequired={true}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label="Channel number"
            value={formData.channelNumber}
            onChange={handleChange}
            type="number"
            placeholder="Channel number"
            name="channelNumber"
            isrequired={true}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label="ROI"
            value={formData.roi}
            onChange={handleChange}
            type="text"
            placeholder="ROI"
            name="roi"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          {propTypes.isEditable ? (
            <AutocompleteComp
              option={nvrList}
              onChange={handleConnectedToChangeConrolleble}
              value={formData.connectedTo}
              label="Connected To NVR"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={nvrList}
              name="connectedTo"
              onChange={handleConnectedToChange}
              label="Connected To NVR"
            />
          )}
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <MultiSelectAutoAutoComplete
            option={serviceList}
            value={formData.useCases}
            onChange={handleUseCasesChange}
            label="Use cases"
            getOptionDisabled={getOptionDisabled}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <AutocompleteComp
            option={[
              {
                label: "Yes",
              },
              {
                label: "No",
              },
            ]}
            onChange={handleLiveViewChange}
            value={formData.liveView}
            label="Live View"
            showValue={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <AutocompleteComp
            option={[
              {
                label: "Yes",
              },
              {
                label: "No",
              },
            ]}
            onChange={handleIsActiveChange}
            value={formData.isActive}
            label="Active"
            showValue={false}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label="Live view server port:"
            value={formData.liveViewServerPort}
            onChange={handleChange}
            type="number"
            placeholder="Live view server port:"
            name="liveViewServerPort"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label="Monitor ID"
            value={formData.monitorId}
            onChange={handleChange}
            type="text"
            placeholder="Enter Monitor Id:"
            name="monitorId"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label="Embedded url"
            value={formData.embeddedUrl}
            onChange={handleChange}
            type="text"
            placeholder="Embedded url:"
            name="embeddedUrl"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label="Stream type:"
            value={formData.streamType}
            onChange={handleChange}
            type="text"
            placeholder="Stream Type"
            name="streamType"
            isrequired={false}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <AutocompleteComp
            option={[
              {
                label: "Cloud",
              },
              {
                label: "On Premisis",
              },
            ]}
            onChange={handleStreamSourceChange}
            value={formData.streamSource}
            label="Stream source"
            showValue={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <CheckBoxComp
            label="ezviz"
            name="isEzviz"
            checked={formData.isEzviz}
            onChange={(e) => handleCheckChange("isEzviz", e.target.checked)}
          />
        </div>
        {formData.isEzviz && (
          <>
            <div className="col-12 col-sm-6 col-lg-4 mb-3">
              <Textfield
                label="Camera Ip:"
                value={formData.cameraIp}
                onChange={handleChange}
                type="text"
                placeholder="Camera Ip"
                name="cameraIp"
                isrequired={formData.isEzviz}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-3">
              <Textfield
                label="Camera user name"
                value={formData.cameraUserName}
                onChange={handleChange}
                type="text"
                placeholder="Camera user name"
                name="cameraUserName"
                isrequired={false}
              />
            </div>

            <div className="col-12 col-sm-6 col-lg-4 mb-3">
              <Textfield
                label="Camera password"
                value={formData.cameraPassword}
                onChange={handleChange}
                type="text"
                placeholder="Camera password"
                name="cameraPassword"
                isrequired={false}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-3">
              <Textfield
                label="RTSP Port No:"
                value={formData.rtspPortNo}
                onChange={handleChange}
                type="text"
                placeholder="RTSP Port No"
                name="rtspPortNo"
                isrequired={formData.isEzviz}
              />
            </div>
          </>
        )}
      </div>
      <div className="row mt-sm-3">
        {propTypes.isEditable && (
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-6 mb-3">
              <Textfield
                label="Main RTSP URL"
                value={formData.mainRtsp}
                onChange={handleChange}
                type="text"
                placeholder="Main rtsp Url"
                name="mainRtsp"
                isrequired={formData.liveView.label == "Yes"}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-6 mb-3">
              <Textfield
                label="Sub RTSP URL"
                value={formData.subRtsp}
                onChange={handleChange}
                type="text"
                placeholder="Sub rtsp Url"
                name="subRtsp"
                isrequired={formData.liveView.label == "Yes"}
              />
            </div>
          </div>
        )}
        <div className="col-12 col-sm-6 col-lg-6 mb-3">
          <CheckBoxComp
            label="Standalone"
            name="isStandAlone"
            checked={formData.isStandAlone}
            onChange={(e) =>
              handleCheckChange("isStandAlone", e.target.checked)
            }
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <CheckBoxComp
            label="Cloud Enabled"
            name="cloudEnabled"
            checked={formData.cloudEnabled}
            onChange={(e) =>
              handleCheckChange("cloudEnabled", e.target.checked)
            }
          />
        </div>
      </div>
      <div className="row mt-sm-3">
        <div className="col-12 col-sm-6 col-lg-3 mb-3">
          <Button
            variant="contained"
            onClick={handleClick}
            sx={{ margin: "2px", minWidth: "150px" }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : propTypes.isEditable ? (
              "Update Camera"
            ) : (
              "Add Camera"
            )}
          </Button>
        </div>

        {!propTypes.isEditable && (
          <div className="col-12 col-sm-6 col-lg-4 add-another-camera">
            <Button
              variant="contained"
              onClick={() => handleModal(true)}
              className="button-add-another-camera"
            >
              Auto Create Camera
            </Button>
          </div>
        )}
      </div>
      <BulkCreateCamera isOpen={openModal} handleModalClose={handleModal} />

      {!propTypes.isEditable && (
        <CameraTable
          data={state.newAddedCameras}
          getUserList={() => console.log("")}
          showAddNewButton={propTypes.usedIn === "onboarding" ? false : true}
        />
      )}
    </div>
  );
};
export default AddCamera;
