const initialState = {
  selectedAccount: [],
  takenAccount: [],
  enteredMessages: [],
  msgDict: {},
  time: { startTime: "00:00", endTime: "23:59" },
  date: "",
};

export default function ConfigPageReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_SELECTED_ACCOUNT":
      return {
        ...state,
        selectedAccount: action.payload,
      };
    case "SET_TAKEN_ACCOUNT":
      return {
        ...state,
        takenAccount: action.payload,
      };

    case "SET_ENTERED_MESSAGE":
      return {
        ...state,
        enteredMessages: action.payload,
      };
    case "SET_ENTERED_MESSAGE_DICT":
      return {
        ...state,
        msgDict: action.payload,
      };
    case "SET_SELECTION_TIME":
      return {
        ...state,
        time: action.payload,
      };
    case "SET_SELECTED_DATE":
      return {
        ...state,
        date: action.payload,
      };
    default:
      return state;
  }
}
