import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import "../../assets/css/main.css";
import OperationModeAction from "./../../redux/Action/OperationModeAction";
import ApprovalCardDetailEdit from "./ApproveCardDetailEdit";

const ApprovalCard = (propTypes) => {
  const dispatch = useDispatch();
  const item = propTypes.item;
  const type = propTypes.item.type;
  useEffect(() => {
    if (item) {
      const accountId = item.data.facility__account__account_id;
      dispatch(OperationModeAction.getFacilitiesByAccountId(accountId));
      dispatch(OperationModeAction.getCamerasByStore(item.data.facility));
    }
  }, [item]);

  if (!item || !item.data) {
    return null; // Return null or a loading/error component if necessary
  }

  return (
    <div>
      <div className="main-container">
        <div className="alert-image">
          <img
            src={
              type === "event" ? item.data.event_image : item.data.alert_image
            }
            alt={type === "event" ? "Event" : "Alert"}
          />
        </div>

        <ApprovalCardDetailEdit item={item.data} isEvent={type === "event"} />
      </div>
    </div>
  );
};

ApprovalCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ApprovalCard;
