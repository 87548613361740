import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
const steps = ["Device Connection", "Device Congiguration"];
import AivisLogin from "./AivisLogin";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AivisConfig from "./AivisConfig";
import { notifiToasterWarn } from "../../components/common/UseToast";
import { useDispatch } from "react-redux";
import deviceRelatedAction from "../../redux/Action/DeviceRelatedAction";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
export default function AivisConfigMainPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    sessionStorage.removeItem("remoteIotCredential");
    dispatch(deviceRelatedAction.setNewConfigList([]));
    localStorage.removeItem("deviceConfigList");
  };

  const handleNext = () => {
    if (isRemoteIotCredentialPresent()) {
      if (activeStep < steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      notifiToasterWarn("Please Enter Below details");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleFinish = () => {
    setOpen(true);
  };
  useEffect(() => {
    dispatch(changeTopbarTitle("Aivis Config"));
  }, []);
  function isRemoteIotCredentialPresent() {
    try {
      const sessionData = sessionStorage.getItem("remoteIotCredential");
      const parsedSessionData = JSON.parse(sessionData);

      // Check if 'remoteIotCredential' exists and is an object
      return !!parsedSessionData && typeof parsedSessionData === "object";
    } catch (error) {
      // Handle any potential parsing errors
      return false;
    }
  }

  return (
    <Box
      sx={{ width: "100%", padding: "30px" }}
      className="config-page-container"
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === 0 && <AivisLogin />}
        {activeStep === 1 && <AivisConfig />}
      </div>
      <div className="mt-3">
        <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Details saved Successfully"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Entered sites and their details are saved Successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Finish
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
