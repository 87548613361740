import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import routes from "./routes";
import Login from "./components/common/Login";
import ForgetPass from "./components/common/ForgetPass";
import Profile from "./pages/Profile/Profile";
import ProtectedRoute from "./routes/ProtectedRoute";
import { useState, useEffect } from "react";
import AppLoader from "./components/common/AppLoader";
// import AccountDetails from "./pages/Overview/AccountDetails";
import PageNotFound from "./components/common/PageNotFound";
import AccountTable from "./pages/AdminPanel/AccountDetails/AccountTable";
import FacilityProfileDetails from "./pages/AdminPanel/FacilityProfileMapping/FacilityProfileDetails";
import UserDetails from "./pages/AdminPanel/UserDetails/UserDetail";
import UserProfileDetails from "./pages/AdminPanel/UserProfileDetails/UserProfileDetails";
import FacilityDetails from "./pages/AdminPanel/FacilityDetails/FacilityDetails";
import DeviceDetails from "./pages/AdminPanel/DeviceDetails/DeviceDetails";
import CameraDetails from "./pages/AdminPanel/CameraDetails/CameraDetails";
import NvrDetails from "./pages/AdminPanel/NvrDetails/NvrDetails";
import UseCaseDetails from "./pages/AdminPanel/UseCaseMapping/UseCasesDetails";
import AccessControlmapping from "./pages/AdminPanel/AccessControlmapping/AccessControlMain";
function App() {
  const [loading, setLoading] = useState(true); // add a loading state

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [0]);
  }, []);

  if (loading) {
    // render the loader if the app is loading
    return <AppLoader />;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path=""
          exact
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          {routes}
          <Route path="profile" element={<Profile />}></Route>
          <Route path="/accountList" element={<AccountTable />} />
          <Route path="/userList" element={<UserDetails />} />
          <Route path="/userProfileList" element={<UserProfileDetails />} />
          <Route path="/facilityList" element={<FacilityDetails />} />
          <Route path="/deviceList" element={<DeviceDetails />} />
          <Route path="/deviceList" element={<DeviceDetails />} />
          <Route path="/cameraList" element={<CameraDetails />} />
          <Route path="/nvrList" element={<NvrDetails />} />
          <Route path="/usecaseMapping" element={<UseCaseDetails />} />
          <Route
            path="/accessControlmapping"
            element={<AccessControlmapping />}
          />
          <Route
            path="/facilityProfileList"
            element={<FacilityProfileDetails />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="forget" element={<ForgetPass />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
