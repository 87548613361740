import React, { useMemo } from "react";
import EmailIcon from "@mui/icons-material/Email";
import "../../assets/css/main.css";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";
const ProfileTopCard = (propTypes) => {
  const state = useSelector((state) => state.profile.profileData);
  const profile = JSON.parse(localStorage.getItem("profile"));
  return (
    <div>
      <div className="profile-top-card-container col-12">
        <div className="image-account-div">
          <div className="profile-top-card-logo-div">
            <img
              src={propTypes.profile.account.config["logo"]}
              className="profile-top-card-logo-div-image"
            />
          </div>
          <div className="profile-top-card-logo-div-account-name">
            <center>{propTypes.profile.account.name}</center>
          </div>
        </div>
        <div className="parent-profile-top-card-account-details">
          <div className="profile-top-card-account-details">
            <div className="profile-detail-item">
              <div className="profile-detail-item-icon">
                <EmailIcon className="profile-detail-icon" />
              </div>
              <div className="profile-detail-item-text">
                {state.email_address[0]}
              </div>
            </div>
            <div className="profile-detail-item">
              <div className="profile-detail-item-icon">
                <PhoneIcon className="profile-detail-icon" />
              </div>
              <div className="profile-detail-item-text">
                {state.phone_numbers[0]}
              </div>
            </div>
            <div className="profile-detail-item">
              <div className="profile-detail-item-icon">
                <LocationOnIcon className="profile-detail-icon" />
              </div>
              <div className="profile-detail-item-text">{state.location}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTopCard;
