import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup } from "@mui/material";
import PropTypes from "prop-types";
const CheckBoxComp = ({ name, label, checked, onChange }) => {
  function handleChange(e) {
    onChange(e);
  }
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          name={name}
          control={<Checkbox />}
          label={label}
          checked={checked}
          onChange={handleChange}
        />
      </FormGroup>
    </div>
  );
};

CheckBoxComp.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckBoxComp;
