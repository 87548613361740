import React, { useEffect, useState } from "react";
import UseCaseTable from "./UseCaseTable";
import OperationModeAction from "../../../redux/Action/OperationModeAction";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import axios from "axios";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import { Bars } from "react-loader-spinner";
import getDataFromAPI from "../../Onboarding/Utils/getDataFromAPI";
const UseCaseDetails = () => {
  const dispatch = useDispatch();
  const [usecasesList, setUseCasesList] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getUseCasesList() {
    try {
      setLoading(true);
      var token = localStorage.getItem("token");
      const response = await axios.get(
        OperationDashEndpoints.addUseCaseMapping(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      setUseCasesList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setUseCasesList([]);
      console.error("Error fetching user data:", error);
      throw error;
    }
  }
  const fetchAccountList = async () => {
    // Check if the account list is already saved in sessionStorage
    const savedAccountList = sessionStorage.getItem("accountListSaved");

    // If the account list is not in sessionStorage, fetch it and save it
    if (!savedAccountList) {
      const accountList = await getDataFromAPI.getAllAccount();
      sessionStorage.setItem("accountListSaved", JSON.stringify(accountList));
      return accountList;
    }

    // Return the parsed account list from sessionStorage
    return JSON.parse(savedAccountList);
  };
  useEffect(() => {
    getUseCasesList();
    fetchAccountList();
    dispatch(changeTopbarTitle("Admin/Use Cases Mapping"));
  }, []);

  return (
    <div className="config-page-container">
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <UseCaseTable data={usecasesList} getUsecList={getUseCasesList} />
      )}
    </div>
  );
};

export default UseCaseDetails;
