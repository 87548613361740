import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditCameraModal from "./EditCameraModal";
import { useDispatch } from "react-redux";
import OnboardingAction from "../../../../redux/Action/OnboardingAction";
export default function FacilityTable({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [modalId, setModalId] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const handleEditCamera = (id) => {
    setShowModal(true);
    setModalId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModalId(-1);
  };
  const handleDeleteFacility = (id) => {
    dispatch(OnboardingAction.deleteFacility(id));
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return (
    <TableContainer component={Paper}>
      <>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={{ width: "100%" }}>
              <StyledTableCell>Id</StyledTableCell>
              <StyledTableCell align="left">Facility Name</StyledTableCell>
              <StyledTableCell align="left">Account</StyledTableCell>
              <StyledTableCell align="left">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          {data.length > 0 && (
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.id + "-" + index}>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.facility_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.account}
                  </TableCell>

                  <TableCell align="left">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteFacility(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </>
    </TableContainer>
  );
}
FacilityTable.propTypes = {
  data: PropTypes.array.isRequired,
};
