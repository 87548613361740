import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { useDispatch } from "react-redux";
import StatusCheck from "./StatusComp";
import axios from "axios";
import OperationDashEndpoints from "../../redux/features/OperationDashEndpoints";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { notifiToasterSuccess } from "../../components/common/UseToast";
import AutocompleteCompUncontrollable from "./../../components/common/AutoCompleteUncontrollable";
import FacilityByAccount from "./FacilityByAccount";
import { Box, Typography } from "@mui/material";
import AutocompleteComp from "../../components/common/Autocomplete";

const nvrOptions = [
  { label: "Hikvision / Prama", value: "hikvision" },
  { label: "CP Plus / Dahua", value: "cpplus" },
  { label: "Uniview", value: "uniview" },
  { label: "Securus", value: "securus" },
  { label: "Secureye", value: "secureye" },
  { label: "Others", value: "others" },
];

const channelOptions = [
  { label: "Channel Number", value: "channelNumber" },
  { label: "Channel Capacity", value: "channelCapacity" },
];

const channelCapacityOptions = [
  { label: "4", value: "4" },
  { label: "8", value: "8" },
  { label: "16", value: "16" },
  { label: "32", value: "32" },
];

const MainSalesForm = () => {
  const dispatch = useDispatch();
  const [ipAddress, setIpAddress] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [channelNumber, setChannelNumber] = useState("1");
  const [channelCapacity, setChannelCapacity] = useState("");
  const [httpPort, setHttpPort] = useState("");
  const [rtspPort, setRtspPort] = useState("");
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState("");
  const [text, setText] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [brand, setBrand] = useState(nvrOptions[2]);
  const [usedRtspUrl, setUsedRtspUrl] = useState([]);
  const [otherBrand, setOtherBrand] = useState("");
  const [channelOption, setChannelOption] = useState({
    label: "Channel Number",
    value: "channelNumber",
  });
  const [streamType, setStreamType] = useState({
    label: "Sub Stream",
    value: "subStream",
  }); // Added state for streamOption

  useEffect(() => {
    dispatch(changeTopbarTitle("Connectivity Tester"));
  }, [dispatch]);

  const handleBrand = (e, val) => {
    setBrand(val);
    validateBrand(val);
  };

  const validateIP = (ipAddress) => {
    const newErrors = { ...errors };
    setText("");
    setStatus("");
    if (!ipAddress) {
      newErrors.ipAddress = "IP Address is required";
    } else if (
      !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipAddress
      )
    ) {
      newErrors.ipAddress = "Please Enter a Valid IP";
    } else {
      delete newErrors.ipAddress;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateUserNamePass = (username, pass) => {
    const newErrors = { ...errors };
    if (!username.trim()) {
      newErrors.username = "Username is required";
    }
    if (!brand) {
      newErrors.brand = "Brand Name is required";
    }
    if (!pass) {
      newErrors.password = "Password is required";
    }
    if (channelOption.value === "channelNumber" && !channelNumber) {
      newErrors.channelNumber = "Channel Number is required";
    }
    if (channelOption.value === "channelCapacity" && !channelCapacity) {
      newErrors.channelCapacity = "Channel Capacity is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateBrand = (brand) => {
    const newErrors = { ...errors };
    if (!brand) {
      newErrors.brand = "Brand Name is required";
    } else {
      delete newErrors.brand;
    }
    setErrors(newErrors);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    notifiToasterSuccess("Rtsp url copied successfully");
  };

  const validateHttp = (http) => {
    const newErrors = { ...errors };
    if (http === "") {
      newErrors.httpPort = "HTTP Port is required";
    } else if (isNaN(http) || http <= 0 || http > 65535) {
      newErrors.httpPort = "Please enter a valid port number (1-65535)";
    } else {
      delete newErrors.httpPort;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateRtsp = (rtsp) => {
    const newErrors = { ...errors };
    if (rtsp === "") {
      newErrors.rtspPort = "RTSP Port is required";
    } else if (isNaN(rtsp) || rtsp <= 0 || rtsp > 65535) {
      newErrors.rtspPort = "Please enter a valid port number (1-65535)";
    } else {
      delete newErrors.rtspPort;
    }
    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const pingIp = async () => {
    setImageUrls([]);
    setUsedRtspUrl([]);
    if (validateIP(ipAddress)) {
      try {
        setImageUrls([]);
        setStatus("loading");
        setText("Checking IP Address");
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          OperationDashEndpoints.pingIpAddress(ipAddress),
          {
            headers,
          }
        );
        if (response.data.message === "ping successful") {
          setStatus("success");
          setText("Ip is valid");
        } else {
          setStatus("failed");
          setText("Unable To Ping IP");
        }
      } catch (error) {
        setStatus("failed");
        setText("Unable To Ping IP");
      }
    }
  };

  const telnetPort = async (type) => {
    setImageUrls([]);
    setUsedRtspUrl([]);
    const port = type === "http" ? httpPort : rtspPort;

    if (
      type === "http"
        ? validateHttp(httpPort) && validateIP(ipAddress)
        : validateRtsp(rtspPort) && validateIP(ipAddress)
    ) {
      console.log("ibcfhubhdb");
      try {
        setStatus("loading");
        setText(
          type === "http" ? "Checking the HTTP port" : "Checking the RTSP port"
        );
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          OperationDashEndpoints.checkHttpPort(ipAddress, port),
          {
            headers,
          }
        );
        if (response.data.status === "success") {
          setStatus("success");
          setText(type === "http" ? "Valid HTTP Port" : "Valid RTSP Port");
          if (type === "rtsp") {
            setTimeout(() => {
              getImageViaRtsp();
            }, 1000);
          }
        } else {
          setStatus("failed");
          setText(
            type === "http"
              ? "HTTP Port is unreachable"
              : "RTSP Port is unreachable"
          );
        }
      } catch (error) {
        setStatus("failed");
        setText(
          type === "http"
            ? "HTTP Port is unreachable"
            : "RTSP Port is unreachable"
        );
      }
    }
  };

  const getImageViaRtsp = async () => {
    console.log(brand);
    if (brand.value == "others") {
      setStatus("failed");
      setText("Not Able to fetch images for brand Others");
      return;
    }

    setImageUrls([]);
    setUsedRtspUrl([]);
    if (
      validateIP(ipAddress) &&
      validateUserNamePass(username, password) &&
      validateRtsp(rtspPort)
    ) {
      try {
        setStatus("loading");
        setText("Checking the rtsp Stream");
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };
        if (brand.value !== "secureye") {
          const response2 = await axios.post(
            OperationDashEndpoints.checkAuthentication(),
            {
              host: ipAddress,
              port: httpPort,
              channel_number: channelNumber,
              username: username,
              password: password,
              brand: brand.value,
            },
            {
              headers,
            }
          );
          if (
            response2.data.message ===
            "Unauthorized!! Incorrect username or password."
          ) {
            setStatus("failed");
            setText("Authentication credentials are not correct");
            return;
          }
        }
        const formattedData = {
          host: ipAddress,
          rtsp_port: rtspPort,
          channel_number: channelNumber,
          username: username,
          password: password,
          brand: brand.value,
          streamType: streamType.value,
        };
        const response = await axios.post(
          OperationDashEndpoints.checkRtspStream(),
          formattedData,
          {
            headers,
          }
        );
        setUsedRtspUrl([response.data.rtsp_url]);
        setImageUrls([response.data]);
        setStatus("success");
        setText("RTSP Stream is working");
      } catch (error) {
        console.log(error.response?.data?.rtsp_url);
        setUsedRtspUrl([error.response?.data?.rtsp_url ?? ""]);
        setStatus("failed");
        setText("Unable to Play RTSP Stream");
      }
    }
  };

  const checkRtspStreamChannelCapacity = async () => {
    if (brand.value == "others") {
      setStatus("failed");
      setText("Authentication credentials are not correct");
      return;
    }

    setImageUrls([]);
    setUsedRtspUrl([]);
    if (
      validateIP(ipAddress) &&
      validateUserNamePass(username, password) &&
      validateRtsp(rtspPort)
    ) {
      try {
        setStatus("loading");
        setText("Checking the RTSP Stream with Channel Capacity");
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };
        const formattedData = {
          host: ipAddress,
          rtsp_port: rtspPort,
          channel_capacity: channelCapacity,
          username: username,
          password: password,
          brand: brand.value,
          streamType: streamType.value,
        };
        const response = await axios.post(
          OperationDashEndpoints.checkRtspStreamChannelCapacity(),
          formattedData,
          {
            headers,
          }
        );
        const data = response.data;
        if (data.image_urls && data.image_urls.length > 0) {
          setUsedRtspUrl(data.image_urls.map((img) => img.rtsp_url));

          setImageUrls(data.image_urls);
          setStatus("success");
          setText("RTSP Stream with Channel Capacity is working");
        } else {
          setStatus("failed");
          setText("No valid RTSP Stream found");
        }
      } catch (error) {
        console.log(error);
        console.log(error.response?.data?.rtsp_url);
        setUsedRtspUrl([error.response?.data?.rtsp_url ?? ""]);
        setStatus("failed");
        setText("Unable to Play RTSP Stream with Channel Capacity");
      }
    }
  };

  const handleChannelNumber = (e) => {
    const value = e.target.value;

    // Validate and clear error if the field is filled
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        channelNumber: "Channel Number is required",
      }));
    } else {
      setErrors((prevErrors) => {
        const { channelNumber, ...rest } = prevErrors; // Destructure and remove channelNumber key
        return rest; // Return errors object without channelNumber if no error
      });
    }

    setChannelNumber(value);
  };

  const handleChannelCapacity = (val) => {
    setChannelCapacity(val);
    if (val) {
      const newErrors = { ...errors };
      delete newErrors.channelCapacity;
      setErrors(newErrors);
    }
  };

  const handleHttpPort = (e) => {
    const val = e.target.value;
    if (val < 0) return;
    setHttpPort(val);
    validateHttp(val);
  };

  const handleRtspPort = (e) => {
    const val = e.target.value;
    if (val < 0) return;
    setRtspPort(val);
    validateRtsp(val);
  };
  const [nvrFromFilter, setNvrFromFilter] = useState(null);
  useEffect(() => {
    if (nvrFromFilter) {
      const temp_brand = nvrOptions.find(
        (item) => item.value == nvrFromFilter.brand.toLowerCase()
      );

      setBrand(temp_brand ?? { value: "others", label: "Others" });
      setIpAddress(nvrFromFilter?.ip_address);
      setUsername(nvrFromFilter?.username);
      setPassword(nvrFromFilter?.password);
      setHttpPort(nvrFromFilter?.http_port_number);
      setRtspPort(nvrFromFilter?.rtsp_port);
    } else {
      setBrand({ value: "others", label: "Others" });
    }
  }, [nvrFromFilter]);

  const handleclearallselectedfield = () => {
    setIpAddress([]);
    setUsername([]);
    setPassword([]);
    setHttpPort([]);
    setRtspPort([]);
    setImageUrls([]);
    setText("");
    setStatus("");
  };
  const handleOherBrandInputChange = (e) => {
    const value = e.target.value;
    setOtherBrand(value);
    console.log("other brand name is", value);
  };
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <FacilityByAccount setNvrFromFilter={setNvrFromFilter} />
      </Box>
      <div className="config-page-container">
        <form id="checkForm">
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <TextField
                fullWidth
                id="ipAddress"
                label="IP Address"
                variant="outlined"
                placeholder="Enter IP Address"
                value={ipAddress}
                onChange={(e) => {
                  setIpAddress(e.target.value);
                  validateIP(e.target.value);
                }}
                error={Boolean(errors.ipAddress ?? "")}
                helperText={errors.ipAddress}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <TextField
                fullWidth
                id="username12"
                label="Username"
                variant="outlined"
                placeholder="Username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  const newErrors = { ...errors };
                  if (e.target.value.trim()) {
                    delete newErrors.username;
                  }
                  setErrors(newErrors);
                }}
                error={Boolean(errors.username ?? "")}
                helperText={errors.username ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <TextField
                fullWidth
                id="password12"
                label="Password"
                type="text"
                variant="outlined"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  const newErrors = { ...errors };
                  if (e.target.value) {
                    delete newErrors.password;
                  }
                  setErrors(newErrors);
                }}
                error={Boolean(errors.password ?? "")}
                helperText={errors.password ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <AutocompleteComp
                option={channelOptions}
                onChange={(e, val) =>
                  val ? setChannelOption(val) : setChannelOption(null)
                }
                value={channelOption}
                label={"Channel Option"}
                disableClearable={false}
                showValue={false}
              />
            </div>
            {channelOption.value === "channelNumber" && (
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  fullWidth
                  id="channelNumber"
                  label="Channel Number"
                  variant="outlined"
                  placeholder="Channel Number"
                  type="number"
                  value={channelNumber}
                  onChange={handleChannelNumber}
                  error={Boolean(errors.channelNumber ?? "")}
                  helperText={errors.channelNumber ?? ""}
                />
              </div>
            )}

            {channelOption.value === "channelCapacity" && (
              <div className="col-12 col-md-6 mb-3">
                <AutocompleteCompUncontrollable
                  option={channelCapacityOptions}
                  onChange={(val) =>
                    val
                      ? handleChannelCapacity(val.value)
                      : handleChannelCapacity(null)
                  }
                  label={"Channel Capacity"}
                  disableClearable={false}
                  showValue={false}
                />
                <span className="error-form-fleet">
                  {errors.channelCapacity ?? ""}
                </span>
              </div>
            )}
            <div className="col-12 col-md-6 mb-3">
              <AutocompleteComp
                option={[
                  { label: "Main Stream", value: "mainStream" },
                  { label: "Sub Stream", value: "subStream" },
                ]}
                onChange={(e, val) =>
                  val ? setStreamType(val) : setStreamType(null)
                }
                label={"Stream Option"}
                value={streamType}
                disableClearable={false}
                error={Boolean(errors.streamType ?? "")}
                helperText={errors.streamType ?? ""}
                showValue={false}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <TextField
                fullWidth
                id="httpPort"
                label="HTTP Port "
                variant="outlined"
                type="number"
                placeholder="HTTP Port (default 80)"
                value={httpPort}
                onChange={handleHttpPort}
                error={Boolean(errors.httpPort ?? "")}
                helperText={errors.httpPort ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <TextField
                fullWidth
                id="rtspPort"
                label="RTSP Port"
                variant="outlined"
                type="number"
                placeholder="RTSP Port (default 554)"
                value={rtspPort}
                onChange={handleRtspPort}
                error={Boolean(errors.rtspPort ?? "")}
                helperText={errors.rtspPort ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-6 mb-3">
              <AutocompleteComp
                option={nvrOptions}
                value={brand}
                onChange={handleBrand}
                label={"NVR Brand *"}
                disableClearable={false}
                showValue={false}
              />
              <span className="error-form-fleet">{errors.brand ?? ""}</span>
            </div>
            {brand?.value === "others" && (
              <div className="col-12 col-sm-6 col-lg-6 mb-3">
                <TextField
                  fullWidth
                  id="brandName"
                  label="Brand Name"
                  variant="outlined"
                  placeholder="Enter Brand Name"
                  value={otherBrand}
                  onChange={handleOherBrandInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            )}
          </div>
          <StatusCheck status={status} text={text} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div>
                <Button variant="outlined" onClick={pingIp}>
                  Check IP
                </Button>
              </div>
              <div>
                <Button variant="outlined" onClick={() => telnetPort("http")}>
                  Check HTTP
                </Button>
              </div>
              <div>
                <Button
                  variant="outlined"
                  onClick={() =>
                    channelOption.value === "channelCapacity"
                      ? checkRtspStreamChannelCapacity()
                      : telnetPort("rtsp")
                  }
                >
                  Check RTSP Stream
                </Button>
              </div>
            </div>
            <div>
              <Button variant="outlined" onClick={handleclearallselectedfield}>
                Clear
              </Button>
            </div>
          </div>
        </form>

        {imageUrls.length > 0 ? (
          <div>
            <h4>RTSP STREAM IMAGES :</h4>
            <div className="row">
              {imageUrls.map((url, index) => (
                <div key={index} className="col-md-6 mb-3">
                  <div className="card">
                    <img
                      style={{
                        height: "400px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      src={url.image_url}
                      alt={`rtsp-image-channel-${index + 1}`}
                      className="card-img-top"
                      onClick={() => window.open(url.image_url, "_blank")}
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                        Channel {url.channel_number}
                      </h5>
                      {url.status == "failed" && (
                        <Typography color={"red"}>
                          Failed to fetch image for channe number{" "}
                          {url.channel_number}
                        </Typography>
                      )}
                      <p className="card-text">
                        RTSP URL used:{" "}
                        <span id={`rtsp-url-${index}`}>
                          {usedRtspUrl[index]}
                        </span>
                      </p>
                      <button
                        className="btn btn-primary"
                        onClick={() => copyToClipboard(usedRtspUrl[index])}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p> </p>
        )}
      </div>
    </>
  );
};

export default MainSalesForm;
