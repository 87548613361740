import React, { useState, useEffect } from "react";
import ClientOnboardingActions from "../../redux/Action/ClientOnboarding";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Paper,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

const CameraRow = ({ cameraData, onUpdate }) => {
  const [formData, setFormData] = useState(cameraData);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <TableRow>
      <TableCell>{formData.channel_number}</TableCell>
      <TableCell sx={{ minWidth: "200px" }}>
        <TextField
          name="camera_name"
          value={formData.camera_name}
          onChange={handleChange}
          variant="outlined"
          size="small"
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={formData.live_view}
              onChange={handleChange}
              name="live_view"
            />
          }
          label=""
        />
      </TableCell>
      <TableCell sx={{ minWidth: "500px" }}>
        <TextField
          name="main_rtsp_url"
          fullWidth
          value={formData.main_rtsp_url}
          onChange={handleChange}
          variant="outlined"
          size="small"
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={formData.is_active}
              onChange={handleChange}
              name="is_active"
            />
          }
          label=""
        />
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onUpdate(formData)}
        >
          Update
        </Button>
      </TableCell>
    </TableRow>
  );
};

CameraRow.propTypes = {
  cameraData: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const steps = [
  "Sign Up",
  "Create Organisation",
  "Create Facility",
  "Add NVR/DVR",
  "Add Cameras",
  "Select Services",
];

const OnboardCameras = () => {
  const activeStep = useSelector((state) => state.clientOnboard.currentStep);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cameraList, setCameraList] = useState([]);
  const [isStandalone, setIsStandAlone] = useState(false);
  const [changedCam, setChangedCam] = useState([]);
  useEffect(() => {
    const fetchCameras = async () => {
      const standaloneCameras = JSON.parse(
        localStorage.getItem("standaloneCamera")
      );
      if (standaloneCameras) {
        if (standaloneCameras.cameras.length > 0) {
          setCameraList(standaloneCameras.cameras);
          setIsStandAlone(true);
        }
      } else {
        let existingCamera = JSON.parse(localStorage.getItem("onboardCameras"));
        if (!existingCamera || existingCamera.length == 0) {
          const nvr_id = JSON.parse(localStorage.getItem("onboardNvr")).id;
          let camList = await ClientOnboardingActions.createCameraAutocreate(
            nvr_id
          );
          setCameraList(camList);
          localStorage.setItem("onboardCameras", JSON.stringify(camList));
        } else {
          setCameraList(existingCamera);
        }
      }
    };
    fetchCameras();
  }, []);

  const handleUpdate = (updatedCamera) => {
    let flag = ClientOnboardingActions.updateCamerasDetails(
      updatedCamera.id,
      updatedCamera,
      isStandalone
    );
    if (flag && updatedCamera.live_view)
      setChangedCam([...changedCam, updatedCamera]);
  };

  const handleNext = async () => {
    let liveview = changedCam.filter((item) => item.live_view == true);
    if (liveview.length > 0) {
      const user_profile_id = JSON.parse(
        localStorage.getItem("onboardAccount")
      ).user_profile_id;

      await ClientOnboardingActions.EnableLiveview(user_profile_id)();
    }
    localStorage.setItem("completedStep", 4);
    dispatch({ type: "updateCurrentStep", payload: activeStep + 1 });
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Channel</TableCell>
              <TableCell sx={{ minWidth: "200px" }}>Camera Name</TableCell>
              <TableCell>Live View</TableCell>
              <TableCell>RTSP URL</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cameraList.map((camera) => (
              <CameraRow
                key={camera.id}
                cameraData={camera}
                onUpdate={handleUpdate}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="submit-button-onboarding mt-4">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          // disabled={!disableAddAnother || loading}
          onClick={handleNext}
        >
          {activeStep === steps.length - 1 ? "" : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default OnboardCameras;
