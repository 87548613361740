import React, { useState, useEffect } from "react";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import MultiSelectAutoAutoComplete from "../../../components/formLayout/MultiSelectAutoAutoComplete";
import axios from "axios";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import getDataFromAPI from "../Utils/getDataFromAPI";
import { notifiToasterWarn } from "../../../components/common/UseToast";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import AutocompleteComp from "../../../components/common/Autocomplete";
import PropTypes from "prop-types";
const FacilityProfileMapping = ({ isEditable, id }) => {
  const [selectedUserprofile, setSelectedUserProfile] = useState(null);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [facilityProfileMapping, setFacilityProfileMapping] = useState({});
  const dispatch = useDispatch();
  const handleUserProfileChange = (value) => {
    setSelectedUserProfile(value);
  };
  const handleUserProfileChangeControl = (e, value) => {
    setSelectedUserProfile(value);
  };
  const handleFacilitychange = (newValue) => {
    setSelectedFacilities(newValue);
  };

  useEffect(() => {
    // Fetch user profiles and facilities using async/await
    async function fetchData() {
      try {
        var token = localStorage.getItem("token");
        const [userProfilesResponse, facilitiesResponse] = await Promise.all([
          axios.get(OperationDashEndpoints.getAllUserProfiles(), {
            headers: { Authorization: `Token ${token}` },
          }),
          axios.get(OperationDashEndpoints.getAllFacilities(), {
            headers: { Authorization: `Token ${token}` },
          }),
        ]);

        const userProfilesData = userProfilesResponse.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        const facilitiesData = facilitiesResponse.data.map((item) => ({
          label: item.facility_name,
          value: item.id,
        }));

        setUserProfiles(userProfilesData);
        setFacilitiesList(facilitiesData);
      } catch (error) {
        notifiToasterWarn(error);
      }
    }

    fetchData();
  }, []);
  useEffect(() => {
    setSelectedFacilities(
      getDataFromAPI.getSelectedServices(
        facilityProfileMapping.Facility,
        facilitiesList
      )
    );
    setSelectedUserProfile(
      getDataFromAPI.getSelectedItem(
        facilityProfileMapping.userprofile,
        userProfiles
      )
    );
  }, [facilitiesList, userProfiles, facilityProfileMapping]);
  useEffect(() => {
    async function getAccountDetails() {
      const ans = await getDataFromAPI.getFacilityProfileById(id);
      setFacilityProfileMapping(ans);
    }
    if (isEditable && id) {
      getAccountDetails();
    }
  }, [id, isEditable]);
  function getOptionDisabled() {
    return false;
  }
  function handleSaveDetails() {
    if (!selectedUserprofile) {
      notifiToasterWarn("Please select user Profile");
    } else if (!selectedFacilities || selectedFacilities.length == 0) {
      notifiToasterWarn("Please select facilities");
    } else {
      const profile = selectedUserprofile.value;
      const facilities = selectedFacilities.map((option) => option.value);
      const finalData = {
        userprofile: profile,
        Facility: facilities,
      };
      setLoading(true);
      if (isEditable) {
        dispatch(OnboardingAction.updateUserProfileMapping(id, finalData))
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        dispatch(OnboardingAction.createUserProfileMapping(finalData))
          .then(() => {
            setLoading(false);
            resetAllStates();
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }
  const resetAllStates = () => {
    setSelectedUserProfile(selectedUserprofile);
    setSelectedFacilities([]);
    setUserProfiles(selectedUserprofile);
    setFacilitiesList([]);
    setLoading(false);
  };
  console.log(selectedUserprofile);
  return (
    <div>
      <div className="mt-4">
        {!isEditable && <h1> Facility Profile Mapping </h1>}
        <div
          className={
            isEditable
              ? "form-group col-12 mt-3"
              : "form-group col-12 col-sm-6 col-lg-6 mb-3 mt-3"
          }
        >
          {" "}
          {isEditable ? (
            <AutocompleteComp
              option={userProfiles}
              onChange={handleUserProfileChangeControl}
              value={selectedUserprofile}
              label="User Profile"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={userProfiles}
              onChange={handleUserProfileChange}
              label={"Select User Profile"}
            />
          )}
        </div>

        <div
          className={
            isEditable
              ? "form-group col-12 mb-3"
              : "form-group col-12 col-sm-6 col-lg-6 mb-3 mb-3"
          }
        >
          <MultiSelectAutoAutoComplete
            option={facilitiesList}
            value={selectedFacilities}
            onChange={handleFacilitychange}
            label="Facilities"
            getOptionDisabled={getOptionDisabled}
          />
        </div>

        <button
          className="bg-primary facility-profile-mapping-btn"
          onClick={handleSaveDetails}
          disabled={loading}
          style={{ minWidth: "150px" }}
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} />
          ) : isEditable ? (
            "Update Mapping"
          ) : (
            "Add Mapping"
          )}
        </button>
      </div>
    </div>
  );
};
FacilityProfileMapping.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default FacilityProfileMapping;
