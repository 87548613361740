import React from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AddLinkIcon from "@mui/icons-material/AddLink";
import DvrIcon from "@mui/icons-material/Dvr";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import { useNavigate } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const PageCard = () => {
  const navigate = useNavigate();
  const cardData = [
    {
      path: "/accountList",
      icon: <AccountBoxIcon sx={{ fontSize: "80px" }} />,
      label: "Account",
    },
    {
      path: "/userList",
      icon: <PersonIcon sx={{ fontSize: "80px" }} />,
      label: "Users",
    },
    {
      path: "/userProfileList",
      icon: <AssignmentIndIcon sx={{ fontSize: "80px" }} />,
      label: "User Profile",
    },
    {
      path: "/facilityList",
      icon: <StorefrontIcon sx={{ fontSize: "80px" }} />,
      label: "Facility",
    },
    {
      path: "/facilityProfileList",
      icon: <AddLinkIcon sx={{ fontSize: "80px" }} />,
      label: "Facility Profile Map",
    },
    {
      path: "/nvrList",
      icon: <DvrIcon sx={{ fontSize: "80px" }} />,
      label: "NVR",
    },
    {
      path: "/cameraList",
      icon: <CameraAltIcon sx={{ fontSize: "80px" }} />,
      label: "Camera",
    },
    {
      path: "/deviceList",
      icon: <VideogameAssetIcon sx={{ fontSize: "80px" }} />,
      label: "Device",
    },
    {
      path: "/usecaseMapping",
      icon: <VideogameAssetIcon sx={{ fontSize: "80px" }} />,
      label: "Usecase Mapping",
    },
    {
      path: "/accessControlmapping",
      icon: <ManageAccountsIcon sx={{ fontSize: "80px" }} />,
      label: "Access Control Mapping",
    },
  ];

  return (
    <div className="row">
      {cardData.map((card, index) => (
        <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="page-card-box" onClick={() => navigate(card.path)}>
            {card.icon}
            <h3>{card.label}</h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PageCard;
