import useWindowDimensions from "./useWindowDimensions";
import LargeWindowTopBar from "./LargeWindowTopBar";
import SmallWindowTopBar from "./SmallWindowTopBar";

const Topbar = () => {
  const { height, width } = useWindowDimensions();
  if (width >= 800) {
    return <LargeWindowTopBar />;
  }
  return <SmallWindowTopBar />;
};

export default Topbar;
