import React from "react";
import { RotatingLines } from "react-loader-spinner";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
const StatusCheck = ({ status, text }) => {
  const getStatusIcon = () => {
    if (status === "loading") {
      return (
        <div style={{ marginRight: "10px" }}>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="20"
            visible={true}
          />
        </div>
      );
    } else if (status === "success") {
      return (
        <div style={{ marginRight: "10px", color: "green" }}>
          <DoneAllIcon />
        </div>
      );
    } else if (status === "failed") {
      return (
        <div style={{ marginRight: "10px", color: "red" }}>
          <CloseIcon />
        </div>
      );
    }
  };

  return (
    <div className="d-flex">
      {getStatusIcon()}
      <div>
        <p
          style={{
            color:
              status === "success"
                ? "green"
                : status === "loading"
                ? "var(--card-text-color)"
                : "red",
          }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};
StatusCheck.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default StatusCheck;
