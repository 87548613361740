import React from "react";
import { BACKEND_URL } from "../../routes/config";

class DesktopNotification extends React.Component {
  constructor() {
    super();
    this.permission = "default";
  }

  checkPermission() {
    if (typeof window.Notification !== "undefined") {
      this.permission = window.Notification.permission;
    }
  }

  getUserPermission() {
    if (
      this.permission !== "denied" &&
      typeof window.Notification !== "undefined"
    ) {
      window.Notification.requestPermission().then((permission) => {
        this.permission = permission;
      });
    }
  }

  sendDesktopNotification({ title, message, image }) {
    if (
      this.permission === "granted" &&
      typeof window.Notification !== "undefined"
    ) {
      const notification = new window.Notification(title, {
        body: message,
        image: image,
      });

      notification.onclick = (event) => {
        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        if (title === "Alerts") {
          window.open(`${BACKEND_URL}/alerts/`, "_blank");
        } else if (title === "Events") {
          window.open(`${BACKEND_URL}/events/`, "_blank");
        }
      };
    }
  }

  render() {
    return null;
  }
}

export default DesktopNotification;
