import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import deviceRelatedAction from "../../redux/Action/DeviceRelatedAction";

export default function ConfigTable({ data }) {
  const dispatch = useDispatch();

  const handleDeleteDevice = (id) => {
    const data = JSON.parse(localStorage.getItem("deviceConfigList")) ?? [];
    const filteredData = data.filter((item) => item.cameraId !== id);
    localStorage.setItem("deviceConfigList", JSON.stringify(filteredData));
    dispatch(deviceRelatedAction.setNewConfigList(filteredData));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow sx={{ width: "100%" }}>
            <StyledTableCell>Id</StyledTableCell>
            <StyledTableCell align="left">Camera Name</StyledTableCell>
            <StyledTableCell align="left">Camera Number</StyledTableCell>
            <StyledTableCell align="left">Device Name</StyledTableCell>
            <StyledTableCell align="left">Uses Case</StyledTableCell>
            <StyledTableCell align="left">Demographic</StyledTableCell>
            <StyledTableCell align="left">Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        {data.length > 0 && (
          <>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.id + "-" + index}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.cameraName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cameraId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.deviceName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.useCase == 0 ? "People Count" : "Passer By"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.demographics ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteDevice(row.cameraId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
}
ConfigTable.propTypes = {
  data: PropTypes.array.isRequired,
};
