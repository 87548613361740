import axios from "axios";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import OperationDashEndpoint from "../features/OperationDashEndpoints";

class FleetDashActions {
  static updateSelectedCamera(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_SELECTED_CAMERA_CONFIG", payload: data });
    };
  }
  static setImageForPoints() {
    return async (dispatch) => {
      dispatch({ type: "SET_IMAGE_FILE_FOR_POINTS", payload: [] });
    };
  }
  static setIsCameraEzviz(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_IS_CAMERA_EZVIZ", payload: data });
    };
  }
  static updateConfigList(data) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_CONFIG_LIST_FOR_DEVICE", payload: data });
    };
  }
  static setNewConfigList(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_CONFIG_LIST_FOR_DEVICE", payload: data });
    };
  }
  static updateSelectedNVR(data) {
    return async (dispatch) => {
      dispatch({ type: "SET_SELECTED_NVR_CONFIG", payload: data });
    };
  }

  static async getImageFromDB(data) {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };

    try {
      const response = await axios.post(
        OperationDashEndpoint.getSavedImageFromDB(data),
        null,
        {
          headers,
        }
      );
      notifiToasterSuccess("Image retrieved");
      return response.data.message.image_path; // Return the image URL
    } catch (error) {
      notifiToasterWarn("Something went wrong, Can't fetch Image");
      return ""; // Return an empty string in case of an error
    }
  }
  static async createLabel(data) {
    console.log("hello");
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };

    try {
      const response = await axios.post(
        OperationDashEndpoint.labelForFleet(),
        data,
        {
          headers,
        }
      );
      notifiToasterSuccess("Label created");
    } catch (error) {
      notifiToasterWarn("Something went wrong, Can't fetch Image");
    }
  }
  static async addDeviceToFleetDash(data) {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };

    try {
      const response = await axios.post(
        OperationDashEndpoint.getDeviceList(),
        data,
        {
          headers,
        }
      );
      notifiToasterSuccess("Device Added");
    } catch (error) {
      notifiToasterWarn("Unable to add device");
    }
  }
  static async updateDeviceToFleetDash(id, data) {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };

    try {
      const response = await axios.put(
        OperationDashEndpoint.getDeviceListById(id),
        data,
        {
          headers,
        }
      );
      notifiToasterSuccess("Device Added");
    } catch (error) {
      notifiToasterWarn("Unable to add device");
    }
  }
}

export default FleetDashActions;
