import React, { useEffect, useState } from "react";
// import OverviewTable from "./OverviewTable";
import UserTable from "./UserTable";
import OperationModeAction from "../../../redux/Action/OperationModeAction";
import { useSelector, useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import axios from "axios";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import { Bars } from "react-loader-spinner";
const UserDetails = () => {
  const operationState = useSelector((state) => state.Approval);
  const dispatch = useDispatch();
  const profile_id = localStorage.getItem("profile_id");
  const [userList, setUserList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [accountList, SetAccountList] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [loading, setLoading] = useState(false);
  async function getAccontList() {
    try {
      var token = localStorage.getItem("token");
      const response1 = await axios.get(
        OperationDashEndpoints.createAccount(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      var accountData = [{ label: "All", value: 0 }];
      response1.data.map((item) =>
        accountData.push({
          label: item.name,
          value: item.account_id,
        })
      );
      SetAccountList(accountData);
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error;
    }
  }
  console.log(selectedAccount);
  async function getUserList() {
    try {
      setLoading(true);
      var token = localStorage.getItem("token");
      if (searchItem.length == 0 && !selectedAccount.value) {
        const response = await axios.get(OperationDashEndpoints.getUserList(), {
          headers: { Authorization: `Token ${token}` },
        });

        setUserList(response.data);
      } else if (searchItem.length > 2) {
        const response = await axios.get(
          OperationDashEndpoints.getSearchUser(searchItem),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        setUserList(response.data);
      } else if (selectedAccount && selectedAccount.value) {
        const response = await axios.get(
          OperationDashEndpoints.getUserByAccount(selectedAccount.value),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setUserList(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setUserList([]);
      console.error("Error fetching user data:", error);
      throw error;
    }
  }
  useEffect(() => {
    getUserList();
    getAccontList();
    dispatch(changeTopbarTitle("Admin/Users"));
  }, []);
  useEffect(() => {
    getUserList();
  }, [searchItem, selectedAccount]);
  function handleAccountChange(val) {
    setSelectedAccount(val);
  }
  return (
    <div className="config-page-container">
      <div className="row">
        <div className="d-flex mb-3 col-6">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target.value)}
          />
        </div>
        <div className=" mb-3 col-6">
          <AutocompleteCompUncontrollable
            option={accountList}
            onChange={handleAccountChange}
            label="Account"
            disableClearable={true}
            showValue={true}
          />
        </div>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Bars
            height="80"
            width="80"
            color="black"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <UserTable data={userList} getUserList={getUserList} />
      )}
    </div>
  );
};

export default UserDetails;
