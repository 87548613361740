import React from "react";
import { useState, useEffect } from "react";
import { AppBar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import FormControlLabel from "@mui/material/FormControlLabel";
import Drawer from "@mui/material/Drawer";
import { Stack } from "@mui/system";
import assets from "../../assets";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import Account from "../../redux/Action/CommonAction";
import Tooltip from "@mui/material/Tooltip";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useSelector } from "react-redux";
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: "70px",
  height: "40px",
  padding: 6,
  "& .MuiSwitch-switchBase": {
    marginTop: 5,
    padding: 2,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(36px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#4318FF" : "#4318FF",
    width: "24px",
    height: "24px",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 28 / 2,
  },
}));

const SmallWindowTopBar = () => {
  const filterApprouter = (appRoutes) => {
    var profile = JSON.parse(localStorage.getItem("profile"));
    var list = appRoutes.filter((item) => profile[item.state]);
    return list;
  };
  const muteNotification = useSelector(
    (state) => state.commonData.muteNotification
  );
  var profile = JSON.parse(localStorage.getItem("profile"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [auth, setAuth] = React.useState(true);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    left: false,
  });
  const THEME_KEY = "myapp_theme";

  const [theme, setTheme] = useState(
    localStorage.getItem(THEME_KEY) === "dark" ? true : false
  );
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const toggleNotification = (checked) => {
    Account.toglleMuteNotification(!checked)(dispatch);
    localStorage.setItem("muteNotification", !checked);
    if (!checked) {
      window?.socket?.disconnect();
    } else {
      window?.socket?.connect();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    updateRootVariables(theme);
  }, []);

  useEffect(() => {
    updateRootVariables(theme);
  }, [theme]);
  const handleToggle = () => {
    setTheme((prevTheme) => !prevTheme);
    localStorage.setItem(THEME_KEY, theme ? "light" : "dark");
  };
  const sidebarBgColorValue = !theme ? "#FFFFFF" : "#141415";
  const topbarbgcolor = !theme ? "#e0e5f2" : "#313235";
  const cardTextColor = !theme ? "#2b3674" : "#FFFFFF";
  const inputSelectBgColor = !theme ? "#f4f7fe" : "#313235";
  const cardIconDivColor = !theme ? "#f4f7fe" : "#313235";
  const chatBackground = !theme ? " white" : "black";
  const serverSentText = !theme ? "black" : "#ffff";
  const chatbotWelcomeTextColor = !theme ? "black" : "#ffff";
  const loadingSkeleton = !theme ? "#e6e3dc" : "#8d8b89";

  const updateRootVariables = () => {
    Account.updateTheme(theme)(dispatch);
    const css = `:root {
      --side-bar-bg-color: ${sidebarBgColorValue};
      --top-bar-bg-color: ${topbarbgcolor};
      --card-text-color:${cardTextColor};
      --input-select-bg-color:${inputSelectBgColor};
      --card-icon-div-bg:${cardIconDivColor};
      --chatbot-bg-color: ${chatBackground};
      --sever-sent-msg-text:${serverSentText};
      --chatbot-welcome-text-color:${chatbotWelcomeTextColor}
      --loading-skeleton:${loadingSkeleton};
    }`;
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);
  };

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const profileRoute = () => {
    navigate("/profile");
  };
  const logOutPage = () => {
    navigate("/login");
  };
  // Code to stop previous live stream if they are still exist in session storage

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          ml: sizeConfigs.sidebar.width,
          boxShadow: "unset",
          backgroundColor: "var(--top-bar-bg-color)",
          color: colorConfigs.topbar.color,
        }}
      >
        <Toolbar>
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={toggleDrawer(anchor, true)}>
                {" "}
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              </Button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                sx={{
                  width: sizeConfigs.sidebar.width,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: sizeConfigs.sidebar.width,
                    boxSizing: "border-box",
                    borderRight: "0px",
                    backgroundColor: "var(--side-bar-bg-color)",
                    color: colorConfigs.sidebar.color,
                  },
                }}
              >
                <List
                  disablePadding
                  onClick={toggleDrawer(anchor, false)}
                  onKeyDown={toggleDrawer(anchor, false)}
                >
                  <Toolbar sx={{ marginBottom: "20px" }}>
                    <Stack
                      sx={{ width: "100%" }}
                      direction="row"
                      justifyContent="center"
                    >
                      <img
                        src={assets.images.logo}
                        style={{
                          height: "60px",
                          width: "350px",
                          marginTop: "20px",
                        }}
                        alt="#"
                      />
                    </Stack>
                  </Toolbar>
                </List>
                <div>
                  {filterApprouter(appRoutes).map((route, index) =>
                    route.sidebarProps ? (
                      route.child ? (
                        <SidebarItemCollapse item={route} key={index} />
                      ) : (
                        <SidebarItem item={route} key={index} />
                      )
                    ) : null
                  )}
                </div>
              </Drawer>
            </React.Fragment>
          ))}
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <FormControlLabel
                  control={<MaterialUISwitch sx={{ ml: 2 }} />}
                  checked={theme}
                  onChange={handleToggle}
                />
                <MenuItem
                  onClick={handleClose}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    sx={{ p: 1, marginRight: 1, marginTop: "1px" }}
                    onClick={profileRoute}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <img
                      className="profile-topbar-profile-image"
                      src={profile.account.config["logo"]}
                    />
                  </IconButton>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => toggleNotification(muteNotification)}
                  style={{
                    color: muteNotification ? "#4318FF" : "#8796A5",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={
                      muteNotification
                        ? "Show Notification"
                        : "Hide Notification"
                    }
                    arrow
                  >
                    <NotificationsOffIcon />
                  </Tooltip>{" "}
                </MenuItem> */}
                <MenuItem onClick={logOutPage}>Logout</MenuItem>
              </Menu>
            </div>
          }
        </Toolbar>
      </AppBar>
    </>
  );
};

export default SmallWindowTopBar;
