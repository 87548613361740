import React, { useEffect, useState } from "react";
import CheckboxAutocomplete from "../../components/formLayout/CheckboxAutoComplete";
import { Button, Tabs, Tab, Box } from "@mui/material";
import ClientOnboardingActions from "../../redux/Action/ClientOnboarding";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import { useDispatch } from "react-redux";
import IntrusionService from "./IntrusionService";
import CrowdDetection from "./CrowdDetection";
import PropTypes from "prop-types";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  // Expected to be React node(s)
  children: PropTypes.node,
  // Expected to be a number indicating the current active tab index
  value: PropTypes.number.isRequired,
  // Expected to be a number indicating this panel's associated tab index
  index: PropTypes.number.isRequired,
  // Allowing any other props that might be necessary for flexibility
  other: PropTypes.object,
};

const OnboardServices = () => {
  const [selectNvr, setSelectNVR] = useState([]);
  const [nvrList, setNVRList] = useState([]);
  const [isCamera, setIsCamera] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    try {
      const nvrData = JSON.parse(localStorage.getItem("onboardNvr"));
      if (nvrData && nvrData.id && nvrData.name) {
        const nvrOptions = [{ id: nvrData.id, value: nvrData.name }];
        setNVRList(nvrOptions);
      } else {
        const camerasData = JSON.parse(
          localStorage.getItem("standaloneCamera")
        );
        setNVRList(camerasData.nvr);
        setIsCamera(true);
      }
    } catch (error) {
      console.error("Error parsing NVR data from localStorage:", error);
    }
  }, []);

  const handleChmsEnable = async () => {
    const savedOption = [];
    for (const nvr of selectNvr) {
      const { id, value } = nvr;
      const data = { cloud_monitoring_enabled: true };
      const isSuccess = await ClientOnboardingActions.updateNVRDetails(
        id,
        data
      );
      if (isSuccess) {
        notifiToasterSuccess(`CHMS is Enabled on ${value}`);
        savedOption.push(nvr);
      } else {
        notifiToasterWarn(`Unable to turn on the CHMS on ${value}`);
      }
    }
    localStorage.setItem("completedStep", 6);
    dispatch({ type: "updateCurrentStep", payload: 6 });
    localStorage.setItem("chmsEnableOn", JSON.stringify(savedOption));
  };

  return (
    <>
      <h2 className="mt-2 mb-4">Select Services</h2>
      <Tabs
        value={tabIndex}
        onChange={handleChangeTab}
        aria-label="Service tabs"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab label="CHMS" />
        <Tab label="Intrusion Detection" />
        <Tab label="Crowd Detection" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <CheckboxAutocomplete
          value={selectNvr}
          onChange={(event, newValue) => setSelectNVR(newValue)}
          label={!isCamera ? "Select NVRs" : "Select Cameras"}
          heading="Enable CHMS"
          options={nvrList}
          size="medium"
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={handleChmsEnable}
        >
          Enable CHMS
        </Button>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <IntrusionService />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <CrowdDetection />
      </TabPanel>
    </>
  );
};

export default OnboardServices;
