import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useDispatch } from "react-redux";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import CircularProgress from "@mui/material/CircularProgress";
import getDataFromAPI from "../Utils/getDataFromAPI";
import PropTypes from "prop-types";

function CreateUser({ isEditable, id }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    name: "",
    email: "",
  });
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    is_active: true,
    is_staff: false,
    is_superuser: false,
    changePass: isEditable ? false : true,
  });

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Reset the specific error when the input changes
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
    if (name === "name") setName(value);
    if (name === "email") setEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = {
      username: "",
      password: "",
      confirmPassword: "",
      name: "",
      email: "",
    };

    // Username validation
    if (username.length < 8) {
      valid = false;
      newErrors.username = "Username must be at least 8 characters long";
    }
    if (email.length < 8) {
      valid = false;
      newErrors.email = "Email must be at least 8 characters long";
    }
    if (name.length < 3) {
      valid = false;
      newErrors.name = "Name must be at least 8 characters long";
    }
    // Password validation

    if (password.length < 6 && state.changePass) {
      valid = false;
      newErrors.password = "Password must be at least 6 characters long";
    }

    // Confirm Password validation
    if (password !== confirmPassword && state.changePass) {
      valid = false;
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (valid) {
      // Handle form submission, e.g., send data to the server
      const userData = {
        is_superuser: state.is_superuser,
        username: username,
        email: email,
        is_staff: state.is_staff,
        is_active: state.is_active,
        name: name,
      };
      if (state.changePass) {
        userData.password = password;
      }
      setLoading(true);
      if (isEditable) {
        dispatch(OnboardingAction.updateUserDetails(id, userData)).then(() =>
          setLoading(false)
        );
      } else {
        dispatch(OnboardingAction.createUser(userData))
          .then(() => {
            setLoading(false);
            resetAllStates();
          })
          .catch(() => {
            setLoading(false);
          });
      }
    } else {
      // Update the error state with validation errors
      setErrors(newErrors);
    }
  };

  const resetAllStates = () => {
    setUsername("");
    setPassword("");
    setConfirmPassword("");
    setName("");
    setEmail("");
    setState({
      is_active: true,
      is_staff: false,
      is_superuser: false,
    });
  };
  useEffect(() => {
    async function getUserDetails() {
      const ans = await getDataFromAPI.getUserId(id);
      setUsername(ans.username);
      setName(ans.name);
      setEmail(ans.email);
      setPassword("");
      setState({
        is_active: ans.is_active,
        is_staff: ans.is_staff,
        is_superuser: ans.is_superuser,
      });
    }
    if (isEditable && id) {
      getUserDetails();
    }
  }, [id, isEditable]);
  return (
    <div className="mt-4">
      {!isEditable && <h2 className="mt-2 mb-4">Create User</h2>}
      <form onSubmit={handleSubmit} className="row">
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            fullWidth
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            fullWidth
            type="email"
            name="username"
            value={username}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
        </div>
        {isEditable && (
          <div className="mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.changePass}
                  onChange={handleChangeCheckbox}
                  name="changePass"
                  color="primary"
                />
              }
              label="Change Password"
            />
          </div>
        )}
        {state.changePass && (
          <>
            <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                fullWidth
                name="password"
                type="password"
                value={password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                required
              />
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
              <TextField
                id="confirmPassword"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={handleChange}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword}
                required
              />
            </div>
          </>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={state.is_active}
              onChange={handleChangeCheckbox}
              name="is_active"
              color="primary"
            />
          }
          label="Active"
        />
        <FormHelperText>
          Designates whether this user should be treated as active. Unselect
          this instead of deleting accounts.
        </FormHelperText>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.is_staff}
              onChange={handleChangeCheckbox}
              name="is_staff"
              color="primary"
            />
          }
          label="Staff Status"
        />
        <FormHelperText>
          Designates whether the user can log into this admin site.
        </FormHelperText>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.is_superuser}
              onChange={handleChangeCheckbox}
              name="is_superuser"
              color="primary"
            />
          }
          label="Superuser status"
        />
        <FormHelperText>
          Designates that this user has all permissions without explicitly
          assigning them.
        </FormHelperText>
        <div className="col-4 mt-3">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ minWidth: "100px" }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : isEditable ? (
              "Update User"
            ) : (
              "Add User"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}
CreateUser.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default CreateUser;
