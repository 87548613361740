import React, { useEffect, useState } from "react";
import Textfield from "../../../components/formLayout/TextField";
import MultiSelectAutoAutoComplete from "../../../components/formLayout/MultiSelectAutoAutoComplete";
import Button from "@mui/material/Button";
import SelectFieldComp from "../../../components/formLayout/SelectFieldComp";
import CheckBoxComp from "../../../components/formLayout/CheckBox";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import getDataFromAPI from "../Utils/getDataFromAPI";
import CircularProgress from "@mui/material/CircularProgress";
import AutocompleteComp from "../../../components/common/Autocomplete";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../../components/common/UseToast";
import { useDispatch } from "react-redux";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import PropTypes from "prop-types";
const Userprofile = ({ isEditable, id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [userProfile, setUserProfile] = useState();
  const [formData, setFormData] = useState({
    name: "",
    user: {},
    profileType: "Head Office",
    account: {},
    services: [],
    fcm: "",
    phoneNumber: "",
    email: "",
    checked: "",
  });
  const [accountList, setAccountList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const userList = await getDataFromAPI.getAllUser();
      setUserList(userList);
      const accData = await getDataFromAPI.getAllAccount();
      setAccountList(accData);
      const serviceList = await getDataFromAPI.getAllServices();
      setServiceList(serviceList);
    };

    fetchData();
  }, []);
  useEffect(() => {
    async function getUserProfileDetails() {
      const ans = await getDataFromAPI.getUserProfileById(id);
      setUserProfile(ans);
    }
    if (isEditable && id) {
      getUserProfileDetails();
    }
  }, [id, isEditable]);
  function handleProfileTypeChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      profileType: value,
    }));
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheckChange = (name, checked) => {
    setFormData((prevData) => ({
      ...prevData,
      checked: { ...prevData.checked, [name]: checked },
    }));
  };

  const getOptionDisabled = (item) => {
    return false;
  };

  function handleServicesChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      services: value,
    }));
  }
  function handleUserChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      user: value,
    }));
  }
  function handleAccountChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      account: value,
    }));
  }

  const profileTypeOptions = [
    "Head Office",
    "Zonal Admin",
    "Regional Admin",
    "Area Admin",
    "Store Admin",
  ];

  const checkBoxes = [
    { label: "Dashboard", value: 0 },
    { label: "Events", value: 1 },
    { label: "Heatmap", value: 2 },
    { label: "Alerts", value: 3 },
    { label: "Remote Monitoring", value: 4 },
    { label: "Live View", value: 5 },
    { label: "Device Status", value: 6 },
    { label: "Device Control", value: 7 },
    { label: "Offline Alerts", value: 8 },
    { label: "Alert auto resolve", value: 9 },
    { label: "Chat", value: 10 },
  ];
  function getSelectedAccount(id) {
    if (accountList && id && accountList.length > 0) {
      const selectedAccount = accountList.filter((item) => item.value === id);
      if (selectedAccount) {
        return selectedAccount[0];
      } else {
        return {};
      }
    }
  }
  function getSelectedUser(id) {
    if (userList && id && userList.length > 0) {
      const selectedUser = userList.filter((item) => item.value === id);
      if (selectedUser) {
        return selectedUser[0];
      } else {
        return {};
      }
    }
  }
  function getSelectedServices(ids) {
    if (serviceList && ids && serviceList.length > 0) {
      const selectedServices = serviceList.filter((item) =>
        ids.includes(item.value)
      );
      return selectedServices || [];
    }
    return [];
  }

  useEffect(() => {
    if (userProfile) {
      setFormData({
        name: userProfile.name,
        user: getSelectedUser(userProfile.user),
        profileType: "Head Office",
        account: getSelectedAccount(userProfile.account),
        services: getSelectedServices(userProfile.services_selected),
        fcm: userProfile.fcm,
        phoneNumber: userProfile.phone_numbers[0]?.phone_number || "",
        email: userProfile.email_address[0]?.email_address || "",
        checked: {
          emailNotification:
            userProfile.email_address[0]?.email_address || false,
          whatsapp:
            userProfile.phone_numbers[0]?.whatsapp_notification || false,
          Dashboard: userProfile.dashboard,
          "Device Control": userProfile.device_control,
          "Device Status": userProfile.device_status,
          Heatmap: userProfile.heatmap,
          Alerts: userProfile.alerts,
          Events: userProfile.events,
          Chat: userProfile.chat,
          "Alert auto resolve": userProfile.alert_auto_resolve,
          "Offline Alerts": userProfile.offline_alerts,
          "Live View": userProfile.live_view,
          "Remote Monitoring": userProfile.remote_monitoring,
        },
      });
    }
  }, [accountList, userProfile, userList, serviceList]);
  function handleClick() {
    if (!formData || !formData.name || formData.name.length < 2) {
      notifiToasterWarn("Please Enter Name");
      return false;
    }
    if (!formData || !formData.user || !formData.user.value) {
      notifiToasterWarn("Please Select User");
      return false;
    }
    if (!formData || !formData.account || !formData.account.value) {
      notifiToasterWarn("Please Select Account");
      return false;
    }
    if (!formData || formData.services.length == 0) {
      notifiToasterWarn("Please select services");
      return false;
    }
    const finalData = {
      phone_numbers: [
        {
          phone_number: formData.phoneNumber,
          whatsapp_notification: formData.checked.whatsapp || false,
          user_profile: 0,
        },
      ],
      email_address: [
        {
          id: 0,
          email_address: formData.email,
          send_email: formData.checked.emailNotification,
          user_profile: 0,
        },
      ],
      name: formData.name,
      profile_type: formData.profileType.replace(/\s+/g, "_").toLowerCase(),
      dashboard: formData.checked.Dashboard || false,
      events: formData.checked.Events || false,
      heatmap: formData.checked.Heatmap || false,
      alerts: formData.checked.Alerts || false,
      remote_monitoring: formData.checked["Remote Monitoring"] || false,
      live_view: formData.checked["Live View"] || false,
      device_status: formData.checked["Device Status"] || false,
      device_control: formData.checked["Device Control"] || false,
      offline_alerts: formData.checked["Offline Alerts"] || false,
      alert_auto_resolve: formData.checked["Alert auto resolve"] || false,
      chat: formData.checked.Chat || false,
      config: false,
      mainpage: false,
      fcm: formData.fcm.length > 0 ? [formData.fcm] : [],
      user: formData.user.value,
      account: formData.account.value,
      services_selected: formData.services.map((option) => option.value),
    };
    setLoading(true);
    if (isEditable) {
      dispatch(OnboardingAction.updateUserProfileByid(id, finalData)).then(() =>
        setLoading(false)
      );
    } else {
      dispatch(OnboardingAction.createUserProfile(finalData))
        .then(() => {
          setLoading(false);
          setFormData({
            name: "",
            user: {},
            profileType: "Head Office",
            account: {},
            services: [],
            fcm: "",
            phoneNumber: "",
            email: "",
            checked: "",
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }
  return (
    <div className="container">
      <div>
        {!isEditable && <h2 className="mt-2 mb-4">Create User Profile:</h2>}
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label={"Name"}
            value={formData.name}
            onChange={handleChange}
            type={"text"}
            placeholder={"Name"}
            name={"name"}
            isrequired={true}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          {isEditable && formData.user ? (
            <AutocompleteComp
              option={userList}
              onChange={handleUserChange}
              value={formData.user}
              label="User"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={userList}
              onChange={handleUserChange}
              name="user"
              label="User"
            />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          {isEditable && formData.account ? (
            <AutocompleteComp
              option={accountList}
              onChange={handleAccountChange}
              value={formData.account}
              label="Account"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={accountList}
              onChange={handleAccountChange}
              label="Account"
            />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <SelectFieldComp
            options={profileTypeOptions}
            onChange={handleProfileTypeChange}
            value={formData.profileType}
            label="Profile Type"
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label={"Fcm"}
            value={formData.fcm}
            onChange={handleChange}
            type={"text"}
            placeholder={"Fcm"}
            name={"fcm"}
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-12 col-lg-12 mb-3">
          <MultiSelectAutoAutoComplete
            option={serviceList}
            value={formData.services}
            onChange={handleServicesChange}
            label="Services Selected"
            getOptionDisabled={getOptionDisabled}
          />
        </div>
      </div>
      <div className="row mt-sm-3">
        <h3>Notification Related</h3>
        <span>
          Enter the Phone Number on which Whatsapp Notification should be
          recieved
        </span>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label={"Phone Number"}
            value={formData.phoneNumber}
            onChange={handleChange}
            type={"text"}
            placeholder={"Phone Number"}
            name={"phoneNumber"}
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-6 mb-3">
          <CheckBoxComp
            label="Whats app notification"
            name="whatsapp"
            checked={formData.checked.whatsapp || false}
            onChange={(e) => handleCheckChange("whatsapp", e.target.checked)}
          />
        </div>
      </div>
      <div className="row mt-sm-3">
        <span>Enter the Email On Which Email is recieved</span>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <Textfield
            label={"Email Address"}
            value={formData.email}
            onChange={handleChange}
            type={"email"}
            placeholder={"Email Address "}
            name={"email"}
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <CheckBoxComp
            label="Email notification"
            name="emailNotification"
            checked={formData.checked.emailNotification || false}
            onChange={(e) =>
              handleCheckChange("emailNotification", e.target.checked)
            }
          />
        </div>
      </div>

      <div className="row mt-sm-3">
        <h3>Select Pages To Show</h3>
        {checkBoxes.map((elem) => (
          <div className="col-12 col-sm-6 col-lg-6 mb-3" key={elem.value}>
            <CheckBoxComp
              label={elem.label}
              name={elem.label}
              checked={formData.checked[elem.label] || false}
              onChange={(e) => handleCheckChange(elem.label, e.target.checked)}
            />
          </div>
        ))}
      </div>
      <div className="row mt-sm-3">
        <div className="col-12 col-sm-6 col-lg-3 mb-3">
          <Button
            variant="contained"
            onClick={loading ? null : handleClick}
            disabled={loading}
            sx={{ minWidth: "200px" }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : isEditable ? (
              "Update User Profile"
            ) : (
              "Add User Profile"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
Userprofile.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default Userprofile;
