import "../../assets/css/Login.css";
import play_store_image from "../../assets/images/googleplay.png";
import ios_image from "../../assets/images/appstore.png";
import React, { useRef, useState } from "react";
// import Swal from "sweetalert2";
import Layout from "../common/Layout";
import Modal from "react-bootstrap/Modal";
import ForgetPass from "./ForgetPass";
import assets from "../../assets";
import { useDispatch } from "react-redux";
import { fetchAccessToken } from "../../redux/Action/Login";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { notifiToasterSuccess, notifiToasterWarn } from "./UseToast";
import { getProfile } from "../../redux/Action/ProfileAction";
import FailureModal from "./LoginModal";
const Login = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isLoadingg, setisLoadingg] = useState(false);
  const submitButtonRef = useRef(null);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeIcon, setEyeIcon] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEyeIcon(eyeIcon ? false : true);
  };

  const handleSubmit = (e) => {
    setisLoadingg(true);
    e.preventDefault();
    if (loginTest(username, password)) {
      fetchAccessToken({ username: username, password: password })
        .then((firstTrueKey) => {
          Layout();
          window?.socket?.disconnect();
          setisLoadingg(false);
          notifiToasterSuccess("Login Successful");
          dispatch(getProfile());
          navigate("/" + firstTrueKey);
        })
        .catch((error) => {
          setisLoadingg(false);
          setError(error);
          setIsModalOpen(true);
          console.error(error);
        });
    }
  };

  const loginTest = (email, password) => {
    if (email && password) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email.toLowerCase())) {
        fireErrorAlert(
          "Incorrect email format.",
          "Enter a correct email to login."
        );
        setisLoadingg(false);
        return false;
      }
    } else if (email && !password) {
      fireErrorAlert("Password required.", "Enter your password to login.");
      setisLoadingg(false);
      return false;
    } else if (password && !email) {
      fireErrorAlert("Email required.", "Enter your email to login.");
      setisLoadingg(false);
      return false;
    } else if (!email && !password) {
      fireErrorAlert("Fields required.", "Email and password are required.");
      setisLoadingg(false);
      return false;
    }

    return true;
  };
  // email verification
  const fireErrorAlert = (title, text) => {
    window.alert(title, text);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 colxl-4 form-section">
          <form className="login-form" onSubmit={handleSubmit}>
            <img
              alt=""
              src={assets.images.logo}
              style={{ maxWidth: "100%", marginBottom: "40px" }}
            ></img>
            <div className="form-group">
              <div
                className="form-label-login-page"
                style={{ marginBottom: "6px" }}
                htmlFor="username"
              >
                Email id/Username *
              </div>
              <input
                type="text"
                className="inputLogin"
                onChange={(e) => setusername(e.target.value)}
                placeholder="mail@sample.com"
              />
            </div>
            <div className="form-group">
              <div
                className="form-label-login-page"
                style={{ marginBottom: "6px" }}
                htmlFor="password"
              >
                Password *
              </div>
              <div className="pwd">
                <input
                  className="inputLogin"
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Min.8 characters"
                  type={passwordShown ? "text" : "password"}
                />
                <span className="p-viewer">
                  <i
                    onClick={togglePasswordVisiblity}
                    className={eyeIcon ? "bi bi-eye" : "bi bi-eye-slash"}
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>

            <br />
            <div style={{ marginTop: "20px" }}>
              <button
                type="submit"
                className="signButton"
                ref={submitButtonRef}
              >
                {!isLoadingg ? (
                  <span id="headSign">Sign In</span>
                ) : (
                  <CircularProgress style={{ color: "#ffff" }} />
                )}
              </button>
            </div>

            <div className="form-group">
              <p className="not-registred-yet">
                Not Registered yet ?
                <a style={{ color: "#4318FF" }} href="https://agrexai.com/">
                  {" "}
                  Contact us
                </a>{" "}
              </p>
            </div>
            <div className="form-group">
              <div
                className="d-flex flex-column justify-content-center align-items-center h-100"
                style={{ marginTop: "50px" }}
              >
                <div id="allrightreserved">
                  &copy; 2023 Agrex.ai All Rights Reserved.
                </div>
              </div>
              <div
                style={{ marginTop: "80px" }}
                className="row d-flex justify-content-center"
              >
                <div className="col-5 mt-3 login-store-logo">
                  <a href="https://play.google.com/store/apps/details?id=com.agrex.agrexai&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      src={play_store_image}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <div className="col-5 h-sm-50 mt-3 login-store-logo">
                  <a href="https://apps.apple.com/in/app/agrex-ai-analytics-app/id1586145482">
                    <img src={ios_image} alt="" style={{ width: "100%" }} />
                  </a>
                </div>
              </div>
            </div>
            <FailureModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              error={error}
            />
          </form>
        </div>
        <div className="col-12 col-lg-8 col-xl-8 d-sm-none d-md-none d-lg-block d-xl-block d-none image-section"></div>
      </div>
      <Modal centered show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <div className="forgot-password-bg-image">
            <ForgetPass />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
