import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const CrowdDetectionCameraWise = ({ camera, index, data, changeData }) => {
  const [times, setTimes] = useState(
    data.times ||
      days.map((day) => ({
        day,
        startTime: "00:00",
        endTime: "23:59",
        active: true,
      }))
  );
  const [crowdCount, setCrowdCount] = useState(data.crowdCount || 1);
  const [isCrowdDetectionEnabled] = useState(true);
  const [tempCrowdCOunt, setTempCrowdCount] = useState(1);
  const handleCrowdCountChange = (e) => {
    setTempCrowdCount(e.target.value);
    const newCount = parseInt(e.target.value, 10);
    if (newCount >= 1) {
      setCrowdCount(newCount);
    } else {
      setCrowdCount(1);
    }
  };

  const updateData = () => {
    console.log(times, crowdCount, isCrowdDetectionEnabled);
    changeData({ times, crowdCount, isCrowdDetectionEnabled });
  };

  useEffect(() => {
    updateData();
  }, [times, crowdCount, isCrowdDetectionEnabled]);

  return (
    <div className="border-div-card-intrusion">
      <Typography variant="h4" component="h2">
        {camera.value}
      </Typography>
      <Typography variant="h5" component="p">
        Channel Number: {camera.channel_number}
      </Typography>

      <div className="d-flex justify-content-start">
        {
          <TextField
            fullWidth
            type="number"
            sx={{ maxWidth: "40%" }}
            value={tempCrowdCOunt}
            label="Crowd Threshold"
            onChange={handleCrowdCountChange}
            helperText={
              tempCrowdCOunt.length == 0
                ? "Please enter a valid Count Threshold"
                : "Set the minimum number of people for alerts"
            }
            margin="normal"
            error={tempCrowdCOunt < 1}
          />
        }
      </div>

      {times.map((time, i) => (
        <FormGroup key={time.day}>
          <div className="row mt-3">
            <div className="time-range-input-field col-12 col-sm-12 col-xl-3 d-flex justify-content-start">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={time.active}
                    onChange={() => {
                      const newTimes = [...times];
                      newTimes[i].active = !newTimes[i].active;
                      setTimes(newTimes);
                    }}
                  />
                }
                label={time.day}
              />
            </div>
            <div className="time-range-input-field col-12 col-sm-5 col-xl-3 mx-sm-2 mt-2">
              <TextField
                label="Start Time"
                type="time"
                value={time.startTime}
                fullWidth
                onChange={(e) => {
                  const newTimes = [...times];
                  newTimes[i].startTime = e.target.value;
                  setTimes(newTimes);
                }}
              />
            </div>
            <div className="time-range-input-field col-12 col-sm-5 col-xl-3 mt-2">
              <TextField
                label="End Time"
                type="time"
                fullWidth
                value={time.endTime}
                onChange={(e) => {
                  const newTimes = [...times];
                  newTimes[i].endTime = e.target.value;
                  setTimes(newTimes);
                }}
              />
            </div>
          </div>
        </FormGroup>
      ))}
    </div>
  );
};

CrowdDetectionCameraWise.propTypes = {
  camera: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  changeData: PropTypes.func.isRequired,
};

export default CrowdDetectionCameraWise;
