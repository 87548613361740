import React, { useEffect, useState } from "react";
import FacilityProfileTable from "./FacilityProfileTable";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import axios from "axios";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import { Bars } from "react-loader-spinner";
const FacilityProfileDetails = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);

  // Include dispatch as a dependency
  const [selectedUserProfile, setSelectedUP] = useState(null);
  const [userProfileList, SetUserProfileList] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [loading, setLoading] = useState(false);
  async function getUserProfileList() {
    try {
      var token = localStorage.getItem("token");
      const response1 = await axios.get(
        OperationDashEndpoints.getAllUserProfiles(),
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      const accountData = [
        { label: "All", value: 0 },
        ...response1.data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      ];

      SetUserProfileList(accountData);
    } catch (error) {
      console.error("Error fetching account data:", error);
      throw error;
    }
  }
  async function getUserList() {
    try {
      var token = localStorage.getItem("token");
      setLoading(true);
      if (searchItem.length == 0 && !selectedUserProfile) {
        const response = await axios.get(
          OperationDashEndpoints.getAllFacilityProfiles(),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        setUserList(response.data);
      } else if (searchItem.length > 2) {
        const response = await axios.get(
          OperationDashEndpoints.seachFacilityProfileFromAll(searchItem),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        setUserList(response.data);
      } else if (selectedUserProfile && selectedUserProfile.value) {
        const response = await axios.get(
          OperationDashEndpoints.getFPmappingByUserProfile(
            selectedUserProfile.value
          ),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        setUserList(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setUserList([]);
      console.error("Error fetching user data:", error);
      throw error;
    }
  }
  // console.log(loading);
  useEffect(() => {
    getUserList();
    getUserProfileList();
    dispatch(changeTopbarTitle("Admin/Facility Profile"));
  }, []);
  useEffect(() => {
    getUserList();
  }, [searchItem, selectedUserProfile]);
  function handleUserProfileChange(val) {
    setSelectedUP(val.value ? val : null);
  }
  return (
    <div className="config-page-container">
      <div className="row">
        <div className=" mb-3 col-6">
          <AutocompleteCompUncontrollable
            option={userProfileList}
            onChange={handleUserProfileChange}
            label="User Profile"
            disableClearable={true}
            showValue={true}
          />
        </div>
      </div>
      {!loading ? (
        <FacilityProfileTable data={userList} getUserList={getUserList} />
      ) : (
        <>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "50vh" }}
          >
            <Bars
              height="80"
              width="80"
              color="black"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FacilityProfileDetails;
