import React, { useEffect } from "react";
import DeviceListTable from "./DeviceTable";
import "./deviceConfig.css";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import ConfigureRHMS from "./ConfigureRHMS/ConfigureRHMS";
const DeviceList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTopbarTitle("Device List"));
  }, []);
  return (
    <div>
      <div className="main-page-layout-container-device-config">
        <ConfigureRHMS />
      </div>
    </div>
  );
};

export default DeviceList;
