import React from "react";
import { useEffect, useState } from "react";
import { RouteType } from "../../routes/config";
import { useSelector } from "react-redux";
import {
  ListItemButton,
  ListItemIcon,
  Toolbar,
  ListItemText,
  Typography,
  Collapse,
  List,
} from "@mui/material";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SidebarItem from "./SidebarItem";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

type Props = {
  item: RouteType,
};

const SidebarItemCollapse = ({ item }: Props) => {
  const screenWidth = window.innerWidth;
  const state = useSelector((state) => state);
  const toggle = state.commonData.toggleSidebar;
  const [open, setOpen] = useState(false);
  function handleEvent() {
    setOpen(!open);
  }
  return item.sidebarProps ? (
    <>
      <ListItemButton
        onMouseUp={() => setOpen(!open)}
        sx={{
          "&: hover": {
            backgroundColor: "var(--top-bar-bg-color)",
          },
          paddingY: "12px",
          paddingX: "24px",
        }}
      >
        <ListItemIcon
          sx={{
            color: "var(--card-text-color)",
            margin: "0px",
          }}
          style={{ margin: 0 }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        {toggle ? (
          <div className="showIcon">
            {open ? (
              <ExpandLessOutlinedIcon
                sx={{
                  color: "var(--card-text-color)",
                }}
              />
            ) : (
              <ExpandMoreOutlinedIcon
                sx={{
                  color: "var(--card-text-color)",
                }}
              />
            )}
          </div>
        ) : null}

        {!toggle && (
          <ListItemText
            onDoubleClick={() => setOpen(!open)}
            disableTypography
            primary={
              <h5 style={{ margin: "0px", padding: "0px" }}>
                {item.sidebarProps.displayText}
              </h5>
            }
          />
        )}
        {toggle ? null : open ? (
          <ExpandLessOutlinedIcon
            sx={{
              color: "var(--card-text-color)",
            }}
          />
        ) : (
          <ExpandMoreOutlinedIcon
            sx={{
              color: "var(--card-text-color)",
            }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List sx={{ paddingY: "0px", paddingX: toggle ? "0px" : "20px" }}>
          {item.child?.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
      </Collapse>
    </>
  ) : null;
};

export default SidebarItemCollapse;
