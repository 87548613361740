import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../assets/css/main.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./approval.css";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import Button from "@mui/material/Button";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import OperationModeAction from "./../../redux/Action/OperationModeAction";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import AutocompleteComp from "../../components/common/Autocomplete";
import axios from "axios";
import OperationDashEndpoints from "../../redux/features/OperationDashEndpoints";
const filter = createFilterOptions();
const alertType = [
  { value: "human_alert", label: "Human Alert" },
  { value: "cameras_offline_alert", label: "Camera Offline Alert" },
  { value: "thermal_alert", label: "Thermal Alert" },
  { value: "mask_alert", label: "Mask Alert" },
  { value: "hdd_not_recording", label: "HDD Less Recording Alert" },
  { value: "nvr_offline_alert", label: "Nvr Offline Alert" },
  { value: "hdd_health", label: "HDD Health Alert" },
  { value: "soc_dis_alert", label: "Social Distancing Alert" },
  { value: "headgear_alert", label: "Headgear Alert" },
  { value: "handwash_alert", label: "Handwash Alert" },
  { value: "blackout_alert", label: "Blackout Alert" },
  { value: "angle_shift_alert", label: "Angle Shift Alert" },
  { value: "blur_image_alert", label: "Blur Image Alert" },
];
const eventType = [
  { label: "Compliance Issue", value: "compliance_issue" },
  { label: "Open Box", value: "open_box" },
  { label: "Pallet Not Used", value: "pallet_not_use" },
  { label: "Box On Shoulder", value: "box_on_shoulder" },
  { label: "Box Mishandled", value: "box_mishandled" },
  { label: "Uncovered Truck", value: "uncovered_truck" },
  { label: "Standing / Sitting", value: "standing_sitting" },
  { label: "Security Issue", value: "security_thread" },
  { label: "Supervisor Not Present", value: "supervisor_not_present" },
  { label: "Facility Status", value: "facility_status" },
  { label: "Facility Open", value: "facility_open" },
  { label: "Facility Closed", value: "facility_closed" },
  { label: "Loading Status", value: "loading_status" },
  { label: "Loading", value: "loading" },
  { label: "Unloading", value: "unloading" },
  { label: "Vehicle Alert", value: "vehicle_alert" },
  { label: "Dock In", value: "dock_in" },
  { label: "Dock Out", value: "dock_out" },
  { label: "other", value: "other" },
];
const ApprovalCardDetailEdit = (propType) => {
  const item = propType.item;
  function getAlertType(alert) {
    if (propType.isEvent) {
      return eventType.find((option) => option.value === alert);
    } else {
      return alertType.find((option) => option.value === alert);
    }
  }
  //alert or events type
  const [newAlertType, setNewAlertType] = useState(
    propType.isEvent
      ? getAlertType(item.event_type)
      : getAlertType(item.alert_type)
  );

  function extractTime(timestamp) {
    if (timestamp) {
      // Split the timestamp into date and time parts
      const [datePart, timePart] = timestamp.split("T");

      // Split the time part into hours, minutes, and seconds
      const [hours, minutes, seconds] = timePart.split(":");
      // Return the time in hh:mm format
      return `${hours}:${minutes}`;
    } else {
      return `00:00`;
    }
  }

  function modifyDate(dateString, timeString) {
    // Create a new Date object using the date
    const dateObj = new Date(dateString);

    // Extract the individual time components
    const [hours, minutes] = timeString.split(":").map(Number); // convert to numbers

    // Apply timezone offset
    const offset = dateObj.getTimezoneOffset();
    const hourOffset = Math.floor(offset / 60);
    const minuteOffset = offset % 60;
    const localHours = hours - hourOffset - 1;
    const localMinutes = minutes - minuteOffset;

    // Set the extracted time components to the date object
    dateObj.setHours(localHours, localMinutes);
    const isoString = dateObj.toISOString().replace(/\.\d+Z$/, "Z");
    // Convert the date object to ISO 8601 format
    // const isoString = dateObj.toISOString();

    return isoString;
  }

  const [alert_message, setAlertMessage] = useState(
    propType.isEvent ? item.event_message : item.alert_message
  );
  const [itemRemark, setItemRemark] = useState(item.remark);
  const [date_value, setDateValue] = useState(
    dayjs(propType.isEvent ? item.event_recieved_at : item.alert_recieved_at)
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date(
      new Date(
        propType.isEvent ? item.event_recieved_at : item.alert_recieved_at
      ).toLocaleString("en-US", {
        timeZone: "GMT",
      })
    )
  );

  const [time, setTime] = useState(
    extractTime(
      propType.isEvent
        ? propType.item.event_recieved_at
        : propType.item.alert_recieved_at
    )
  );
  const handleDateChange = (date) => {
    setSelectedDate(date); // update local state
  };
  const state = useSelector((state) => state.Approval);
  const configData = useSelector((state) => state.configData);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedFacility, SetSelectedFacilty] = useState(null);

  const [selectedCamera, setSelectedCamera] = useState(null);
  const faclilityList = useSelector(
    (state) => state.Approval.facilityByAccountList
  );
  const cameraList = useSelector((state) => state.Approval.cameraList);
  const dispatch = useDispatch();
  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
  const handleAccountChange = (event, value) => {
    if (value) {
      setSelectedAccount(value);
      dispatch(OperationModeAction.getFacilitiesByAccountId(value.value));
    }
  };
  function handleAlertTypeChange(event, value) {
    if (value) {
      setNewAlertType(value);
    }
  }
  const handleCameraChange = (event, value) => {
    if (value) {
      setSelectedCamera(value);
    }
  };
  const handleFacilityChange = (event, value) => {
    if (value) {
      SetSelectedFacilty(value);
      dispatch(OperationModeAction.getCamerasByStore(value.value));
    }
  };
  const handleAlertMessageChange = (event, newValue) => {
    if (typeof newValue === "string") {
      setAlertMessage(newValue);
    } else if (newValue) {
      // Create a new value from the user input
      setAlertMessage(newValue);
    } else {
      setAlertMessage(newValue);
    }
  };

  const handleRemarkChange = (e) => {
    setItemRemark(e.target.value);
  };

  useEffect(() => {
    // Check if account list is populated and propType has the required data
    if (
      state.accountList.length > 0 &&
      propType.item.facility__account__account_id
    ) {
      const initialSelectedAccount = state.accountList.find(
        (account) =>
          account.value === propType.item.facility__account__account_id
      );
      setSelectedAccount(initialSelectedAccount);
    }
  }, [state.accountList, propType.item]);
  useEffect(() => {
    // Check if account list is populated and propType has the required data
    if (faclilityList && faclilityList.length > 0 && propType.item.facility) {
      const initialSelectedFacility = faclilityList.find(
        (account) => account.value === propType.item.facility
      );
      SetSelectedFacilty(initialSelectedFacility);
      // if (!initialSelectedFacility) {
      //   dispatch(OperationModeAction.getCamerasByStore(faclilityList[0].value));
      // }
    }
  }, [faclilityList, propType.item]);
  useEffect(() => {
    // Check if account list is populated and propType has the required data
    if (cameraList && cameraList.length > 0 && propType.item.camera) {
      const initialSelectedCamera = cameraList.find(
        (account) => account.value === propType.item.camera
      );
      setSelectedCamera(initialSelectedCamera);
    }
  }, [cameraList, propType.item]);
  //states and function related to aprroved or rejected button
  const [isApproved, setApproved] = useState(false);
  const [isRejecetd, setRejecetd] = useState(false);
  async function handleApproved(id) {
    if (isRejecetd) {
      removeItemFromLocalReject(id);
      setRejecetd(false);
    }

    if (await handleApproveSave()) {
      var arr = JSON.parse(localStorage.getItem("approvedItems")) ?? [];
      arr.push(id); // Add the id to the array
      localStorage.setItem("approvedItems", JSON.stringify(arr)); // Store the updated array
      setApproved(true);

      if (state.currentIndex != state.alertEventCard.length - 1) {
        dispatch(OperationModeAction.updatecurrentPage(state.currentIndex + 1));
      }
    }
  }

  async function handleReject(id) {
    if (isApproved) {
      removeItemFromLocalApproved(id);
      setApproved(false);
    }

    if (await handleRejectSave()) {
      var arr = JSON.parse(localStorage.getItem("rejectedItems")) ?? [];
      arr.push(id); // Add the id to the array
      localStorage.setItem("rejectedItems", JSON.stringify(arr));
      setRejecetd(true);

      if (state.currentIndex != state.alertEventCard.length - 1) {
        dispatch(OperationModeAction.updatecurrentPage(state.currentIndex + 1));
      }
    }
  }

  function removeItemFromLocalApproved(id) {
    var arr = JSON.parse(localStorage.getItem("approvedItems")) ?? [];

    // Find the index of the item with the given ID
    const index = arr.indexOf(id);

    if (index !== -1) {
      // Remove the item from the array
      arr.splice(index, 1);

      // Store the updated array back to localStorage
      localStorage.setItem("approvedItems", JSON.stringify(arr));
    }
  }
  function removeItemFromLocalReject(id) {
    var arr = JSON.parse(localStorage.getItem("rejectedItems")) ?? [];

    // Find the index of the item with the given ID
    const index = arr.indexOf(id);

    if (index !== -1) {
      // Remove the item from the array
      arr.splice(index, 1);

      // Store the updated array back to localStorage
      localStorage.setItem("rejectedItems", JSON.stringify(arr));
    }
  }
  function checkStatusApproved(id) {
    let arr = JSON.parse(localStorage.getItem("approvedItems")) ?? [];
    return arr.includes(id);
  }

  function checkStatusRejected(id) {
    let arr = JSON.parse(localStorage.getItem("rejectedItems")) ?? [];
    return arr.includes(id);
  }
  function isCameraPresent() {
    let flag = cameraList.some((item) => item.value === selectedCamera.value);
    return flag;
  }
  function isFacilityPresent() {
    let flag = faclilityList.some(
      (item) => item.value === selectedFacility.value
    );
    return flag;
  }
  useEffect(() => {
    setNewAlertType(
      propType.isEvent
        ? getAlertType(item.event_type)
        : getAlertType(item.alert_type)
    );
    setItemRemark(propType.item.remark);
    setAlertMessage(
      propType.isEvent
        ? propType.item.event_message
        : propType.item.alert_message
    );
    setTime(
      extractTime(
        propType.isEvent
          ? propType.item.event_recieved_at
          : propType.item.alert_recieved_at
      )
    );
    setApproved(checkStatusApproved(propType.item.id));
    setRejecetd(checkStatusRejected(propType.item.id));
    setSelectedDate(
      new Date(
        new Date(
          propType.isEvent ? item.event_recieved_at : item.alert_recieved_at
        ).toLocaleString("en-US", {
          timeZone: "GMT",
        })
      )
    );
    setSelectedAccount(
      state.accountList.find(
        (account) =>
          account.value == propType.item.facility__account__account_id
      )
    );
    SetSelectedFacilty(
      faclilityList.find((account) => account.value === propType.item.facility)
    );
    setSelectedCamera(
      cameraList.find((account) => account.value === propType.item.camera)
    );
  }, [propType.item]);
  async function updateAlertEventCardStatus(data) {
    try {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      const response = await axios.post(
        OperationDashEndpoints.modifyStatusForAlertEventCard(),
        data,
        {
          headers,
        }
      );

      if (response.status === 200) {
        return true; // API call was successful, return true
      } else {
        console.error("Error updating card status: Unexpected response status");
        return false; // API call failed, return false
      }
    } catch (err) {
      console.error("Error updating card status:", err);
      return false; // If an error occurs, return false
    }
  }
  async function handleSave(isApprove) {
    if (!selectedCamera) {
      notifiToasterWarn("Please Select Camera");
      setSelectedCamera(propType.item.camera);
      return false;
    } else if (!selectedFacility) {
      notifiToasterWarn("Please Select Facility");
      setSelectedCamera(propType.item.facility);
      return false;
    } else if (isCameraPresent() === false) {
      notifiToasterWarn("Camera and facility do not match");
      return false;
    } else if (isFacilityPresent() === false) {
      notifiToasterWarn("Camera and facility do not match");
      return false;
    } else if (!selectedAccount) {
      notifiToasterWarn("Please Select Account");
      return false;
    } else if (!alert_message || alert_message.length == 0) {
      notifiToasterWarn("Please Enter the message");
      setAlertMessage(
        propType.isEvent ? item.event_message : item.alert_message
      );
      return false;
    }

    try {
      const changedData = {
        alert_event_id: propType.item.id,
        is_accepted: isApprove ? true : false,
        is_seen: true,
        type: propType.isEvent ? "event" : "alert",
        date_time: modifyDate(selectedDate, time),
        remark: itemRemark,
        message: alert_message,
        alert_event_type: newAlertType.value,
        camera: selectedCamera.value,
        facility: selectedFacility.value,
      };

      const commonProperties = {
        ...propType.item,
        camera: selectedCamera.value,
        facility: selectedFacility.value,
        is_seen: true,
        remark: itemRemark,
        status: true,
        visibility: isApprove ? true : false,
        facility__account__account_id: selectedAccount.value,
      };

      const updatedItem = propType.isEvent
        ? {
            ...commonProperties,
            event_message: alert_message,
            event_recieved_at: modifyDate(selectedDate, time),
            event_type: newAlertType.value,
          }
        : {
            ...commonProperties,
            alert_message: alert_message,
            alert_recieved_at: modifyDate(selectedDate, time),
            alert_type: newAlertType.value,
          };

      const response = await updateAlertEventCardStatus(changedData);
      if (response) {
        notifiToasterSuccess("Card Status Updated Successfully");

        dispatch(
          OperationModeAction.updateAlertEventCardAtIndex({
            index: state.currentIndex,
            newData: {
              type: propType.isEvent ? "event" : "alert",
              data: updatedItem,
            },
          })
        );
        return true; // API call was successful, return true
      } else {
        notifiToasterWarn("Failed to update card");
        return false; // API call failed, return false
      }
    } catch (error) {
      console.log(error);
      notifiToasterWarn("Failed to update card");
      return false; // Error occurred, return false
    }
  }

  async function handleApproveSave() {
    return await handleSave(true);
  }

  async function handleRejectSave() {
    return await handleSave(false);
  }

  return (
    <div>
      <div className="profile-detail-form">
        <div className="row">
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <AutocompleteComp
              option={propType.isEvent ? eventType : alertType}
              onChange={handleAlertTypeChange}
              value={newAlertType}
              label={"Alert/Event Type"}
              showValue={true}
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <DatePicker
              className="form-control-date"
              customInput={<input className="custom-input-date-text" />}
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <input
              type="time"
              className="form-control"
              id="alert_time"
              name="alert_time"
              placeholder="Alert Time"
              value={time}
              onChange={handleTimeChange}
              style={{
                backgroundColor: "var(--sidebar-bg-color)",
                height: "55px",
                marginBottom: "10px",
              }}
            />
          </div>
          {state.accountList.length > 0 && selectedAccount ? (
            <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
              <AutocompleteComp
                option={state.accountList}
                onChange={handleAccountChange}
                value={
                  selectedAccount ?? { label: "Select Account", value: -1 }
                }
                label={"Account"}
                showValue={true}
              />
            </div>
          ) : null}
          {faclilityList.length > 0 && (
            <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
              <AutocompleteComp
                option={faclilityList}
                onChange={handleFacilityChange}
                value={
                  selectedFacility ?? { label: "Select Facility", value: -2 }
                }
                label={"Facility"}
                showValue={true}
              />
            </div>
          )}
          {cameraList.length > 0 && (
            <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
              <AutocompleteComp
                option={cameraList}
                onChange={handleCameraChange}
                value={selectedCamera ?? { label: "Select Camera", value: -3 }}
                label={"Camera"}
                showValue={true}
              />
            </div>
          )}
          <div className="form-group col-12 col-lg-6 mb-3 ">
            <div className="row form-content-edit-data remark-input-field">
              <TextField
                className="col"
                id="outlined-basic"
                label="Remark"
                variant="outlined"
                value={itemRemark}
                onChange={handleRemarkChange}
              />
            </div>
          </div>
          <div className="form-group col-12 col-lg-6 mb-3">
            <Autocomplete
              value={alert_message}
              onChange={handleAlertMessageChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={configData.enteredMessages}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option;
              }}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Alert/Event Message" />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Button
              variant="contained"
              endIcon={<DoneAllIcon />}
              onClick={() => handleApproved(propType.item.id)}
              disabled={isApproved}
            >
              Approve
            </Button>
          </div>
          <div className="col">
            {" "}
            <Button
              variant="contained"
              endIcon={<CloseIcon />}
              sx={{ backgroundColor: "red" }}
              disabled={isRejecetd}
              onClick={() => handleReject(propType.item.id)}
            >
              Reject
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
ApprovalCardDetailEdit.propTypes = {
  item: PropTypes.object.isRequired,
  isEvent: PropTypes.bool.isRequired,
};
export default ApprovalCardDetailEdit;
