import axios from "axios";
import ApiSpec from "../features/EndPoints";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";

// get profile details

export const getProfileDetail = () => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios.get(ApiSpec.getuserprofile, { headers }).then((response) => {
      dispatch({ type: "PROFILE_DATA", payload: response.data });
    });
  };
};

// get Profile

export const getProfile = () => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios.get(ApiSpec.getuserprofile, { headers }).then((response) => {
      dispatch({ type: "PROFILE_DATA", payload: response.data });
    });
  };
};
export const saveProfileDataToRedux = (data) => {
  return async (dispatch) => {
    dispatch({ type: "PROFILE_DATA", payload: data });
  };
};
// update profile of the user

export const updateProfileDetail = (data) => {
  let form = data.formData;
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .put(ApiSpec.getuserprofile, form, { headers })
      .then((response) => {
        dispatch({ type: "PROFILE_DATA", payload: response.data });
        notifiToasterSuccess("Profile updated");
      })
      .catch((erro) => {
        notifiToasterWarn("Not Updated");
      });
  };
};

// get user Setting data

export const getUserSettingData = (data) => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    var profile = JSON.parse(localStorage.getItem("profile"));
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(ApiSpec.getProfileSetting(data.account_id, profile.profile_type), {
        headers,
      })
      .then((response) => {
        dispatch({ type: "USER_SETTING_DATA", payload: response.data });
      })
      .catch((erro) => {
        notifiToasterWarn("Failed to Fetch ");
      });
  };
};

// update userSetting

export const updateUserSetting = (data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .put(ApiSpec.updateProfileSetting(data.id), data, { headers })
      .then((response) => {
        notifiToasterSuccess("updated");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("Not Updated");
        reject(error);
      });
  });
};
// get userSetting profile data by account id

export const getProfileDetailsByAccountId = (data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(ApiSpec.updateProfileSetting(data.id), { headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// delete user

export const deleteUserSetting = (data) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .delete(ApiSpec.updateProfileSetting(data.id), { headers })
      .then((response) => {
        notifiToasterSuccess("Deleted");
        resolve(response);
      })
      .catch((error) => {
        notifiToasterWarn("Not Deleted");
        reject(error);
      });
  });
};

// send ivite to user

export const sendInvite = (data) => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(ApiSpec.getuserprofile, { data }, { headers })
      .then((response) => {
        dispatch({ type: "USER_SETTING_DATA", payload: response.data });
      })
      .catch((erro) => {
        notifiToasterWarn("Not Updated");
      });
  };
};

// get user Group data

export const getUserGroupData = (data) => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(ApiSpec.getuserprofile, { data }, { headers })
      .then((response) => {
        dispatch({ type: "USER_SETTING_DATA", payload: response.data });
      })
      .catch((erro) => {
        notifiToasterWarn("Not Updated");
      });
  };
};
