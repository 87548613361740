import axios from "axios";
import ApiSpec from "../features/EndPoints";
// import Swal from "sweetalert2";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
// Login Function

export const getNewUserDetails = () => {
  var token = localStorage.getItem("token");
  let headers = {
    Authorization: `Token ${token}`,
  };
  axios.get(ApiSpec.getuserprofile, { headers }).then(async (response) => {
    localStorage.setItem("profile", JSON.stringify(response.data));
    localStorage.setItem("profile_id", JSON.stringify(response.data.id));
  });
};
export const fetchAccessToken = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(ApiSpec.login, {
        email: data.username,
        password: data.password,
      })
      .then(async (response) => {
        localStorage.setItem("token", response.data.token);
        var token = localStorage.getItem("token");
        await localStorage.setItem("isAuthticated", JSON.stringify(true));
        let headers = {
          Authorization: `Token ${token}`,
        };
        axios
          .get(ApiSpec.getuserprofile, { headers })
          .then(async (response) => {
            await localStorage.setItem(
              "profile",
              JSON.stringify(response.data)
            );
            await localStorage.setItem(
              "profile_id",
              JSON.stringify(response.data.id)
            );

            const firstTrueKey = Object.keys(response.data).find(
              (key) => response.data[key] === true
            );
            resolve(firstTrueKey);
          });
      })
      .catch((error) => {
        let error2 = "";
        if (error.message.includes("400")) {
          error2 = "invalid";
          // fireErrorAlert("Invalid Password or Username.");
        } else {
          error2 = "server";
        }
        reject(error2);
      });
  });
};

export const logout = (data) => {
  return async (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthticated");
    localStorage.removeItem("profile");
    localStorage.removeItem("profile_id");
    sessionStorage.removeItem("mychats");
    window?.socket?.disconnect();
    dispatch({
      type: "RESET_RHMS_REDUCER",
    });

    dispatch({
      type: "LOGOUT_USER",
    });
    // notifiToasterSuccess("Logout Successfulyy!!");
  };
};

// get Forget password token and uid

export const forgetPassToken = (data) => {
  return async (dispatch) => {
    axios
      .post(ApiSpec.forgetPassword, data)
      .then((response) => {
        dispatch({ type: "FORGET_PASS_DATA_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        notifiToasterWarn(error.response.data.detail);
      });
  };
};

// chage forget Password

export const changePassword = (data) => {
  return async (dispatch) => {
    axios
      .post(ApiSpec.forgetPassSubmit(data.data.uid, data.data.token), data.obj)
      .then((response) => {
        dispatch({
          type: "FORGET_PASS_SUCCESS_FLAG",
        });
        notifiToasterSuccess(response.data.detail);
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message.includes("400")) {
          notifiToasterWarn(error.response.data.detail[0]);
        } else {
          notifiToasterWarn(error.response.data.detail);
        }
      });
  };
};

// email verification
const fireErrorAlert = (title, text) => {
  window.alert(title);
};
