import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import OperationDashEndpoints from "../features/OperationDashEndpoints";
import axios from "axios";

const token = localStorage.getItem("token");

export const fetchAccountsforGroup = async () => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getAccountforAccessControl(),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    const accountData = response.data.map((item) => ({
      label: item.name,
      value: item.account_id,
    }));
    return accountData;
  } catch (error) {
    console.error("Error fetching account data:", error);
    return [];
  }
};
// get account List
export const fetchAccounts = async () => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getAccountforAccessControlforConnectivity(),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    const accountData = response.data.data.map((item) => ({
      label: item.name,
      value: item.account_id,
    }));
    return accountData;
  } catch (error) {
    console.error("Error fetching account data:", error);
    return [];
  }
};

// get user List
export const fetchUserDetails = async (id) => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getUserByAccountId(id),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    const userOptionsList = response.data.data.map((item) => ({
      label: item.username,
      value: item.id,
    }));
    return userOptionsList;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return [];
  }
};

// get user group
export const fetchGroupDetails = async (userId) => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getGroupByUserId(userId),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    const groupOptionsList = response.data.map((item) => ({
      label: item.group_name,
      value: item.id,
    }));
    return groupOptionsList;
  } catch (error) {
    console.error("Error fetching group data:", error);
    return [];
  }
};

// get all facility
export const fetchAllFacility = async () => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getAllFacilities(),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    const facilityOptionsList = response.data.map((item) => ({
      label: item.facility_name,
      value: item.id,
    }));
    return facilityOptionsList;
  } catch (error) {
    console.error("Error fetching facilities data:", error);
    return [];
  }
};

// get all nvrlist connected to facility
export const getNvrAndDeviceDetailsUsingFacilityIds = async (
  facilityIds,
  source
) => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getDeviceOrNVRsFromFacilityUrl(
        facilityIds,
        source
      ),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    const nvrOptionList = response.data.data.map((item) => ({
      label: item.value,
      value: item.id,
    }));
    return nvrOptionList;
  } catch (error) {
    console.error("Error fetching NVR data:", error);
    return [];
  }
};

// get cameras list from NVR IDs
export const getCamerasListfromNvrIds = async (nvr_ids) => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getCameraFromNvrForAccessControlUrl(nvr_ids),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    const cameraOptionList = response.data.data.map((item) => ({
      label: item.value,
      value: item.id,
    }));
    return cameraOptionList;
  } catch (error) {
    console.error("Error fetching camera data:", error);
    return [];
  }
};

// update the account and group
export const updateGroupfromAccountIds = async (
  acc_id,
  group_id,
  data,
  remove_mapping
) => {
  try {
    await axios.patch(
      OperationDashEndpoints.updateGroupbyAccountIds(
        acc_id,
        group_id,
        remove_mapping
      ),
      data,
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
    notifiToasterSuccess("Group updated successfully");
  } catch (error) {
    console.error("Error updating group details:", error);
    notifiToasterWarn("Something went wrong, please refresh the page");
  }
};

// get facility, NVR, and camera by group IDs
export const getFacilityNvrCameraByGroupIds = async (group_id) => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getFacilityCameraNvrUrl(group_id),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching facility, NVR, and camera data:", error);
    notifiToasterWarn("Something went wrong, please refresh the page");
    return null;
  }
};

export const fetchFacilityByAccountId = async (id) => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getFacilityByAccount(id),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
    const FacilityData = response.data.data.map((item) => ({
      label: item.facility_name,
      value: item.id,
    }));
    return FacilityData;
  } catch (error) {
    console.error("Error fetching account data:", error);
    return [];
  }
};

export const fetchNvrByFacilityId = async (id) => {
  try {
    const response = await axios.get(
      OperationDashEndpoints.getNVRByFacility(id),
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching account data:", error);
    return [];
  }
};
