import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  fetchAccounts,
  fetchFacilityByAccountId,
  fetchNvrByFacilityId,
} from "../../redux/Action/AccessControlMappingAction";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";

const FacilityByAccount = ({ setNvrFromFilter }) => {
  const dispatch = useDispatch();
  const [accounts, setAccounts] = useState([]);
  const [facility, setFacility] = useState([]);
  const [nvr, setNvr] = useState([]);
  const [nvrListWithDetails, setNvrListWithDetails] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(
    JSON.parse(sessionStorage.getItem("selectedAccount")) || null
  );
  const [selectedFacility, setSelectedFacility] = useState(
    JSON.parse(sessionStorage.getItem("selectedFacility")) || null
  );
  const [selectedNvr, setSelectedNvr] = useState(
    JSON.parse(sessionStorage.getItem("selectedNvr")) || null
  );

  useEffect(() => {
    getAccountData();
    dispatch(changeTopbarTitle("Connectivity Tester"));

    // Load facility and NVR options if there are saved selections
    if (selectedAccount) getFacilityData(selectedAccount.value);
    if (selectedFacility) getNvrData(selectedFacility.value);
  }, [dispatch]);

  const getAccountData = async () => {
    const accountList = await fetchAccounts();
    setAccounts(accountList);
  };

  const getFacilityData = async (id) => {
    const facilityList = await fetchFacilityByAccountId(id);
    setFacility(facilityList);
    setNvr([]); // Reset NVR options when facility changes
    setSelectedFacility(null); // Clear selected facility
    setSelectedNvr(null); // Clear selected NVR
  };

  const getNvrData = async (id) => {
    const NvrList = await fetchNvrByFacilityId(id);
    const FacilityData = NvrList.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    setNvr(FacilityData);
    setNvrListWithDetails(NvrList);
    setSelectedNvr(null); // Clear selected NVR on facility change
  };

  const handleAccountChange = async (event, value) => {
    setSelectedAccount(value);
    sessionStorage.setItem("selectedAccount", JSON.stringify(value));
    if (value && value.value) {
      await getFacilityData(value.value);
    } else {
      setFacility([]);
      setNvr([]);
      setSelectedFacility(null);
      setSelectedNvr(null);
      sessionStorage.removeItem("selectedFacility");
      sessionStorage.removeItem("selectedNvr");
    }
  };

  const handleFacilityChange = async (event, value) => {
    setSelectedFacility(value);
    sessionStorage.setItem("selectedFacility", JSON.stringify(value));
    if (value && value.value) {
      await getNvrData(value.value);
    } else {
      setNvr([]);
      setSelectedNvr(null);
      sessionStorage.removeItem("selectedNvr");
    }
  };

  const handleNvrChange = (event, value) => {
    setSelectedNvr(value);
    sessionStorage.setItem("selectedNvr", JSON.stringify(value));
    if (value) {
      setNvrFromFilter(
        nvrListWithDetails.find((item) => item.id === value.value)
      );
    } else {
      setNvrFromFilter(null);
    }
  };

  const handleClearFilter = () => {
    // Clear only the selected states and session storage without modifying `accounts`
    setSelectedAccount(null);
    setSelectedFacility(null);
    setSelectedNvr(null);
    setFacility([]);
    setNvr([]);
    setNvrListWithDetails([]);
    setNvrFromFilter(null);

    // Clear session storage
    sessionStorage.removeItem("selectedAccount");
    sessionStorage.removeItem("selectedFacility");
    sessionStorage.removeItem("selectedNvr");
  };

  return (
    <div className="config-page-container">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={accounts}
            value={selectedAccount}
            onChange={handleAccountChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Account" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={facility}
            renderOption={(props, option) => (
              <li {...props} key={`${option.label}-${option.value}`}>
                {option.label}
              </li>
            )}
            value={selectedFacility}
            onChange={handleFacilityChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Facility" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={nvr}
            value={selectedNvr}
            onChange={handleNvrChange}
            renderInput={(params) => (
              <TextField {...params} label="Select NVR" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
          <Button
            onClick={handleClearFilter}
            sx={{ p: 1.5 }}
            variant="outlined"
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

FacilityByAccount.propTypes = {
  setNvrFromFilter: PropTypes.func.isRequired,
};

export default FacilityByAccount;
