import React, { useEffect, useState } from "react";
import Textfield from "../../../components/formLayout/TextField";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { notifiToasterWarn } from "../../../components/common/UseToast";
import axios from "axios";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import { useDispatch } from "react-redux";
import OnboardingAction from "../../../redux/Action/OnboardingAction";
import PropTypes from "prop-types";
import getDataFromAPI from "../Utils/getDataFromAPI";
import AutocompleteComp from "../../../components/common/Autocomplete";
const initialState = {
  deviceName: "",
  remoteIotUnit: "",
  macId: "",
  ip: "",
  password: "",
  userName: "",
  notes: "",
  facilityInstalledAt: null,
  typeOfFeature: "",
  typeOfHardware: "",
  dateOfInstallation: null,
  deviceType: "",
};
const HARDWARE_TYPE = [
  { value: "1", label: "ANALYTICS_BOX_CAMERA_2GB" },
  { value: "2", label: "ANALYTICS_BOX_CAMERA_4GB" },
  { value: "3", label: "ANALYTICS_BOX_SMALL" },
  { value: "4", label: "ANALYTICS_BOX_MEDIUM" },
  { value: "5", label: "ANALYTICS_BOX_LARGE" },
];
const hardwareList = [
  {
    ANALYTICS_BOX_CAMERA_2GB: "analytics_box_camera_2GB",
  },
  {
    ANALYTICS_BOX_CAMERA_4GB: "analytics_box_camera_4GB",
  },
  {
    ANALYTICS_BOX_SMALL: "analytics_box_small",
  },
  {
    ANALYTICS_BOX_MEDIUM: "analytics_box_medium",
  },
  {
    ANALYTICS_BOX_LARGE: "analytics_box_large",
  },
];

const featureList = [
  {
    label: "People_Count",
    value: "1",
  },
  {
    label: "Heatmap",
    value: "2",
  },
  {
    label: "Demographics",
    value: "3",
  },
  {
    label: "Passer_By",
    value: "4",
  },
  {
    label: "Logistics",
    value: "5",
  },
  {
    label: "Remote_Health",
    value: "6",
  },
  {
    label: "All",
    value: "7",
  },
];

const deviceList = [
  {
    label: "Cloud",
    value: "1",
  },
  {
    label: "Premises",
    value: "2",
  },
];

const AddDevice = ({ isEditable, id }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function getTypeOfItem(list, key) {
    const ans = list.filter((item) => item.label.toLowerCase() == key);
    if (ans) {
      return ans[0];
    } else return {};
  }
  useEffect(() => {
    setFormData({
      deviceName: deviceData.device_name,
      remoteIotUnit: deviceData.remoteiot_unit_id,
      macId: deviceData.mac_id,
      ip: deviceData.ip,
      password: deviceData.password,
      userName: deviceData.username,
      notes: deviceData.notes,
      facilityInstalledAt: getDataFromAPI.getSelectedItem(
        deviceData.facility_installed_at,
        facilityList
      ),
      typeOfFeature: getTypeOfItem(featureList, deviceData.type_of_feature),
      typeOfHardware: getTypeOfItem(HARDWARE_TYPE, deviceData.type_of_hardware),
      dateOfInstallation: deviceData.date_of_installation,
      deviceType: getTypeOfItem(deviceList, deviceData.device_type),
    });
  }, [deviceData, facilityList]);
  useEffect(() => {
    async function getDeviceDetails() {
      const ans = await getDataFromAPI.getDeviceDetailsById(id);
      setDeviceData(ans);
    }
    if (isEditable && id) {
      getDeviceDetails();
    }
  }, [id, isEditable]);
  const handleClick = () => {
    if (formData.deviceName.length < 4) {
      notifiToasterWarn("Please Enter Device Name Correctly");
      return;
    }

    if (!formData.facilityInstalledAt) {
      notifiToasterWarn("Please Select the Facility Installed At");
      return;
    }
    if (!formData.typeOfHardware) {
      notifiToasterWarn("Please Select the Type Of Hardware");
      return;
    }
    if (!formData.typeOfFeature) {
      notifiToasterWarn("Please Select the Type Of Feature");
      return;
    }
    if (!formData.deviceType) {
      notifiToasterWarn("Please Select the Device Type");
      return;
    }

    const deviceData = {
      device_name: formData.deviceName,
      remoteiot_unit_id: formData.remoteIotUnit,
      mac_id: formData.macId,
      ip: formData.ip,
      password: formData.password,
      username: formData.userName,
      notes: formData.notes,
      type_of_feature: formData.typeOfFeature.label.toLowerCase(),
      type_of_hardware: hardwareList[formData.typeOfHardware],
      date_of_installation: formData.dateOfInstallation,
      device_type: formData.deviceType.label.toLowerCase(),
      facility_installed_at: formData.facilityInstalledAt.value,
    };
    if (!isEditable) {
      dispatch(OnboardingAction.addDevice(deviceData))
        .then(() => {
          const facilityInstalledAt = formData.facilityInstalledAt;
          const harwareType = formData.typeOfHardware;
          const typeOfFeature = formData.typeOfFeature;
          const deviceType = formData.deviceType;
          setFormData(initialState);
          setFormData((prev) => ({
            ...prev,
            facilityInstalledAt: facilityInstalledAt,
            typeOfHardware: harwareType,
            typeOfFeature: typeOfFeature,
            deviceType: deviceType,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(OnboardingAction.updateDevice(id, deviceData));
    }
  };

  function getAllFacilitiesList() {
    var token = localStorage.getItem("token");
    axios
      .get(OperationDashEndpoints.getAllFacilities(), {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        const facilitiesData = response.data.map((item) => ({
          label: item.facility_name,
          value: item.id,
        }));
        setFacilityList(facilitiesData);
      });
  }
  useEffect(() => {
    getAllFacilitiesList();
  }, []);

  function handleFacilityChange(val) {
    if (val) {
      setFormData((prevData) => ({
        ...prevData,
        facilityInstalledAt: val,
      }));
    }
  }

  function handleHardwareChange(val) {
    if (val) {
      setFormData((prevData) => ({
        ...prevData,
        typeOfHardware: val,
      }));
    }
  }

  function handleFeatureChange(val) {
    if (val) {
      setFormData((prevData) => ({
        ...prevData,
        typeOfFeature: val,
      }));
    }
  }

  function handleDeviceChange(val) {
    if (val) {
      setFormData((prevData) => ({
        ...prevData,
        deviceType: val,
      }));
    }
  }
  return (
    <div className="container">
      <div>{!isEditable && <h2 className="mt-2 mb-4">Add Device:</h2>}</div>
      <div className="row">
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <Textfield
            label="Device name"
            value={formData.deviceName}
            onChange={handleChange}
            type="text"
            placeholder="Device name"
            name="deviceName"
            isrequired={true}
          />
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <Textfield
            label="Remoteiot unit id"
            value={formData.remoteIotUnit}
            onChange={handleChange}
            type="text"
            placeholder="Remoteiot unit id"
            name="remoteIotUnit"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <Textfield
            label="MAC ID"
            value={formData.macId}
            onChange={handleChange}
            type="text"
            placeholder="MAC ID"
            name="macId"
            isrequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <Textfield
            label="IP"
            value={formData.ip}
            onChange={handleChange}
            type="text"
            placeholder="IP"
            name="ip"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <Textfield
            label="User Name"
            value={formData.userName}
            onChange={handleChange}
            type="text"
            placeholder="User Name"
            name="userName"
            isrequired={false}
          />
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <Textfield
            label="Password"
            value={formData.password}
            onChange={handleChange}
            type="password"
            placeholder="Password"
            name="password"
            isrequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <Textfield
            label="Notes"
            value={formData.notes}
            onChange={handleChange}
            type="text"
            placeholder="Notes"
            name="notes"
            isrequired={true}
          />
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          {isEditable && formData.facilityInstalledAt ? (
            <AutocompleteComp
              option={facilityList}
              onChange={handleFacilityChange}
              value={formData.facilityInstalledAt}
              label="Facility Installed At:"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={facilityList}
              onChange={handleFacilityChange}
              label="Facility Installed At:"
            />
          )}
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          {isEditable && formData.typeOfHardware ? (
            <AutocompleteComp
              option={HARDWARE_TYPE}
              onChange={handleHardwareChange}
              value={formData.typeOfHardware}
              label="Type of Hardware:"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={HARDWARE_TYPE}
              onChange={handleHardwareChange}
              name="typeOfHardware"
              label="Type of Hardware:"
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          {isEditable && formData.typeOfFeature ? (
            <AutocompleteComp
              option={featureList}
              onChange={handleFeatureChange}
              value={formData.typeOfFeature}
              label="Type of Feature:"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={featureList}
              onChange={handleFeatureChange}
              name="typeOfFeature"
              label="Type of Feature:"
            />
          )}
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          <Textfield
            label="Date of Installation"
            value={formData.dateOfInstallation}
            onChange={handleChange}
            type="date"
            placeholder="Date of Installation"
            name="dateOfInstallation"
            isrequired={true}
          />
        </div>
        <div className="col-12 col-sn-6 col-lg-4 mb-3">
          {isEditable && formData.typeOfFeature ? (
            <AutocompleteComp
              option={deviceList}
              onChange={handleDeviceChange}
              value={formData.deviceType}
              label="Device Type"
              showValue={true}
            />
          ) : (
            <AutocompleteCompUncontrollable
              disableClearable={true}
              showValue={true}
              option={deviceList}
              onChange={handleDeviceChange}
              name="deviceType"
              label="Device Type:"
            />
          )}
        </div>
      </div>
      <div className="row mt-sm-3">
        <div className="col-12 col-sm-6 col-lg-3 mb-3">
          <Button
            variant="contained"
            onClick={handleClick}
            sx={{ margin: "2px", minWidth: "150px" }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : isEditable ? (
              "Update Device "
            ) : (
              "Add Device"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
AddDevice.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default AddDevice;
