import io from "socket.io-client";
import DesktopNotification from "./Desktopnotification";
// import { notifiToasterSuccess } from "../../components/common/UseToast";
import { notifiToasterSuccess } from "../../components/common/UseToast";

export const Layout = () => {
  const desktopNotification = new DesktopNotification();

  const profile = JSON.parse(localStorage.getItem("profile"));
  const profile_id = JSON.parse(localStorage.getItem("profile_id"));
  desktopNotification.checkPermission();
  desktopNotification.getUserPermission();

  function addSocketNotifications(data) {
    const notifs = localStorage.getItem("notifications");
    if (notifs == null) {
      localStorage.setItem("notifications", JSON.stringify([data]));
    } else {
      let parsedNotif = JSON.parse(notifs);
      if (parsedNotif.length == 20) {
        parsedNotif.pop();
      }
      parsedNotif.unshift(data);
      localStorage.setItem("notifications", JSON.stringify(parsedNotif));
    }
    // this.$store.dispatch("getSockNotifications");
  }

  // console.log(socket);
  const socket = io(`https://backendv2.agrexai.com`);
  window.socket = socket;
  socket.on("connect", () => {
    console.log("Socket Connected");
  });
  // testing notificatio
  socket.on("notification", (data) => {
    console.log(data);
  });

  socket.on("connect_error", (err) => {
    console.log("Socket connection error:", err);
  });
  // Alerts socket reciever

  socket.on(`alerts_${profile.id}`, (res) => {
    desktopNotification.sendDesktopNotification({
      title: "Alerts",
      message: `Store: ${res.data.facility}\nType: ${res.data.type}`,
      image: res?.data?.image,
    });
    {
      /* If profileId is 63(DPS) then dont show the notification for alerts*/
    }
    if (profile_id != 63) {
      notifiToasterSuccess(
        <div>
          <b>Alert</b> <br />
          Store: {res.data.facility} <br />
          Type: {res.data.type}
        </div>
      );
    }

    addSocketNotifications({
      ...res.data,
      keyword: "Alert",
      variant: "danger",
      url: "/alerts/",
    });
  });

  // Events socket reciever
  socket.on(`events_${profile.id}`, (res) => {
    desktopNotification.sendDesktopNotification({
      title: "Events",
      message: `Store: ${res.data.facility}\nType: ${res.data.type}`,
      image: res?.data?.image,
    });
    notifiToasterSuccess(
      <div>
        <b>Event</b> <br />
        Store: {res.data.facility} <br />
        Type: {res.data.type}
      </div>
    );
    addSocketNotifications({
      ...res.data,
      keyword: "Event",
      variant: "success",
      url: "/events/",
    });
  });
  socket.on("disconnect", () => {
    console.log("Socket disconnected.");
    // notifToaster("Socket disconnected.", "Socket", "danger");
  });
};

export default Layout;
