import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Bars } from "react-loader-spinner";
import PolygonDrawing from "../JPolygen/PolygonDrawing";
import deviceRelatedAction from "../../redux/Action/DeviceRelatedAction";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function JpolygonModal({ isEzviz, validate }) {
  const [open, setOpen] = useState(false);
  const [showPolygen, setShowPolygen] = useState(true);
  const state = useSelector((state) => state.device);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    if (validate()) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && state.selectedCamera !== 0 && state.selectedNvr !== 0) {
      const sessioData = JSON.parse(
        sessionStorage.getItem("remoteIotCredential")
      );
      const data = {
        nvr_id: state.selectedNvr,
        live_camera_id: state.selectedCamera,
        device_hostname: sessioData.hostName,
        device_port: sessioData.portNumber,
        device_username: sessioData.deviceName,
        device_password: sessioData.devicePassword,
      };

      if (state.isCameraEZVIZ) {
        dispatch(deviceRelatedAction.getImageViaCamera(data));
      } else {
        dispatch(deviceRelatedAction.getImageViaNVR(data));
      }

      setShowPolygen(true);
    } else {
      setShowPolygen(false);
      setOpen(false);
    }
  }, [open, state.selectedCamera, state.selectedNvr, dispatch]);

  useEffect(() => {
    dispatch(deviceRelatedAction.setImageForPoints(""));
  }, []);
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Get Images
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Draw Points
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {state.loadingForImage ? (
            <div className="d-flex justify-content-center">
              <Bars
                height={80} // Removed the quotes around 80
                width={80} // Removed the quotes around 80
                color="black"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <PolygonDrawing />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
JpolygonModal.propTypes = {
  isEzviz: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
};
