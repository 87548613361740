import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
// import OnboardingAction from "../../../redux/Action/OnboardingAction";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
// import getDataFromAPI from "../Utils/getDataFromAPI";
import PropTypes from "prop-types";
// import SignInOption from "../../components/common/SignInOption";
import ClientOnboardingActions from "../../redux/Action/ClientOnboarding";
import OnboardingAction from "../../redux/Action/OnboardingAction";
import AlertBoxEmail from "./Tables/AlertBox";
function Signup({ isEditable }) {
  // const [isEditable, setIsEditable] = useState(false);
  const [id, setId] = useState(-1);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const activeStep = useSelector((state) => state.clientOnboard.currentStep);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
    name: "",
    email: "",
  });
  const [showAlertBox, setShowAlertBox] = useState(false);
  const [alertMail, setAlertMail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const resetAllStates = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setName("");
    setEmail("");
  };
  useEffect(() => {
    if (isEditable) {
      let userInfo = JSON.parse(localStorage.getItem("onboardUser"));
      if (userInfo && userInfo.userId && userInfo.userEmail) {
        setEmail(userInfo.userEmail);
        setId(userInfo.userId);
        setName(userInfo.name);
        // setIsEditable(true);
      }
    }
  }, [isEditable]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Reset the specific error when the input changes
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "password") setPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
    if (name === "name") setName(value);
    if (name === "email") setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = {
      password: "",
      confirmPassword: "",
      name: "",
      email: "",
    };

    // Username validation
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!regex.test(email)) {
      valid = false;
      newErrors.email = "Invalid email format";
      console.log("invalid email");
    }
    if (name.length < 3) {
      valid = false;
      newErrors.name = "Name must be at least 8 characters long";
    }
    // Password validation

    if (password.length < 6) {
      valid = false;
      newErrors.password = "Password must be at least 6 characters long";
    }

    // Confirm Password validation
    if (password !== confirmPassword) {
      valid = false;
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (valid) {
      // Handle form submission, e.g., send data to the server
      const userData = {
        username: email,
        email: email,
        name: name,
        password: password,
        is_superuser: false,
        is_staff: false,
        is_active: true,
      };
      setLoading(true);

      dispatch(OnboardingAction.createUser(userData))
        .then(() => {
          setLoading(false);
          resetAllStates();
          dispatch({ type: "updateCurrentStep", payload: 1 });
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setErrors(newErrors);
    }
    setLoading(false);
  };

  return (
    <div className="mt-4">
      {!isEditable ? (
        <h2 className="mt-2 mb-4">Create User</h2>
      ) : (
        <h2 className="mt-2 mb-4">User Details</h2>
      )}
      <form onSubmit={handleSubmit} className="row" style={{ padding: "20px" }}>
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="name-onboard"
            label="Name"
            variant="outlined"
            fullWidth
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="email-onboard"
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
        </div>

        {!isEditable && (
          <>
            <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
              <TextField
                id="password-onbaording"
                label="Password"
                variant="outlined"
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                required
              />
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
              <TextField
                id="confirmPassword-login"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={confirmPassword}
                onChange={handleChange}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword}
                required
              />
            </div>
          </>
        )}
        <center>
          {showAlertBox && (
            <div className="form-group col-12 col-sm-6 col-lg-8 mb-3">
              <AlertBoxEmail email={alertMail} />
            </div>
          )}
        </center>
        {!isEditable && (
          <div className="submit-button-onboarding">
            <Button
              sx={{ width: "auto" }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              // onClick={handleAdduser}
            >
              {loading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : isEditable ? (
                "Update User"
              ) : (
                "Add User"
              )}
            </Button>
          </div>
        )}
      </form>
      {/* {!isEditable && <SignInOption signUp={true} />} */}
    </div>
  );
}
Signup.propTypes = {
  isEditable: PropTypes.bool.isRequired,
};
export default Signup;
