const initialState = {
  activeStep: Number(JSON.parse(localStorage.getItem("activeStep"))) || 0,
  newAccount: -1,
  newAddedCameras:
    JSON.parse(localStorage.getItem("PreviouslyAddedCamera")) || [],
  newAddedNVR: JSON.parse(localStorage.getItem("PreviouslyAddedNVR")) || [],
  newAddedFacility:
    JSON.parse(localStorage.getItem("PreviouslyAddedFacilities")) || [],
  newAddedDevice:
    JSON.parse(localStorage.getItem("PreviouslyAddedDevice")) || [],
};

export default function OnboardingReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_CURRENT_STEP":
      return { ...state, activeStep: action.payload };
    case "UPDATE_NEW_ACCOUNT_PROGRESS":
      return { ...state, newAccount: action.payload };
    case "UPDATE_NEW_ADDED_CAMERAS":
      // Create a new array by spreading the current newAddedCameras and adding the action.payload
      return {
        ...state,
        newAddedCameras: [...state.newAddedCameras, action.payload],
      };
    case "BULK_ADDED_CAMERAS":
      // Create a new array by spreading the current newAddedCameras and adding the action.payload
      return {
        ...state,
        newAddedCameras: [...state.newAddedCameras, ...action.payload],
      };
    case "UPDATE_MODIFIED_ADDED_CAMERAS": {
      const updatedCameras = state.newAddedCameras.map((camera) => {
        if (camera.id === action.payload.id) {
          return action.payload;
        }
        return camera;
      });

      return {
        ...state,
        newAddedCameras: updatedCameras,
      };
    }
    case "UPDATE_MODIFIED_NVR": {
      const updatedNVR = state.newAddedNVR.map((nvr) => {
        if (nvr.id === action.payload.id) {
          return action.payload;
        }
        return nvr;
      });

      return {
        ...state,
        newAddedNVR: updatedNVR,
      };
    }
    case "UPDATE_NEW_ADDED_NVR":
      // Create a new array by spreading the current newAddedNVR and adding the action.payload
      return {
        ...state,
        newAddedNVR: [...state.newAddedNVR, action.payload],
      };

    case "UPDATE_MODIFIED_ADDED_NVR": {
      const updateNVR = state.newAddedNVR.map((nvr) => {
        if (nvr.id === action.payload.id) {
          return action.payload;
        }
        return nvr;
      });

      return {
        ...state,
        newAddedNVR: updateNVR,
      };
    }

    case "DELETE_SELECTED_NVR": {
      const updateNVR = state.newAddedNVR.filter(
        (camera) => camera.id != action.payload
      );

      return {
        ...state,
        newAddedNVR: updateNVR,
      };
    }

    case "DELETE_SELECTED_FACILITY": {
      const updatedFacility = state.newAddedFacility.filter(
        (facility) => facility.id !== action.payload
      );

      return {
        ...state,
        newAddedFacility: updatedFacility.length > 0 ? updatedFacility : [],
      };
    }

    case "DELETE_SELECTED_CAMERA": {
      const updatedCamera = state.newAddedCameras.filter(
        (camera) => camera.id !== action.payload
      );

      return {
        ...state,
        newAddedCameras: updatedCamera,
      };
    }
    case "UPDATE_NEW_ADDED_FACILITIES":
      // Create a new array by spreading the current newAddedCameras and adding the action.payload
      return {
        ...state,
        newAddedFacility: [...state.newAddedFacility, action.payload],
      };

    case "CLEAR_REDUCER_FOR_ONBOARDING":
      // Create a new array by spreading the current newAddedCameras and adding the action.payload
      return {
        ...state,
        newAddedFacility: [],
        newAddedCameras: [],
        newAddedNVR: [],
        activeStep: 0,
      };

    case "PreviouslyAddedDevice":
      return {
        ...state,
        newDevice: action.payload,
      };
    default:
      return state;
  }
}
