import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useSelector } from "react-redux";
const steps = [
  {
    label: "Account",
  },
  {
    label: "User",
  },
  {
    label: "User Profile",
  },
  {
    label: "Facilities",
  },
  { label: "Facility Profile Mapping" },
  {
    label: "NVR/DVR",
  },
  {
    label: "Camera",
  },
  {
    label: "Add Device",
  },
];

export default function ProgressMenu() {
  const onboardingState = useSelector((state) => state.onboarding);

  return (
    <Box style={{ paddingLeft: "10px" }}>
      <Stepper activeStep={onboardingState.activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <span style={{ fontSize: "18px" }}>{step.label}</span>
            </StepLabel>
            <StepContent sx={{ minHeight: "10px" }}></StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
