import { BACKEND_URL } from "../../routes/config";
class OperationDashEndPoint {
  constructor() {
    this.getAlertEventCard = (operator_id, startTime, endTime, date) => {
      return `${BACKEND_URL}/operations/alerts_events/${operator_id}/${
        startTime + ":00"
      }/${date}/${endTime + ":00"}`;
    };
    this.getSelectedClients = (operator_id) => {
      return `${BACKEND_URL}/operations/operator_client_selection/${operator_id}`;
    };
    this.updateSelectedClients = (operator_id, selected_clients) => {
      return `${BACKEND_URL}/operations/operator_registration/${operator_id}/${selected_clients}`;
    };
    this.modifyStatusForAlertEventCard = () => {
      return `${BACKEND_URL}/operations/visibility_acceptence_seen`;
    };
    this.getAllStoredMessage = (id) => {
      return `${BACKEND_URL}/operations/message_display/${id}`;
    };
    this.updateStoredMessages = () => {
      return `${BACKEND_URL}/operations/message_save`;
    };
    this.deleteStoredMessages = (id) => {
      return `${BACKEND_URL}/operations/message_delete/${id}`;
    };
    this.getAllUserProfiles = () => {
      return `${BACKEND_URL}/operations/userprofiles/`;
    };
    this.getProfileById = (id) => {
      return `${BACKEND_URL}/operations/userprofiles/${id}/`;
    };
    this.getAllFacilities = () => {
      return `${BACKEND_URL}/operations/facility/`;
    };

    this.saveFacilityProfileMapping = () => {
      return `${BACKEND_URL}/operations/facilityprofilemapping/`;
    };
    this.createUser = () => {
      return `${BACKEND_URL}/operations/users/`;
    };
    this.createNVR = () => {
      return `${BACKEND_URL}/operations/nvr/`;
    };
    this.getNvrById = (id) => {
      return `${BACKEND_URL}/operations/nvr/${id}/`;
    };
    this.createAccount = () => {
      return `${BACKEND_URL}/operations/accounts/`;
    };
    this.getAccountById = (id) => {
      return `${BACKEND_URL}/operations/accounts/${id}/`;
    };
    this.seachAccountFromAll = (searchItem) => {
      return `${BACKEND_URL}/operations/accounts/?search=${searchItem}`;
    };
    this.seachFacilityProfileFromAll = (searchItem) => {
      return `${BACKEND_URL}/operations/facilityprofilemapping/?search=${searchItem}`;
    };
    this.seachDeviceFromAll = (searchItem) => {
      return `${BACKEND_URL}/operations/device/?search=${searchItem}`;
    };
    this.seachNVRFromAll = (searchItem) => {
      return `${BACKEND_URL}/operations/nvr/?search=${searchItem}`;
    };
    this.seachCameraFromAll = (searchItem) => {
      return `${BACKEND_URL}/operations/camera/?search=${searchItem}`;
    };
    this.getFacilityById = (id) => {
      return `${BACKEND_URL}/operations/facility/${id}/`;
    };
    this.getAllFacilityProfilesById = (id) => {
      return `${BACKEND_URL}/operations/facilityprofilemapping/${id}/`;
    };
    this.getAllFacilityProfiles = (id) => {
      return `${BACKEND_URL}/operations/facilityprofilemapping/`;
    };
    this.getUserById = (id) => {
      return `${BACKEND_URL}/operations/users/${id}/`;
    };
    this.getSearchUser = (seachItem) => {
      return `${BACKEND_URL}/operations/users/?search=${seachItem}`;
    };
    this.getSearchUserProfiles = (seachItem) => {
      return `${BACKEND_URL}/operations/userprofiles/?search=${seachItem}`;
    };
    this.getSearchFacilityProfile = (seachItem) => {
      return `${BACKEND_URL}/operations/facility/?search=${seachItem}`;
    };
    this.getAllServices = () => {
      return `${BACKEND_URL}/operations/services/`;
    };
    this.createCamera = () => {
      return `${BACKEND_URL}/operations/camera/`;
    };
    this.getCameraById = (id) => {
      return `${BACKEND_URL}/operations/camera/${id}/`;
    };
    this.getFacilityById = (id) => {
      return `${BACKEND_URL}/operations/facility/${id}/`;
    };
    this.getCameraByNvrId = (id) => {
      return `${BACKEND_URL}/operations/camera_from_nvr/${id}`;
    };
    this.createFacility = () => {
      return `${BACKEND_URL}/operations/facility/`;
    };
    this.getDeviceList = () => {
      return `${BACKEND_URL}/operations/device/`;
    };
    this.getDeviceListById = (id) => {
      return `${BACKEND_URL}/operations/device/${id}/`;
    };
    this.getUserList = () => {
      return `${BACKEND_URL}/operations/users/`;
    };
    this.updateDataInConfig = () => {
      return `${BACKEND_URL}/operations/device_rhms/replace_cam_config`;
    };
    this.getSavedImageFromDB = (data) => {
      return `${BACKEND_URL}/operations/device_rhms/image_fetch/${data.live_camera_id}`;
    };
    this.bulkCreateCamera = (nvr_id) => {
      return `${BACKEND_URL}/operations/bulk_camera/${nvr_id}`;
    };
    this.installDependencyToDevice = (data) => {
      return `${BACKEND_URL}/operations/device_rhms/nano_installation/${data.device_hostname}/${data.device_port}/${data.device_username}/${data.device_password}`;
    };
    this.getImageViaNvr = (data) => {
      return `${BACKEND_URL}/operations/device_rhms/image_creator_nvr/${data.live_camera_id}/${data.nvr_id}/${data.device_hostname}/${data.device_port}/${data.device_username}/${data.device_password}`;
    };
    this.getImageViaEzvizCamera = (data) => {
      return `${BACKEND_URL}/operations/device_rhms/image_creator_camera/${data.live_camera_id}/${data.device_hostname}/${data.device_port}/${data.device_username}/${data.device_password}`;
    };
    this.getUserByAccount = (data) => {
      return `${BACKEND_URL}/operations/users_from_account/${data}`;
    };
    this.getUserProfileByAccount = (data) => {
      return `${BACKEND_URL}/operations/userprofile_from_account/${data}`;
    };
    this.getFacilityByAccount = (data) => {
      return `${BACKEND_URL}/operations/facility_from_account/${data}`;
    };
    this.getFacilityByUserProfile = (data) => {
      return `${BACKEND_URL}/operations/facility_from_userprofile/${data}`;
    };
    this.createCamerasAutoRtsp = () => {
      return `${BACKEND_URL}/operations/camera_auto_rtsp`;
    };
    this.getCamerasByStore = (facility_id) => {
      return `${BACKEND_URL}/facility/camera/${facility_id}/`;
    };
    this.getNVRByFacility = (facility_id) => {
      return `${BACKEND_URL}/operations/nvr_from_facility/${facility_id}`;
    };
    this.getNVRByAccount = (acc_id) => {
      return `${BACKEND_URL}/operations/nvr_from_account/${acc_id}`;
    };
    this.getDeviceByFacility = (facility_id) => {
      return `${BACKEND_URL}/operations/device_from_facility/${facility_id}`;
    };
    this.getDeviceByAccount = (acc_id) => {
      return `${BACKEND_URL}/operations/device_from_account/${acc_id}`;
    };
    this.getFPmappingByUserProfile = (id) => {
      return `${BACKEND_URL}/operations/facility_profile_mapping_from_userprofile/${id}`;
    };
    this.pingIpAddress = (ipadd) => {
      return `${BACKEND_URL}/operations/check_ping/${ipadd}`;
    };
    this.pingIpAddress = (ipadd) => {
      return `${BACKEND_URL}/operations/check_ping/${ipadd}`;
    };
    this.checkHttpPort = (ipadd, port) => {
      return `${BACKEND_URL}/operations/check_port/${ipadd}/${port}`;
    };
    this.checkRtspStream = (data) => {
      return `${BACKEND_URL}/operations/rtsp_check/`;
    };
    this.checkRtspStreamChannelCapacity = () => {
      return `${BACKEND_URL}/operations/rtsp_check/capacity`;
    };
    this.checkAuthentication = (data) => {
      return `${BACKEND_URL}/operations/check_authorization`;
    };
    this.getImageFromDatabase = () => {
      return `${BACKEND_URL}/operations/image_fetch_sales`;
    };
    this.getDeviceFromRemoteIOT = () => {
      return `${BACKEND_URL}/operations/device_fetch`;
    };
    this.labelForFleet = () => {
      return `${BACKEND_URL}/operations/devicelabel/`;
    };
    this.deviceSaveFleet = (data) => {
      return `${BACKEND_URL}/operations/device_save/${data.unitId}/${data.name}/${data.internal_ip}/${data.external_ip}/${data.description}/${data.mac_id}/${data.username}/${data.password}/${data.notes}/${data.facility_id}/${data.type_of_feature}/${data.type_of_hardware}/${data.date_of_installation}/${data.device_type}/${data.device_id}`;
    };
    this.getDeviceIdForFleet = (id) => {
      return `${BACKEND_URL}/operations/device/${id}/`;
    };
    this.addUseCaseMapping = () => {
      return `${BACKEND_URL}/operations/usecasecameraall/`;
    };
    this.getUseCaseMappingById = (id) => {
      return `${BACKEND_URL}/operations/usecasecameraall/${id}/`;
    };
    this.cameraFromAccount = (acc_id) => {
      return `${BACKEND_URL}/operations/camera_from_account/${acc_id}`;
    };
    this.retrieveFleetDevices = () => {
      return `${BACKEND_URL}/operations/device_status_v2`;
    };
    this.retrieveFleetDevicesViaAccount = (id) => {
      return `${BACKEND_URL}/operations/device_from_account_riot/${id}`;
    };
    this.retrieveFleetDevicesViaFacility = (id) => {
      return `${BACKEND_URL}/operations/device_from_facility_riot/${id}`;
    };
    this.retrieveFleetDevicesViaChannel = (id) => {
      return `${BACKEND_URL}/operations/device_from_channel_riot/${id}`;
    };
    this.retrieveFleetDevicesViaSearch = (searchString) => {
      return `${BACKEND_URL}/operations/device_status_v2?search=${searchString}`;
    };
    this.getEmailIdViaDeviceId = (id) => {
      return `${BACKEND_URL}/operations/emails_from_device/${id}`;
    };
    this.getChannelList = () => {
      return `${BACKEND_URL}/facility/channels`;
    };
    this.getChannelDetailsById = (id) => {
      return `${BACKEND_URL}/facility/channels/${id}/`;
    };
    this.getAccountByChannelId = (id) => {
      return `${BACKEND_URL}/operations/account_from_channel/${id}`;
    };
    this.getAccountforAccessControl = () => {
      return `${BACKEND_URL}/operations/accounts/`;
    };
    this.getUserByAccountId = (accountidforUser) => {
      return `${BACKEND_URL}/operations/users_from_account/${accountidforUser}`;
    };
    this.getGroupByUserId = (id) => {
      return `${BACKEND_URL}/get/group/user/?user_id=${id}`;
    };
    this.getNVRByOpsFacility = (ids) => {
      const idsString = ids.join(","); // Join array of IDs into a comma-separated string
      return `${BACKEND_URL}/controlled_access/device_nvr_facility_from_facility/${idsString}/nvr`;
    };
    this.getDeviceOrNVRsFromFacilityUrl = (facility_ids, target_info) => {
      return `${BACKEND_URL}/controlled_access/device_nvr_facility_from_facility/${facility_ids}/${target_info}`;
    };
    this.getCameraFromNvrForAccessControlUrl = (nvr_ids) => {
      return `${BACKEND_URL}/controlled_access/camera_nvr_from_nvr/${nvr_ids}`;
    };
    this.updateGroupbyAccountIds = (acc_id, group_id, remove_mapping) => {
      return `${BACKEND_URL}/update/group/mapping/${acc_id}/${group_id}/?remove_mapping=${remove_mapping}`;
    };
    this.getFacilityCameraNvrUrl = (group_id) => {
      return `${BACKEND_URL}/controlled_access/facility_device_camera_nvr_from_group/${group_id}`;
    };
    this.getAccountforAccessControlforConnectivity = () => {
      return `${BACKEND_URL}/operations/account_from_channel/3`;
    };
  }
}

export default new OperationDashEndPoint();
