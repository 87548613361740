import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import axios from "axios";
import OperationDashEndpoints from "../../../redux/features/OperationDashEndpoints";
import AutocompleteCompUncontrollable from "../../../components/common/AutoCompleteUncontrollable";
import { Bars } from "react-loader-spinner";
import CameraTable from "../../Onboarding/Steps/Tables/CameraTables";

const CameraDetails = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedNvr, setSelectedNvr] = useState(null);
  const [facilityList, setFacilityList] = useState([
    { label: "All", value: 0 },
  ]);
  const [nvrList, setNvrList] = useState([{ label: "All", value: 0 }]);
  const [searchItem, setSearchItem] = useState("");
  async function fetchData() {
    try {
      const token = localStorage.getItem("token");
      setLoading(true);

      if (searchItem.length === 0 && !selectedFacility && !selectedNvr) {
        const response = await axios.get(
          OperationDashEndpoints.createCamera(),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setUserList(response.data);
      } else if (searchItem.length > 2) {
        const response = await axios.get(
          OperationDashEndpoints.seachCameraFromAll(searchItem),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setUserList(response.data);
      } else if (selectedFacility && selectedFacility.value) {
        const response = await axios.get(
          OperationDashEndpoints.getCamerasByStore(selectedFacility.value),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setUserList(response.data);
      } else if (selectedNvr && selectedNvr.value) {
        const response = await axios.get(
          OperationDashEndpoints.getCameraByNvrId(selectedNvr.value),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setUserList(response.data.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setUserList([]);
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  useEffect(() => {
    fetchData();
  }, [searchItem, selectedFacility, selectedNvr]);

  useEffect(() => {
    async function fetchFacilityList() {
      try {
        const token = localStorage.getItem("token");
        const response1 = await axios.get(
          OperationDashEndpoints.getAllFacilities(),
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        const accountData = [{ label: "All", value: 0 }];
        response1.data.forEach((item) => {
          accountData.push({
            label: item.facility_name,
            value: item.id,
          });
        });

        setFacilityList(accountData);
      } catch (error) {
        console.error("Error fetching facility data:", error);
        throw error;
      }
    }

    async function fetchNVRList() {
      try {
        const token = localStorage.getItem("token");
        const response1 = await axios.get(OperationDashEndpoints.createNVR(), {
          headers: { Authorization: `Token ${token}` },
        });

        const accountData = [{ label: "All", value: 0 }];
        response1.data.forEach((item) => {
          accountData.push({
            label: item.name,
            value: item.id,
          });
        });

        setNvrList(accountData);
      } catch (error) {
        console.error("Error fetching NVR data:", error);
        throw error;
      }
    }

    fetchFacilityList();
    fetchNVRList();
    dispatch(changeTopbarTitle("Admin/Cameras"));
  }, [dispatch]);

  function handleFacilityChange(val) {
    setSelectedNvr(null);
    if (val.value) setSelectedFacility(val);
    else setSelectedFacility(null);
  }

  function handleNvrChange(val) {
    setSelectedFacility(null);
    if (val.value) setSelectedNvr(val);
    else setSelectedNvr(null);
  }

  return (
    <div className="config-page-container">
      <div className="row">
        <div className="d-flex mb-3 col-4">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search Camera"
            aria-label="Search"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target.value)}
          />
        </div>
        <div className="mb-3 col-4">
          <AutocompleteCompUncontrollable
            option={facilityList}
            onChange={handleFacilityChange}
            label="Facility"
            disableClearable={true}
            showValue={true}
          />
        </div>
        <div className="mb-3 col-4">
          <AutocompleteCompUncontrollable
            option={nvrList}
            onChange={handleNvrChange}
            label="NVR"
            disableClearable={true}
            showValue={true}
          />
        </div>
      </div>
      {!loading ? (
        <CameraTable data={userList} getUserList={fetchData} />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Bars height={80} width={80} color="black" ariaLabel="bars-loading" />
        </div>
      )}
    </div>
  );
};

export default CameraDetails;
