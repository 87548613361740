import React, { useState, useEffect } from "react";
import { useRef } from "react";
import "../../assets/css/Login.css";
import "../../assets/css/main.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifiToasterSuccess } from "./UseToast";
import { ToastContainer } from "react-toastify";
import { forgetPassToken, changePassword } from "../../redux/Action/Login";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const snacker = () => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      message="Hello, world! This is a Snackbar message."
    />
  );
};

const ForgetPass = () => {
  const Toast = useRef(null);
  const [email, setEmail] = useState("");
  const [password1, setpassword1] = useState("");
  const [password2, setpassword2] = useState("");

  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeIcon, setEyeIcon] = useState(false);
  const dispatch = useDispatch();
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEyeIcon(eyeIcon ? false : true);
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    dispatch(forgetPassToken({ email }));
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    dispatch(
      changePassword({
        data: {
          uid: state.login.forgetPassdata.uidb64,
          token: state.login.forgetPassdata.token,
        },
        obj: { password: password1, password2: password2 },
      })
    );
  };
  useEffect(() => {
    if (state.login.passChanged) {
      navigate("/login");
    }
  });

  return (
    <div className="container-fluid">
      <div className="forgetPassdiv">
        <ToastContainer />
        {state.login.forgetPassdata.token !== "" ? null : (
          <form className="forgetPassForm" onSubmit={handleSubmitEmail}>
            <br />
            <br />
            <label className="form-label"> Email *</label>
            <div className="pwd">
              <input
                className="inputLogin"
                placeholder="mail@simple.com"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
            </div>
            <br />
            {state.login.forgetPassdata.passwordChanged}
            <button className="forgetPassSubmitButtom">
              <span id="headSign">Submit</span>
            </button>
          </form>
        )}
        {state.login.forgetPassdata.token === "" ? null : (
          <form className="forgetPassForm" onSubmit={handleSubmitForm}>
            <br />
            <br />
            <div>
              <div>
                <label className="form-label">New Password*</label>
                <div className="pwd">
                  <input
                    className="inputLogin"
                    placeholder="Min.8 characters"
                    onChange={(e) => setpassword1(e.target.value)}
                    type={passwordShown ? "text" : "password"}
                  />

                  {password1.length >= 8 ? null : (
                    <span className="forgetPassword-warning">
                      Text Must Have At least 8 Charaters
                    </span>
                  )}

                  <span className="p-viewer">
                    <i
                      onClick={togglePasswordVisiblity}
                      className={eyeIcon ? "bi bi-eye" : "bi bi-eye-slash"}
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
                <br />
                <br />
                <label className="form-label">Re-Enter Password*</label>
                <div className="pwd">
                  <input
                    className="inputLogin"
                    placeholder="Min.8 characters"
                    onChange={(e) => setpassword2(e.target.value)}
                    type={passwordShown ? "text" : "password"}
                  />
                  {password2.length >= 8 ? null : (
                    <span className="forgetPassword-warning">
                      Text Must Have At least 8 Charaters
                    </span>
                  )}
                  <span className="p-viewer">
                    <i
                      onClick={togglePasswordVisiblity}
                      className={eyeIcon ? "bi bi-eye" : "bi bi-eye-slash"}
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
                <br />
                <br />
              </div>
            </div>
            <button className="forgetPassSubmitButtom">
              <span id="headSign">Submit</span>
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgetPass;
