const initialState = {
  title: [],
};

export default function titleReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_TITLE":
      return { ...state, title: action.payload };
    default:
      return state;
  }
}
