import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import deviceRelatedAction from "../../redux/Action/DeviceRelatedAction";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";

const DeviceSetup = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    ipadd: "",
    port: 22,
    username: "",
    devicePassword: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    ipadd: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    dispatch(changeTopbarTitle("Device Setup"));
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    const newErrors = {
      ipadd: "",
    };

    if (formData.ipadd.length < 6) {
      console.log(`${formData.ipadd} is not a valid.`);
      valid = false;
      newErrors.ipadd = "Enter a valid IP address";
    } else {
      newErrors.ipadd = null;
      setErrors(newErrors);
    }

    if (valid) {
      const data = {
        device_hostname: formData.ipadd,
        device_port: formData.port,
        device_username: formData.username,
        device_password: formData.devicePassword,
      };

      try {
        setLoading(true);
        dispatch(deviceRelatedAction.StartDeviceIntsallation(data))
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } catch (error) {
        console.error("Error submitting data:", error);
        // Handle the error as needed
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="config-page-container">
        <p>
          Install All the Dependency in the device. Enter below information to
          make the device ready{" "}
        </p>
        <ol>
          <li>Port Number: The default Value of port number is 22</li>
          <li>Device Username: Username of device i,e agrex or nvidia</li>
          <li>Device Password: Password of device i,e agrex or nvidia</li>
        </ol>
        <div className="row">
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="ipadd"
              label="Host Name"
              variant="outlined"
              fullWidth
              type="text"
              name="ipadd"
              value={formData.ipadd}
              onChange={handleChange}
              error={Boolean(errors.ipadd)}
              helperText={errors.ipadd}
              required
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="port_number"
              label="Port Number"
              variant="outlined"
              fullWidth
              type="number"
              name="port"
              value={formData.port}
              onChange={handleChange}
              error={Boolean(errors.port)}
              helperText={errors.port}
              required
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="username"
              label="Device Username "
              variant="outlined"
              fullWidth
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              error={Boolean(errors.username)}
              helperText={errors.username}
              required
            />
          </div>

          <div className="form-group col-12 col-sm-6 col-lg-4 mb-3">
            <TextField
              id="password"
              label="Device Password"
              variant="outlined"
              fullWidth
              type="text"
              name="devicePassword"
              value={formData.devicePassword}
              onChange={handleChange}
              error={Boolean(errors.devicePassword)}
              helperText={errors.devicePassword}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 mt-3">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Installing ..." : "Install Dependency"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DeviceSetup;
